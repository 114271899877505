<template>
  <div class="insulation-container">
    <div class="prompt-container">
      <!-- <div class="item-container">
        <div class="prompt">
          Please input the sq footage of the attic
        </div>
        <input
          type="number"
          class="input"
          v-model="atticSqFeet"
          placeholder="Ex: 25"
        />
      </div> -->
      <div class="item-container">
        <div class="prompt">Is the Attic Accessible?</div>
        <div class="button-container">
          <div class="button" @click="access = true" :class="access ? 'active' : ''">Yes</div>
          <div class="button" @click="access = true" :class="!access ? 'active' : ''">No</div>
        </div>
      </div>
      <div class="item-container">
        <div class="prompt">
          Type of current insulation
        </div>
        <div class="button-container">
          <div
            class="button"
            @click="typeOf = 'Spray Foam'"
            :class="typeOf === 'Spray Foam' ? 'active' : ''"
          >
            Spray Foam
          </div>
          <div
            class="button"
            @click="typeOf = 'Blown-In'"
            :class="typeOf === 'Blown-In' ? 'active' : ''"
          >
            Blown-In
          </div>
          <div
            class="button"
            @click="typeOf = 'Unknown'"
            :class="typeOf === 'Unknown' ? 'active' : ''"
          >
            Unknown
          </div>
        </div>
      </div>
    </div>
    <div class="photo-container">
      <div class="prompt">
        Please provide images of the access point to the attic.
      </div>
      <img
        class="photo"
        alt=""
        :src="
          photos.length === 0
            ? defaultPhotoImg
            : photos.length === 1
            ? photos[0]
            : multiplePhotoImg
        "
      />
      <div class="button-container photo-buttons">
        <label class="button file-button">
          <input
            type="file"
            class="photo-input"
            @change="handlePhoto"
            accept=".jpg,.png"
            multiple
            ref="insulationPhotos"
          />
          {{ photos.length === 0 ? "Upload Photo" : "Add Additional" }}
        </label>
        <div class="button file-button" @click="removeFiles">Clear Files</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentPage"],
  data() {
    return {
      atticSqFeet: null,
      defaultPhotoImg: require("../../../../assets/images/Attic2.png"),
      multiplePhotoImg: require("../../../../assets/svg/ZipFolder.svg?external"),
      typeOf: "Unknown",
      photos: [],
      photosUrl: [],
      access: false,
    };
  },
  computed: {
    payload() {
      return {
        typeOf: this.typeOf,
        access: this.access,
      };
    },
    imagePayload() {
      return {
        Insulation: this.photosUrl,
      };
    },
  },
  methods: {
    handlePhoto(e) {
      const file = e.target.files[0];
      const photo = URL.createObjectURL(file);
      if (!this.photos.includes(photo)) {
        this.photos.push(photo);
        this.photosUrl.push(file);
      }
    },
    removeFiles() {
      this.photos = [];
      this.$refs.insulationPhotos.value = "";
    },
  },
};
</script>

<style scoped>
.insulation-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
}
.prompt-container {
  display: flex;
  width: 100%;
  height: 25%;
  justify-content: space-evenly;
}
.item-container {
  width: 42%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.prompt {
  text-align: center;
}
.number-input {
  width: 70%;
  margin: auto;
}
.button-container {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 100%;
}
.photo-buttons {
  justify-content: center;
  margin: initial;
}
.photo-container {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.photo {
  width: auto;
  height: auto;
  max-width: 50vw;
  max-height: 20vh;
  margin: auto;
}
.file-button {
  min-width: fit-content;
  width: 2rem;
  margin: 0 2vw;
}
</style>

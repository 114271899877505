<template>
  <div class="header-container">
    <div class="home-photo-container">
      <img :src="getHome()" alt="" class="home-photo" />
      <div class="sales-container">
        <img :src="icon" class="sales-logo" alt="" />
      </div>
    </div>
    <div class="name">{{ formatName() }}</div>
    <div class="info-container">
      <div class="user-info">
        <div class="info">{{ formatAddress() }}</div>
        <div class="info">{{ formatPhone() }}</div>
        <div class="info">{{ user.email }}</div>
      </div>
      <GmapMap
        class="google-maps"
        :zoom="zoom"
        :center="mapCenter"
        map-type-id="satellite"
        :options="mapOptions"
      />
      <div class="user-info">
        <div class="scope-item" v-for="project of items" :key="project.project">
          {{ project.projectInfo.title }}
        </div>
      </div>
    </div>
    <div class="include-header">Included with your project!</div>
    <div class="included">
      <div class="included-item">
        <img
          src="../../../assets/images/Quotes/HomeUpLogo.jpg"
          alt=""
          class="included-icon"
        />
        <div class="included-text">All Inclusive Home Profile</div>
      </div>
      <div class="included-item">
        <img
          src="../../../assets/images/Quotes/Arrows.jpg"
          alt=""
          class="included-icon"
        />
        <div class="included-text">Step by Step Service Tracking</div>
      </div>
      <div class="included-item">
        <img
          src="../../../assets/images/Quotes/Lock.jpg"
          alt=""
          class="included-icon"
        />
        <div class="included-text">
          Manage Safety Security & Quality Control
        </div>
      </div>
      <div class="included-item">
        <img
          src="../../../assets/images/Quotes/Hammer.jpg"
          alt=""
          class="included-icon"
        />
        <div class="included-text">Complete Project Management</div>
      </div>
      <div class="included-item">
        <img
          src="../../../assets/images/Quotes/Mail.jpg"
          alt=""
          class="included-icon"
        />
        <div class="included-text">24/7 Live Chat Customer Service</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "items"],
  data() {
    return {
      mapCenter: {
        lat: this.user.lat ? this.user.lat : 28.538336,
        lng: this.user.lng ? this.user.lng : -81.379234,
      },
      mapOptions: {
        scrollwheel: false,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        disableDefaultUi: true,
        zoomControlOptions: {
          position: this.mapCenter,
        },
      },
      zoom: 19,
      icon: require("../../../assets/svg/Logo.svg?external"),
    };
  },
  methods: {
    getHome() {
      return require("../../../assets/images/Quotes/Home.jpg");
    },
    async getIcon() {
      if (!this.user.companyUsername) {
        this.icon = require("../../../assets/svg/LogoWhite.svg?external");
        return;
      }
      const img = (
        await this.$http.put(`${this.$API}image/get`, {
          prefix: this.user.companyUsername,
        })
      ).data;
      if (img[0].length === 0)
        this.icon = require("../../../assets/svg/LogoWhite.svg?external");
      else {
        let name;
        for (const imgUrl of img[0]) {
          if (/quotePhoto/.test(imgUrl.name)) {
            name = `${this.$BUCKET}${imgUrl.name}`;
            break;
          } else if (/userPhoto/.test(imgUrl.name)) {
            name = `${this.$BUCKET}${imgUrl.name}`;
          }
        }
        this.icon = name
          ? name
          : require("../../../assets/svg/LogoWhite.svg?external");
      }
    },
    formatAddress() {
      return `${this.user.address}, ${this.user.city}, ${this.user.state}, ${this.user.zip}`;
    },
    formatPhone() {
      const phone = this.user.phone;
      return `(${phone.substring(0, 3)}) ${phone.substring(
        3,
        6
      )} - ${phone.substring(6)}`;
    },
    formatName() {
      return `${this.user.fName
        .charAt(0)
        .toUpperCase()}${this.user.fName.substring(1)} ${this.user.lName
        .charAt(0)
        .toUpperCase()}${this.user.lName.substring(1)}`;
    },
  },
  async mounted() {
    await this.getIcon();
  },
};
</script>

<style scoped>
.header-container {
  display: flex;
  flex-direction: column;
  font-size: 0.25rem;
  margin-bottom: 4vh;
}
.home-photo-container {
  position: relative;
}
.home-photo {
  width: 82vw;
  height: auto;
}
.sales-container {
  position: absolute;
  top: 4vh;
  left: 3vw;
  width: calc(15vh + 11vw);
  height: calc(15vh + 11vw);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sales-logo {
  height: calc(10vh + 7vw);
}
.name {
  margin: 5vh 0;
  text-align: center;
  color: var(--blue-300);
  font-weight: bold;
  font-size: 0.45rem;
}
.info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.user-info {
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  width: 17vw;
  cursor: default;
  overflow-wrap: break-word;
}
.info,
.scope-item {
  font-weight: bold;
}
.google-maps {
  width: 40vw;
  height: 47vh;
  border-radius: 25px;
  pointer-events: none;
}
.scope-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5vw;
}
.scope-item::before {
  content: "";
  display: inline-block;
  height: 1vh;
  width: 1vh;
  background: var(--blue-300);
  margin-right: 0.25vw;
  border-radius: 50%;
}
.scope-item:hover::before {
  background: var(--blue-600);
}
.include-header {
  text-align: center;
  margin: 8vh 0;
  font-weight: bold;
  font-size: 0.45rem;
}
.included {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.included-item {
  display: flex;
  row-gap: 3vh;
  flex-direction: column;
  width: 14vw;
  align-items: center;
}
.included-icon {
  background: var(--blue-300);
  height: 10vh;
  width: 10vh;
  border-radius: 50%;
}
.included-text {
  font-weight: bold;
  text-align: center;
  width: 73%;
}
</style>

<template>
  <div class="landing-container">
    <SearchBar
      :reps="activeReps"
      :companies="activeCompanies"
      :solar="activeSolar"
      :homeowners="customers"
      @adminSnapShot="setSnapShot"
      class="search-bar"
    />
    <AnalyticSnapshot class="analytics" :chartData="chartData" :context="snapShotType" />
    <CurrentProjects
      class="current-projects"
      :snapshot="snapshot"
      :context="snapShotType"
    />
    <Map class="map" :homeowners="customers" />
  </div>
</template>

<script>
import SearchBar from "@/components/Common/SearchUsers.vue";
import AnalyticSnapshot from "@/components/Common/AnalyticSnapshot.vue";
import CurrentProjects from "@/components/Common/CurrentProjects.vue";
import Map from "@/components/Common/Map.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SearchBar,
    AnalyticSnapshot,
    CurrentProjects,
    Map,
  },
  data() {
    return {
      snapShotType: "customers",
    };
  },
  methods: {
    setSnapShot(userType) {
      this.snapShotType = userType;
    },
  },
  computed: {
    ...mapGetters(["reps", "customers", "companies", "userConfig"]),
    snapshot() {
      if (this.snapShotType === "companies") return this.companySnapShot;
      return this.repSnapShot;
    },
    chartData() {
      if (this.snapShotType === "companies") return this.companyChartData;
      return this.repChartData;
    },
    repSnapShot() {
      let snapshot = [];
      for (const rep of this.reps) {
        let repSnap = {
          analyticOne: 0,
          analyticTwo: rep.points,
          id: rep.id,
          abbreviation: `${rep.fName.charAt(0).toUpperCase()}${rep.lName
            .charAt(0)
            .toUpperCase()}`,
          companyName: rep.companyName,
        };
        let count = 0;
        for (const customer of this.customers) {
          if (customer.repId === rep.id) count++;
        }
        repSnap.analyticOne = count;
        snapshot.push(repSnap);
      }
      snapshot = snapshot.sort((a, b) => b.analyticOne - a.analyticOne);
      return snapshot;
    },
    companySnapShot() {
      let snapshot = [];
      for (const company of this.companies) {
        const abbreviation = company.companyName
          .split(" ")
          .map((a) => a.charAt(0))
          .join()
          .replace(",", "");
        let companySnap = {
          analyticOne: 0,
          analyticTwo: 0,
          id: company.id,
          abbreviation: abbreviation,
          companyName: company.companyName.split(" "),
        };
        let customers = 0;
        let reps = 0;
        for (const rep of this.repSnapShot) {
          if (rep.companyName === company.companyName) {
            customers = customers + rep.analyticOne;
            reps++;
          }
        }
        companySnap.analyticOne = customers;
        companySnap.analyticTwo = reps;
        snapshot.push(companySnap);
      }
      snapshot = snapshot.sort((a, b) => b.analyticOne - a.analyticOne);
      return snapshot;
    },
    repChartData() {
      const snapshot = [];
      const categories = [
        "Generac",
        "HVAC",
        "Pool Pumps",
        "Hot Water Heater",
        "Roofing",
        "Insulation",
        "Tree Trimming",
      ];
      for (const customer of this.customers) {
        let repIndex = snapshot
          .map((obj) => {
            return obj.repId;
          })
          .indexOf(customer.repId);
        if (repIndex === -1) {
          snapshot.push({
            repId: customer.repId,
            name: `${customer.repFName} ${customer.repLName}`,
            data: new Array(7).fill(0),
          });
          repIndex = snapshot.length - 1;
        }
        for (const project of customer.projectSnapShot) {
          switch (project.projectName) {
            case "generac":
              snapshot[repIndex].data[0]++;
              break;
            case "hvac":
              snapshot[repIndex].data[1]++;
              break;
            case "pool_pumps":
              snapshot[repIndex].data[2]++;
              break;
            case "hot_water_heater":
              snapshot[repIndex].data[3]++;
              break;
            case "roofing":
              snapshot[repIndex].data[4]++;
              break;
            case "insulation":
              snapshot[repIndex].data[5]++;
              break;
            case "tree_trimming":
              snapshot[repIndex].data[6]++;
              break;
            default:
              break;
          }
        }
      }
      return {
        categories: categories,
        series: snapshot,
      };
    },
    companyChartData() {
      const snapshot = [];
      const categories = [
        "Generac",
        "HVAC",
        "Pool Pumps",
        "Hot Water Heater",
        "Roofing",
        "Insulation",
        "Tree Trimming",
      ];
      for (const rep of this.repChartData.series) {
        let companyName;
        if (rep.repId === 0) {
          companyName = "Admin"
        } else {
          companyName = this.reps.filter((r) => r.id === rep.repId)[0].companyName;
        }
        let companyIndex = snapshot.map((obj) => {return obj.name}).indexOf(companyName);
        if (companyIndex === -1) {
          snapshot.push({
            name: `${companyName}`,
            data: new Array(7).fill(0),
          });
          companyIndex = snapshot.length - 1;
        }
        snapshot[companyIndex].data = snapshot[companyIndex].data.map((num, i) => {
          return num + rep.data[i];
        });
      }
      return {
        categories: categories,
        series: snapshot,
      };
    },
    activeReps() {
      return this.reps.filter((a) => a.isActive);
    },
    activeCompanies() {
      return this.companies.filter((a) => a.isActive && !a.isSolar);
    },
    activeSolar() {
      return this.companies.filter((a) => a.isActive && a.isSolar);
    },
  },
};
</script>

<style scoped>
.landing-container {
  width: 100%;
  height: 91vh;
  background: #ededed;
  display: grid;
  column-gap: 2vw;
  row-gap: 1vh;
  grid-template-areas:
    "search analytics maps"
    "search projects maps";
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 3fr 4fr 3fr;
  column-gap: 2vw;
}
.search-bar {
  grid-area: search;
}
.analytics {
  grid-area: analytics;
}
.current-projects {
  grid-area: projects;
}
.map {
  grid-area: maps;
  height: 90%;
  width: 90%;
  margin: 5%;
}
</style>

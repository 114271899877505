<template>
  <div>
    <div class="loading" v-if="loading">
      <Logo class="loading-icon" />
    </div>
    <Header :navItems="navItems" :openNav="openNav" />
    <div @click="closeNav" class="bg">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
// Components
import Header from "@/components/Common/Header/Header.vue";
import Logo from "@/assets/svg/Logo.svg";

// Icons
import Money from "@/assets/svg/Money4.svg";
import HomeIcon from "@/assets/svg/Star.svg";
import Customers from "@/assets/svg/UserProfile.svg";
import Plus from "@/assets/svg/Plus2.svg";
import Logout from "@/assets/svg/Logout4.svg";

export default {
	props: ["nav"],
  components: {
    Header,
    Logo,
  },
  data() {
    return {
      loading: false,
      openNav: true,
      navItems: [
        {
          copy: "Dashboard",
          link: "landing",
          icon: HomeIcon,
        },
        {
          copy: "Search Users",
          link: "filterUser",
          icon: Customers,
        },
        {
          copy: "New Lead",
          link: "newLead",
          icon: Plus,
        },
        {
          copy: "Wallet",
          link: "view/r",
          icon: Money,
        },
        {
          copy: "Logout",
          link: "logout",
          icon: Logout,
        },
      ],
    };
  },
  computed: {
    userConfig() {
      return this.$store.getters.userConfig;
    },
  },
  methods: {
    closeNav() {
      this.openNav = false;
      setTimeout(() => {
        this.openNav = true;
      }, 500);
    },
    async getUser() {
      this.loading = true;
      const res = await this.$http.get(
        `${this.$API}rep/${this.userConfig.id}/user`
      );
      if (res.data) {
        this.$store.commit("setCustomers", { customers: res.data });
      }
      this.loading = false;
    },
  },
  async mounted() {
    if (!this.userConfig.id) this.$router.push("/");
    await this.getUser();
		if (this.nav) this.$router.push(this.nav);
  },
};
</script>

<style scoped>
.bg {
  min-height: 91vh;
  background: #ededed;
}
</style>
<template>
  <div class="footer-container">
    <div class="loading" v-if="loading"><Icon class="loading-icon" />"</div>
    <div class="submit-section">
      <div
        class="quote-button"
        :disabled="quote && quote.accepted"
        :class="quote && quote.accepted ? 'accepted' : ''"
        @click="acceptQuote"
      >
        {{ quote && quote.accepted ? "Quote Accepted!" : "Submit & Continue!" }}
      </div>
      <div class="sub-text">
        <sup>*</sup>Monthly estimates based on approved solar financing rates
      </div>
    </div>
    <!-- <div class="footer">
      <div class="video-container">
        <div class="video-header">Videos that might help!</div>
        <div class="img-container">
          <img
            src="../../../assets/images/Quotes/Wave.jpg"
            alt=""
            class="video"
          />
          <div class="video-text">
            Here's what to expect on your new project!
          </div>
        </div>
      </div>
      <div class="live-chat">
        Have additional<br />
        questions?<br />
        Use Our Live Chat!
      </div>
    </div> -->
  </div>
</template>

<script>
import Icon from "@/assets/svg/Logo.svg";

export default {
  props: ["quote", "name", "userId"],
  components: {
    Icon,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async acceptQuote() {
      if (!this.quote || this.quote.accepted) return;
      this.loading = true;
      await this.$http.put(
        `${this.$API}user/${this.userId}/quote/${this.quote.id}`,
        {
          message: `${this.name} has accepted their quote, <a href="${
            window.location.href.split(this.$route.matched[0].path)[0]
          }/algnsk?nav=view/h?u=${this.userId}">click here</a> to see details.`,
        }
      );
      this.$store.commit("acceptQuote", {
        id: parseInt(this.quote.id),
        userId: parseInt(this.userId),
      });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.footer-container {
  position: relative;
}
.submit-section {
  padding-bottom: 5vh;
}
.quote-button {
  font-size: 0.35rem;
  font-weight: bold;
  background: var(--blue-300);
  color: #fff;
  width: fit-content;
  margin: auto;
  padding: 3vh 3vw;
  border-radius: 35px;
  cursor: pointer;
  letter-spacing: 2px;
}
.quote-button:hover {
  background: var(--blue-600);
}
.accepted {
  background: green;
  cursor: default;
}
.accepted:hover {
  background: green;
}
.sub-text {
  width: 20%;
  margin: 1vh auto;
  text-align: center;
  font-size: 0.2rem;
  font-weight: bold;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.video-container {
  width: fit-content;
  margin: 8vh 2vw 0;
}
.video-header {
  color: var(--blue-300);
  font-weight: bold;
  margin-bottom: 1vh;
}
.img-container {
  position: relative;
  height: 28vh;
  overflow: hidden;
  border: 6px solid #4f4d4d70;
  width: 26vw;
  cursor: pointer;
}
.video {
  width: 100%;
}
.video-text {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  background: #fff;
  font-size: 0.2rem;
  padding: 0.5vh 0;
}
.live-chat {
  position: relative;
  text-align: center;
  margin-right: 8vw;
  border: 6px solid var(--blue-300);
  padding: 1vh 2vw;
  border-radius: 35px;
}
.live-chat::after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -1vh;
  right: -0.8vw;
  height: calc(2vh + 0.5vw);
  width: calc(2vh + 0.5vw);
  border: 7px solid var(--blue-300);
  border-left: none;
  border-top: none;
  transform: skew(27deg, 21deg);
  background: linear-gradient(to left top, var(--blue-300) 0%, #fff 2%);
}
</style>

<template>
  <div class="leaderboard-container">
    <div v-if="userConfig.companyCode !== '0' && users.length !== 0">
      <div class="header">Leaderboard</div>
      <div
        class="user"
        v-for="(user, index) of users"
        :key="'user' + index"
        :class="index === 0 ? 'blue' : ''"
      >
        <img class="icon" :src="icons[index]" alt="" />
        <div class="name">{{ user.name }}</div>
        <div>{{ user.points }} Pt{{ user.points === 1 ? "" : "s" }}</div>
        <div>
          {{ user.totalProjects }} Project{{
            user.totalProjects === 1 ? "" : "s"
          }}
        </div>
      </div>
    </div>
    <div class="no-company" v-else>
      {{
        users.length === 0
          ? "Awaiting other representatives to activate leaderboard"
          : "Enroll with a company to access the leaderboards!"
      }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["users"],
  computed: {
    userConfig() {
      return this.$store.getters.userConfig;
    },
  },
  data() {
    return {
      icons: [
        require("../../assets/images/Leaderboard/LeaderboardFirst.png"),
        require("../../assets/images/Leaderboard/LeaderboardSecond.png"),
        require("../../assets/images/Leaderboard/LeaderboardThird.png"),
        require("../../assets/images/Leaderboard/LeaderboardFourth.png"),
      ],
    };
  },
};
</script>

<style scoped>
.leaderboard-container {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 25px;
  background: rgb(246, 246, 246);
}
.header {
  margin: 2vh 2vw;
  opacity: 0.4;
  font-weight: bold;
}
.user {
  opacity: 1;
  display: grid;
  grid-template-areas: "a b c d";
  grid-template-columns: 6vw auto 9vw 9vw;
  align-items: center;
  justify-items: center;
  margin: 2vh 2vw;
}
.blue {
  color: var(--blue-300);
}
.icon {
  max-height: calc(3vh + 1vw);
}
.name {
  margin: 0 auto 0 1vw;
}
.no-company {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
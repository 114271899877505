<template>
  <div class="project-settings-container">
    <div class="loading" v-if="loading">
      <Logo class="loading-icon" />
    </div>
    <div class="project-settings-card">
      <div class="header">
        <strong>{{ project.title }}</strong>
      </div>
      <div
        class="question-container"
        @wheel="handleScroll($event, 'question-container')"
        ref="question-container"
      >
        <component :is="selectedProject" :project="project" ref="submission" />
      </div>
      <div
        class="photo-container"
        @wheel="handleScroll($event, 'photo-container')"
        ref="photo-container"
      >
        <div
          class="single-photo-container"
          v-for="(photo, index) of projectPhotos"
          :key="'photo-' + index"
        >
          <label class="sub">
            <input
              type="file"
              class="photo-input"
              @change="handlePhoto($event, photo.name)"
              accept=".jpg,.png"
            />
            <div class="photo-title">{{ photo.title }}</div>
          </label>
          <div
            class="trash-bin-container"
            @click="removeImage(photo.name)"
            v-if="photo.url"
          >
            <TrashBin class="trash-bin" />
          </div>
          <img class="photo" alt="" :src="photo.url" v-if="photo.url" />
          <div class="photo add-extra" alt="" v-if="!photo.url" />
          <MissingImg class="missing-img" v-if="!photo.url" />
        </div>
        <div class="single-photo-container">
          <label class="sub">
            <input
              type="file"
              class="photo-input"
              @change="handlePhoto"
              accept=".jpg,.png"
            />
            <div class="photo-title">Click to Upload</div>
          </label>
          <div class="photo add-extra" alt="" />
          <Plus class="plus" />
        </div>
      </div>
      <div class="billable-container" v-if="level === 'a'">
        <strong>Adjust Quote Pricing</strong>
        <div class="bill">
          <input
            type="number"
            class="input bill-input"
            v-model="bill"
            placeholder="Billable Amount"
          />
          <div class="button settings-button" @click="setBill">Update</div>
        </div>
      </div>
      <div class="note-container" v-if="/[acsr]/.test(level)">
        <textarea
          class="insert-note"
          v-model="blocked"
          maxlength="50"
          placeholder="Blocked Status"
        />
        <div class="character-limit">{{ characterLimit }}</div>
        <div class="button-container blocked-buttons">
          <div class="button blocked-button" @click="blockProject">
            Block Project
          </div>
          <div class="button blocked-button" @click="unblockProject">
            UnBlock Project
          </div>
        </div>
      </div>

      <div class="note-container">
        <textarea
          class="insert-note"
          v-model="newNote"
          placeholder="Add Note"
        />
        <div class="button note-button" @click="addNote(null)">Submit</div>
      </div>
      <div class="previous-notes-header">Previous Notes Added:</div>
      <div class="previous-notes">
        <div
          class="single-note-container"
          v-for="(note, index) of notes"
          :key="'Note-' + index"
        >
          <div class="note-header">
            <div class="note-name">{{ note.name }}</div>
            <div class="note-date">{{ formatDate(note.createdAt) }}</div>
          </div>
          <div class="note-content">{{ note.content }}</div>
        </div>
      </div>
      <div class="button-container">
        <div class="button settings-button" @click="$router.back()">Back</div>
        <div class="button settings-button" @click="submit">Save</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Generac from "@/components/Common/ProjectSettings/Generac.vue";
import Roofing from "@/components/Common/ProjectSettings/Roofing.vue";
import TreeTrimming from "@/components/Common/ProjectSettings/TreeTrimming.vue";
import Insulation from "@/components/Common/ProjectSettings/Insulation.vue";
import HWH from "@/components/Common/ProjectSettings/HotWaterHeater.vue";

// For any project with only image data
import Empty from "@/components/Common/ProjectSettings/Empty.vue";

import Plus from "@/assets/svg/Plus2.svg";
import TrashBin from "@/assets/svg/TrashBin.svg";
import Logo from "@/assets/svg/Logo.svg";
import MissingImg from "@/assets/svg/MissingImg.svg";

export default {
  props: ["projectId", "userId"],
  components: {
    /* Carousel Questions */
    Generac,
    Roofing,
    TreeTrimming,
    Insulation,
    HWH,
    Empty,
    /* Extra Icons */
    Plus,
    TrashBin,
    Logo,
    MissingImg,
  },
  data() {
    return {
      pages: {
        Generator: Generac,
        Roofing: Roofing,
        ["Tree Trimming/Removal"]: TreeTrimming,
        Empty: Empty,
        Insulation: Insulation,
        ["Hot Water Heater"]: HWH,
      },
      bill: 0,
      // Used for force Updating
      photoIndexing: 0,
      // loading icon
      loading: false,
      newNote: "",
      blocked: "",
      notes: [],
    };
  },
  computed: {
    ...mapGetters(["userConfig", "images", "level", "customers"]),
    user() {
      return this.userId
        ? this.customers.filter((a) => a.id === parseInt(this.userId))[0]
        : this.userConfig;
    },
    project() {
      return this.user.projects.filter(
        (a) => a.projectId === parseInt(this.projectId)
      )[0];
    },
    selectedProject() {
      const title = this.project.title;
      let name = "Empty";
      if (title !== "Pool Pumps" && title !== "HVAC") name = title;
      return this.pages[name];
    },
    isHomeowner() {
      return this.level === "u";
    },
    projectPhotos() {
      const imageArray = [];
      const prefix = this.user.username;
      // Forces refresh on complex object
      this.photoIndexing;
      // Check projects and push neccessary images
      for (const project of this.user.projects) {
        switch (project.title) {
          case "Tree Trimming/Removal": {
            let treeImages = Object.keys(this.images);
            treeImages = treeImages.filter((image) =>
              image.includes("TreePhoto")
            );
            for (let i = 0; i < treeImages.length; i++) {
              imageArray.push({
                name: `${prefix}TreePhoto${i}`,
                url: this.images[treeImages[i]],
                title: "Tree Photo " + (i + 1),
              });
            }
            if (treeImages.length === 0) {
              imageArray.push({
                name: `${prefix}TreePhoto0`,
                url: this.images[`${prefix}TreePhoto0`],
                title: "Tree Photo",
              });
            }
            break;
          }
          case "Pool Pumps":
            imageArray.push({
              name: `${prefix}PoolPump`,
              url: this.images[`${prefix}PoolPump`],
              title: "Pool Pump",
            });
            imageArray.push({
              name: `${prefix}PoolTag`,
              url: this.images[`${prefix}PoolTag`],
              title: "Pool Pump Tag",
            });
            break;
          case "Insulation": {
            let insulationImages = Object.keys(this.images);
            insulationImages = insulationImages.filter((image) =>
              image.includes("Insulation")
            );
            for (let i = 0; i < insulationImages.length; i++) {
              imageArray.push({
                name: `${prefix}Insulation${i}`,
                url: this.images[insulationImages[i]],
                title: "Attic Photo " + (i + 1),
              });
            }
            if (insulationImages.length === 0) {
              imageArray.push({
                name: `${prefix}Insulation0`,
                url: this.images[`${prefix}Insulation0`],
                title: "Attic Photo 1",
              });
            }
            break;
          }
          case "Hot Water Heater":
            imageArray.push({
              name: `${prefix}HWH`,
              url: this.images[`${prefix}HWH`],
              title: "HWH",
            });
            imageArray.push({
              name: `${prefix}HWHDataTag`,
              url: this.images[`${prefix}HWHDataTag`],
              title: "HWH Tag",
            });
            break;
          case "HVAC":
            imageArray.push({
              name: `${prefix}ExACUnit`,
              url: this.images[`${prefix}ExACUnit`],
              title: "Exterior AC",
            });
            imageArray.push({
              name: `${prefix}ExACUnitDataTag`,
              url: this.images[`${prefix}ExACUnitDataTag`],
              title: "Exterior AC Tag",
            });
            imageArray.push({
              name: `${prefix}InACUnit`,
              url: this.images[`${prefix}InACUnit`],
              title: "Interior AC",
            });
            imageArray.push({
              name: `${prefix}InACUnitDataTag`,
              url: this.images[`${prefix}InACUnitDataTag`],
              title: "Interior AC Tag",
            });
            break;
          default:
            break;
        }
      }

      // Required photos regardless of project
      imageArray.push({
        name: `${prefix}leftHomePhotoUrl`,
        url: this.images[`${prefix}leftHomePhotoUrl`],
        title: "Left Home Photo",
      });
      imageArray.push({
        name: `${prefix}rightHomePhotoUrl`,
        url: this.images[`${prefix}rightHomePhotoUrl`],
        title: "Right Home Photo",
      });
      imageArray.push({
        name: `${prefix}backHomePhotoUrl`,
        url: this.images[`${prefix}backHomePhotoUrl`],
        title: "Back Home Photo",
      });
      imageArray.push({
        name: `${prefix}frontHomePhotoUrl`,
        url: this.images[`${prefix}frontHomePhotoUrl`],
        title: "Front Home Photo",
      });
      return imageArray;
    },
    characterLimit() {
      if (!this.blocked) return "50 Characters Remaining";
      return `${50 - this.blocked.length} Character${
        50 - this.blocked.length === 1 ? "" : "s"
      } Remaining.`;
    },
  },
  methods: {
    async setBill() {
      if (this.bill === this.project.bill) return;
      this.loading = true;
      await this.$http.put(`${this.$API}project/${this.projectId}/bill`, {
        bill: this.bill,
      });
      this.$store.commit("updateProjects", {
        projectId: parseInt(this.projectId),
        key: "bill",
        value: this.bill,
        userId: parseInt(this.userId),
        isHomeowner: this.isHomeowner,
      });
      this.loading = false;
    },
    async getNotes() {
      if (this.project.notes) {
        this.notes = this.project.notes;
        return;
      }

      const tableName = this.getProjectTitle();
      const context = this.getContext();
      const projectId = parseInt(this.projectId);
      const route =
        context === "admin"
          ? `project/${projectId}/notes`
          : `${context}/${this.userConfig.id}/project/${projectId}/note`;

      await this.$http.get(`${this.$API}${route}`).then((res) => {
        if (res.data) {
          this.notes = res.data;
          this.$store.commit("setNotes", {
            id: this.user.id,
            tableName,
            notes: res.data,
            isHomeowner: this.isHomeowner,
          });
        } else {
          this.$store.commit("setNotes", {
            id: this.user.id,
            tableName,
            notes: [],
            isHomeowner: this.isHomeowner,
          });
        }
      });
    },
    async addNote(note) {
      if (!this.newNote && !note) return;

      const table = this.getProjectTitle();
      const context = this.getContext();
      const projectId = parseInt(this.projectId);
      let payload = {
        name: `${this.userConfig.fName} ${this.userConfig.lName}`,
        table,
        projectId,
        context,
        userId: this.userConfig.id,
        content: note ? note : this.newNote,
        createdAt: new Date().toString(),
      };
      this.loading = true;
      await this.$http.post(
        `${this.$API}${context}/${this.userConfig.id}/project/${projectId}/note`,
        payload
      );
      payload = {
        ...payload,
        id: this.user.id,
        tableName: table,
        isHomeowner: this.isHomeowner,
      };
      this.$store.commit("addNote", payload);
      this.newNote = "";
      this.getNotes();
      this.loading = false;
    },
    formatDate(date) {
      return (
        new Date(date)
          .toLocaleString()
          .substring(0, 15)
          .replace(/\//g, "-")
          .replace(/,/g, "") + new Date(date).toLocaleString().substring(18)
      );
    },
    async handlePhoto(e, target) {
      const file = e.target.files[0];
      const photo = URL.createObjectURL(file);
      const index = file.name.lastIndexOf(".");

      let formData = new FormData();
      formData.append("image", file, `${target}${file.name.substr(index)}`);
      await this.$http.put(`${this.$API}image/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      this.$store.commit("alterImage", {
        key: target,
        value: photo,
      });
      this.photoIndexing++;
    },
    removeImage(target) {
      this.$store.commit("deleteImage", target);
      this.photoIndexing--;
    },
    handleScroll(e, selection) {
      e.preventDefault();
      this.$refs[selection].scrollLeft += e.deltaY;
    },
    getProjectTitle() {
      let projectTitle;
      switch (this.project.title) {
        case "Generator":
          projectTitle = "generac";
          break;
        case "Pool Pumps":
          projectTitle = "pool_pumps";
          break;
        case "Tree Trimming/Removal":
          projectTitle = "tree_trimming";
          break;
        case "Hot Water Heater":
          projectTitle = "hot_water_heater";
          break;
        case "HVAC":
        case "Insulation":
        case "Roofing":
          projectTitle = this.project.title.toLowerCase();
          break;
        default:
          break;
      }
      return projectTitle;
    },
    getContext() {
      let context;
      switch (this.$route.matched[0].path) {
        case "/r":
          context = "rep";
          break;
        case "/c":
        case "/s":
          context = "company";
          break;
        case "/h":
          context = "user";
          break;
        default:
          context = "admin";
          break;
      }
      return context;
    },
    async submit() {
      const payload = this.$refs.submission.payload;
      const projectTitle = this.getProjectTitle();
      this.loading = true;
      const promises = [];
      Object.keys(payload).forEach((key) => {
        if (payload[key] != this.project[key]) {
          promises.push(
            this.$http.put(`${this.$API}project/${this.projectId}`, {
              table: projectTitle,
              field: key,
              value: payload[key],
            })
          );
          this.$store.commit("updateProjects", {
            projectId: parseInt(this.projectId),
            key: key,
            value: payload[key],
            userId: parseInt(this.userId),
            isHomeowner: this.isHomeowner,
          });
        }
      });
      await Promise.all(promises);
      this.loading = false;
    },
    async blockProject() {
      if (!this.blocked) return;
      const note = `Project was blocked on ${this.formatDate(
        new Date()
      )} due to: "${this.blocked}"`;
      let payload = {
        projectId: parseInt(this.projectId),
        reason: this.blocked,
      };
      this.loading = true;
      await this.$http.put(
        `${this.$API}project/${this.projectId}/block`,
        payload
      );
      await this.addNote(note);
      payload = {
        userId: parseInt(this.userId),
        projectId: parseInt(this.projectId),
        key: "blockedStatus",
        value: this.blocked,
      };
      this.$store.commit("updateProjects", payload);
      payload.projectName = this.getProjectTitle();
      this.$store.commit("updateProjectSnapshot", payload);
      payload = {
        id: parseInt(this.userId),
        userType: "user",
        blocked: true,
      };
      this.$store.commit("updateUser", payload);
      this.loading = false;
    },
    async unblockProject() {
      if (!this.project.blockedStatus) return;
      this.blocked = "";
      const note = `Project was unblocked on ${this.formatDate(new Date())}`;
      this.loading = true;
      await this.$http.delete(`${this.$API}project/${this.projectId}/block`);
      await this.addNote(note);
      const payload = {
        userId: parseInt(this.userId),
        projectId: parseInt(this.projectId),
        key: "blockedStatus",
        value: null,
      };
      this.$store.commit("updateProjects", payload);
      payload.projectName = this.getProjectTitle();
      this.$store.commit("updateProjectSnapshot", payload);
      this.loading = false;
    },
  },
  async mounted() {
    this.loading = true;
    await this.getNotes();
    this.bill = this.project.bill;
    this.blocked = this.project.blockedStatus;
    this.loading = false;
  },
};
</script>

<style scoped>
.project-settings-container {
  overflow-x: auto;
  height: 91vh;
  width: 100%;
  background: #ededed;
  font-size: 0.25rem;
}
.project-settings-card {
  width: 80vw;
  margin: 8vh auto;
  padding: 2vh;
  border-radius: 25px;
  background: #ffffff;
}
.header {
  margin: 3vh 3vw 0;
}
.question-container,
.photo-container {
  margin: 3vh 3vw;
  display: flex;
  overflow: auto;
}
.photo-container {
  justify-content: center;
  margin: 5vh auto;
  width: 92%;
  padding-bottom: 2vh;
}
.billable-container {
  margin: 5vh 3vw;
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
}
.bill {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bill-input {
  width: 20vw;
}
.single-photo-container {
  position: relative;
  margin: 0 1vw;
}
.photo {
  height: 14vh;
  width: 20vh;
  object-fit: contain;
  text-align: center;
  border-radius: 25px;
  border-color: var(--blue-300);
  transition: border-color 0.5s ease;
}
.add-extra {
  background: #ededed;
}
.sub {
  position: absolute;
  z-index: 2;
  bottom: 0vh;
  width: 20vh;
  height: 17vh;
  opacity: 0.5;
  font-size: 0.2rem;
  background: none;
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.plus {
  position: absolute;
  height: 6vh;
  top: 4vh;
  left: calc(50% - 3vh);
  opacity: 0.2;
}
.missing-img {
  position: absolute;
  top: 3vh;
  width: 8vh;
  left: calc(50% - 4vh);
  opacity: 0.2;
}
.missing-img-text {
  position: absolute;
  bottom: 4vh;
  width: 20vh;
  text-align: center;
  font-size: 0.2rem;
  opacity: 0.3;
}
.trash-bin-container {
  position: absolute;
  z-index: 3;
  top: 2.5vh;
  right: 2.5vh;
  height: 0;
  width: 0;
  background: var(--blue-600);
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.5s ease;
  cursor: pointer;
}
.trash-bin-container:hover {
  background: var(--error-color);
  top: 0;
  right: 0;
  height: 5vh;
  width: 5vh;
}
.trash-bin-container:hover .trash-bin {
  height: 3vh;
  margin: 1vh;
}
.trash-bin-container:hover ~ img.photo,
.trash-bin-container:hover ~ .photo {
  border: 10px solid var(--error-color);
  height: calc(14vh - 20px);
  width: calc(20vh - 20px);
}

.trash-bin {
  height: 0;
  margin: 0;
  fill: white;
  transition: all 0.5s ease;
}
.sub:hover {
  background: none;
  color: inherit;
}
.sub:hover ~ .photo {
  border: 10px solid var(--blue-300);
  height: calc(14vh - 20px);
  width: calc(20vh - 20px);
}
.sub:hover ~ img.photo {
  border: 10px solid var(--blue-300);
  height: calc(14vh - 20px);
  width: calc(20vh - 20px);
}
.sub:hover ~ .plus {
  opacity: 0.5;
  fill: var(--blue-600);
}

.sub:hover ~ .missing-img {
  fill: var(--blue-600);
}
.sub:hover ~ .missing-img-text {
  color: var(--blue-600);
}
.sub:hover ~ .trash-bin-container {
  top: 0;
  right: 0;
  height: 5vh;
  width: 5vh;
}
.sub:hover ~ .trash-bin-container .trash-bin {
  height: 3vh;
  margin: 1vh;
}
.button-container {
  margin: 0 3vw;
  display: flex;
  justify-content: space-between;
}
.blocked-buttons {
  position: absolute;
  z-index: 1;
  bottom: 7vh;
  right: 1vw;
  width: 30%;
}
.blocked-button {
  min-width: 11vw;
}
.blocked-button,
.note-button {
  color: #fff;
  background: var(--blue-600);
}
.character-limit {
  position: absolute;
  bottom: 7vh;
  left: 4vw;
  opacity: 0.5;
}
.settings-button {
  width: fit-content;
  padding: 0vh 3vw;
}
.note-container {
  position: relative;
}
.insert-note {
  resize: none;
  padding: 2vh 2vw;
  width: calc(100% - 10vw);
  margin: 0 0 5vh 3vw;
  height: 20vh;
  white-space: pre-line;
  background: #ededed;
  border: none;
  font-size: 0.25rem;
}
.note-button {
  position: absolute;
  bottom: 7vh;
  right: 4vw;
  width: 13vw;
}
.blocked-button:hover,
.note-button:hover {
  background: var(--blue-300);
}
textarea::-webkit-input-placeholder {
  color: #000;
  font-weight: bold;
  opacity: 1;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
  font-weight: bold;
  opacity: 1;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
  font-weight: bold;
  opacity: 1;
}

textarea:-ms-input-placeholder {
  color: #000;
  font-weight: bold;
  opacity: 1;
}

textarea::placeholder {
  color: #000;
  font-weight: bold;
  opacity: 1;
}
textarea:focus-visible {
  outline: none;
}
.previous-notes-header {
  width: calc(100% - 6vw);
  margin: auto;
  font-weight: bold;
  font-size: 0.3rem;
  margin-bottom: 2vh;
}
.previous-notes {
  max-height: 30vh;
  width: calc(100% - 6vw);
  margin: auto;
  overflow: auto;
  margin-bottom: 2vh;
}
.single-note-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
}
.note-header {
  display: flex;
  align-items: flex-end;
}
.note-date {
  font-size: 0.17rem;
  margin-left: 0.5vw;
  opacity: 0.7;
}
.note-content {
  font-size: 0.24rem;
}
.photo-title {
  position: relative;
  top: -1.5vh;
}
</style>

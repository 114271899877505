<template>
  <div class="add-project-container" v-if="level !== 'u'">
    <div class="loading" v-if="loading"><Icon class="loading-icon" />"</div>
    <div class="add-project" v-if="popup === true">
      <SelectProject
        v-show="!componentNumber && componentNumber !== 0"
        @select="selectProject"
        @clear="clearProject"
        :options="options"
      />
      <div
        class="button-container"
        v-show="!componentNumber && componentNumber !== 0"
      >
        <div class="button" @click="clearAll">
          Quit
        </div>
        <div class="button" @click="submitAll">
          Submit
        </div>
      </div>

      <NewProject
        :maxPages="maxPages"
        :componentName="componentName"
        :componentNumber="componentNumber"
        v-if="componentNumber || componentNumber === 0"
        @quit="clearProject()"
        @submit="submitProject"
      />
    </div>
    <Plus class="add-project-button" @click="popup = true" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SelectProject from "@/components/Common/NewLead/SelectProject.vue";
import NewProject from "@/components/Common/NewLead/NewLeadSection.vue";
import Plus from "@/assets/svg/Plus2.svg";
import Icon from "@/assets/svg/Logo.svg";

export default {
  props: ["userId"],
  components: {
    SelectProject,
    NewProject,
    Plus,
		Icon,
  },
  data() {
    return {
			loading: false,
      popup: false,
      componentNumber: null,
      maxPages: 0,
      componentName: "",
      options: [],
      payload: {},
      images: {},
    };
  },
  computed: {
    ...mapGetters(["level", "userConfig"]),
  },
  methods: {
    selectProject(payload) {
      this.componentName = payload.componentName;
      this.componentNumber = payload.componentNumber;
      this.maxPages = payload.maxPages;
      this.options.push(payload.componentName);
    },
    clearProject(payload) {
      const componentName = payload
        ? payload.componentName
        : this.componentName;
      this.options = this.options.filter((a) => a !== componentName);
      Object.keys(this.payload).forEach((a) => {
        if (a === componentName) {
          delete this.payload[a];
          delete this.images[a];
        }
      });
      this.componentNumber = null;
    },
    submitProject(payload) {
      Object.keys(payload).forEach((a) => {
        if (a !== "images") {
          this.payload[a] = payload[a];
          this.images[a] = payload.images;
        }
      });
      this.componentNumber = null;
    },
    async submitAll() {
      if (
        Object.keys(this.payload).length === 0 &&
        Object.keys(this.images).length === 0
      ) {
        return;
      }
			this.loading = true;
      const userId = this.userId ? parseInt(this.userId) : this.userConfig.id;
      for (const key in this.payload) {
        let payload = {
          userId: userId,
          projectType: key,
          projectOptions: this.payload[key],
        };
        const projectId = (
          await this.$http.post(`${this.$API}project`, payload)
        ).data.projectId;
        const project = (
          await this.$http.get(`${this.$API}project/${projectId}`)
        ).data;
        this.$store.commit("addProject", { project: project, userId: userId });
				this.$emit("refreshProjects");
      }
			this.loading = false;
      this.clearAll();
    },
    clearAll() {
      this.options = [];
      this.payload = {};
      this.images = {};
      this.popup = false;
    },
  },
};
</script>

<style scoped>
.add-project {
  position: fixed;
  top: 9vh;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d4d4d4;
  z-index: 8;
}
.add-project-button {
  border-radius: 50%;
  background: var(--blue-300);
  height: calc(2vh + 1vw);
  width: calc(2vh + 1vw);
  padding: calc(0.6vh + 0.2vw);
  cursor: pointer;
  transition: fill 0.3s ease;
}
.add-project-button:hover {
  fill: #fff;
}
.button-container {
  position: absolute;
  bottom: 15vh;
  right: 18vw;
  display: flex;
  column-gap: 4vw;
}
.button {
  width: fit-content;
  padding: 0.5vh 1vw;
}
</style>

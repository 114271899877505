<template>
  <div class="hot-water-heater-container">
    <div class="hot-water-heater-questions-section">
      <div class="item-container">
        Is the Heater gas or electric?
        <div class="button-container">
          <div
            class="button"
            @click="isGas = 'Gas'"
            :class="isGas === 'Gas' ? 'active' : ''"
          >
            Gas
          </div>
          <div
            class="button"
            @click="isGas = 'Electric'"
            :class="isGas === 'Electric' ? 'active' : ''"
          >
            Electric
          </div>
        </div>
      </div>
      <div class="item-container">
        Where is the heater located?
        <input
          class="input"
          type="text"
          v-model="location"
          placeholder="Input location:"
        />
      </div>
    </div>
    <div class="hot-water-heater-photo-section">
      <div class="hot-water-heater-header">
        Please Provide Images of the Heater:
      </div>
      <div class="hot-water-heater-photo-container">
        <img class="photo" alt="" @click="handleClick(false)" :src="HWHPhoto" />
        <label class="sub">
          <input
            type="file"
            class="photo-input"
            @change="handlePhoto"
            accept=".jpg,.png"
            ref="HWHPhoto"
          />
          Heater
        </label>
      </div>
      <div class="hot-water-heater-tag-container">
        <img
          class="photo"
          alt=""
          @click="handleClick(true)"
          :src="HWHDataTagPhoto"
        />
        <label class="sub">
          <input
            type="file"
            class="photo-input"
            @change="handlePhoto"
            accept=".jpg,.png"
            ref="HWHDataTagPhoto"
          />
          Data Tag
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentPage"],
  data() {
    return {
      isGas: "Gas",
      location: "",
      HWHPhoto: require("../../../../assets/images/HotWaterHeater.jpg"),
      HWHDataTagPhoto: require("../../../../assets/images/HotWaterHeaterTag.jpg"),
      HWHPhotoUrl: "",
      HWHDataTagUrl: "",
    };
  },
  computed: {
    payload() {
      return {
        location: this.location,
        isGas: this.isGas,
      };
    },
    imagePayload() {
      return {
        HWH: this.HWHPhotoUrl,
        HWHDataTag: this.HWHDataTagUrl,
      };
    },
  },
  methods: {
    handlePhoto(e) {
      const photoChoice = e.target.nextSibling.textContent
        .toString()
        .replace(/ /g, "");
      const file = e.target.files[0];
      const photo = URL.createObjectURL(file);
      if (photoChoice === "Heater") {
        this.HWHPhoto = photo;
        this.HWHPhotoUrl = file;
      } else {
        this.HWHDataTagPhoto = photo;
        this.HWHDataTagUrl = file;
      }
    },
    handleClick(isTag) {
      if (isTag) this.$refs.HWHDataTagPhoto.click();
      else this.$refs.HWHPhoto.click();
    },
  },
};
</script>

<style scoped>
.hot-water-heater-container {
  height: 100%;
  width: 100%;
}
.hot-water-heater-questions-section {
  height: 25%;
  width: 90%;
  margin: 5% 5% 0 5%;
  display: flex;
}

.item-container {
  width: 40%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button-container {
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  width: 100%;
}
.input {
  width: 70%;
  margin: auto;
}
.hot-water-heater-photo-section {
  height: 60%;
  width: 86%;
  display: grid;
  grid-template-areas:
    ". a a ."
    "b b c c";
  margin: auto;
}
.hot-water-heater-header {
  grid-area: a;
  margin: auto;
  font-size: 0.3rem;
}
.hot-water-heater-photo-container,
.hot-water-heater-tag-container {
  grid-area: b;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.hot-water-heater-tag-container {
  grid-area: c;
}
.photo {
  max-width: 10vw;
  max-height: 15vh;
  margin: 0;
  cursor: pointer;
}
.photo:hover ~ .sub {
  background: var(--blue-600);
  color: #fff;
}
</style>

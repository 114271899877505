<template>
  <div>
    <div class="loading" v-if="loading">
      <Logo class="loading-icon" />
    </div>
    <Header :navItems="navItems" :openNav="openNav" />
    <div @click="closeNav" class="bg">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Components
import Header from "@/components/Common/Header/Header.vue";
import Logo from "@/assets/svg/Logo.svg";

// Icons
import Customers from "@/assets/svg/UserProfile2.svg";
import HomeIcon from "@/assets/svg/Star.svg";
import Logout from "@/assets/svg/Logout4.svg";
import NewUserIcon from "@/assets/svg/Plus2.svg";
import AssignSolarIcon from "@/assets/svg/Arrows.svg";
import Email from "@/assets/svg/Mail3.svg";

export default {
	props: ["nav"],
  components: {
    Header,
    Logo,
  },
  data() {
    return {
      loading: false,
      openNav: true,
      navItems: [
        {
          copy: "Dashboard",
          link: "landing",
          icon: HomeIcon,
        },
        {
          copy: "Search Users",
          link: "filterUser",
          icon: Customers,
        },
        {
          copy: "Add User",
          link: "addUser",
          icon: NewUserIcon,
        },
        {
          copy: "Assign Solar Company",
          link: "assignSolar",
          icon: AssignSolarIcon,
        },
        {
          copy: "Send Emails",
          link: "sendEmail",
          icon: Email,
        },
        // 'Analytics',
        {
          copy: "Logout",
          link: "logout",
          icon: Logout,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userConfig", "customers"]),
  },
  methods: {
    closeNav() {
      this.openNav = false;
      setTimeout(() => {
        this.openNav = true;
      }, 500);
    },
    async getList() {
      this.loading = true;
      await this.$http.get(`${this.$API}admin/user`).then((res) => {
        if (res.data.length > 0)
          this.$store.commit("setCustomers", { customers: res.data });
      });
      await this.$http.get(`${this.$API}admin/rep`).then((res) => {
        if (res.data.length > 0)
          this.$store.commit("setReps", { reps: res.data });
      });
      await this.$http.get(`${this.$API}admin/company`).then((res) => {
        if (res.data.length > 0)
          this.$store.commit("setCompanies", { companies: res.data });
      });
      this.loading = false;
    },
    checkDates() {
      for (const customer of this.customers) {
        for (const project of customer.projectSnapShot) {
          if (project.scheduledAt) {
            if (
              Math.floor(
                (new Date(project.scheduledAt).getTime() -
                  new Date().getTime()) /
                  (1000 * 60 * 60 * 24)
              ) < 1 &&
              project.completion !== 5
            ) {
              this.createNotification(customer, project);
            }
          }
        }
      }
    },
    createNotification(user, project) {
      const notification = {
        action: "View",
        action_key: `{"context":"user", "id": ${user.id}}`,
        created_at: new Date().toISOString(),
        id: 0,
        message: `${user.fName} ${user.lName} has ${project.projectName} overdue`,
        unread: 1,
      };
      this.$store.commit("addNotification", notification);
    },
  },
  async mounted() {
    if (!this.userConfig.id) this.$router.push("/");
    await this.getList();
    this.checkDates();
		if (this.nav) this.$router.push(this.nav);
  },
};
</script>

.<style scoped>
.bg {
  min-height: 91vh;
  background: #ededed;
}
</style>

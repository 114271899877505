<template>
  <div class="company-container">
    <div class="top-container" v-if="page === 0">
      <div class="company-info-title">Company Information</div>
      <div class="company-info-container">
        <input
          type="text"
          title="Company Name"
          placeholder="Company Name"
          v-model="companyName"
          class="input"
          @change="removeError(0)"
          required
        />
        <input
          type="text"
          title="EIN Number"
          placeholder="EIN Number"
          v-model="ein"
          class="input"
          @change="removeError(1)"
          required
        />
        <input
          type="number"
          title="Number of Representatives"
          placeholder="Number of Representatives"
          v-model="numberOfReps"
          class="input"
          @change="removeError(2)"
          required
        />
        <input
          type="number"
          title="Number of Offices"
          placeholder="Number of Offices"
          v-model="numberOfOffices"
          class="input"
          @change="removeError(3)"
          required
        />
      </div>
    </div>

    <div class="top-container" v-if="page === 1">
      <div class="photo-upload-title">Upload Company Logo</div>
      <div class="photo-container">
        <label class="alt-sub" :class="companyLogo ? 'hidden-click' : ''">
          <input
            type="file"
            class="photo-input"
            @change="handlePhoto"
            accept=".jpg,.png"
          />
          Click to Upload
        </label>
        <img
          class="photo"
          :class="companyLogo ? '' : 'hidden'"
          alt=""
          :src="companyLogo"
        />
      </div>
    </div>

    <div class="submit-container">
      <div class="err-msg">{{ errMsg }}</div>
      <div class="button-container">
        <div class="button" @click="back">Back</div>
        <div class="button" @click="next">
          {{ page === 0 ? "Next" : "Submit" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyName: "",
      ein: "",
      numberOfReps: "",
      numberOfOffices: "",
      companyLogo: "",
      companyLogoUrl: "",
      errMsg: "",
      page: 0,
    };
  },
  computed: {
    payload() {
      return {
        companyName: this.companyName,
        ein: this.ein,
        numberOfReps: this.numberOfReps,
        numberOfOffices: this.numberOfOffices,
        userPhoto: this.companyLogoUrl,
        context: "company",
      };
    },
  },
  methods: {
    checkFilled() {
      const inputFields = document.getElementsByClassName("input");
      let isFilled = true;
      for (const input of inputFields) {
        if (!input.checkValidity()) {
          input.classList.add("invalid-input");
          isFilled = false;
        }
      }
      return isFilled;
    },
    removeError(number) {
      const field = document.getElementsByClassName("input")[number];
      if (field.classList.contains("invalid-input"))
        field.classList.remove("invalid-input");
      this.errMsg = "";
    },
    handlePhoto(e) {
      const file = e.target.files[0];
      const photo = URL.createObjectURL(file);
      this.companyLogo = photo;
      this.companyLogoUrl = file;
    },
    next() {
      if (this.checkFilled()) {
        if (this.page === 0) this.page++;
        else this.submit();
      } else {
        this.errMsg = "Please fill out all fields";
      }
    },
    submit() {
      this.$emit("submit", this.payload);
    },
    back() {
      if (this.page !== 0) this.page--;
      else this.$emit("back");
    },
  },
};
</script>

<style scoped>
.company-container {
  font-size: 0.35rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.company-info-title {
  margin-top: 4vh;
}
.company-info-container {
  margin-top: 4vh;
  width: 100%;
  display: grid;
  grid-template-areas:
    "a b"
    "c d";
  column-gap: 2vw;
  row-gap: 2vh;
}
.input {
  width: 100%;
}
.hidden {
  display: none;
}
.hidden-click {
  opacity: 0;
  margin-bottom: -38vh;
}
.photo-upload-title {
  margin-top: 5vh;
  width: 100%;
  text-align: center;
}
.photo-container {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.photo {
  margin-top: 2vh;
  height: 36vh;
  width: 36vh;
  object-fit: cover;
  border-radius: 50%;
}
.alt-sub {
  margin-top: 5vh;
  height: calc(18vh + 0.125rem);
  padding-top: calc(18vh - 0.125rem);
  width: 36vh;
  border-radius: 50%;
  background: #ededed;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.25rem;
  cursor: pointer;
  text-align: center;
}
.submit-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
.err-msg {
  color: var(--error-color);
}
.button-container {
  display: flex;
  justify-content: space-between;
  width: 40%;
}
.button {
  width: 47%;
}
</style>

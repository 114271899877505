<template>
  <div class="generator-container">
    <div class="item-container">
      <div class="question">
        Does the home have an active gas meter?
      </div>
      <div class="button-container">
        <div
          class="button"
          @click="gasMeter = true"
          :class="gasMeter ? 'active' : ''"
        >
          Yes
        </div>
        <div
          class="button"
          @click="gasMeter = false"
          :class="!gasMeter ? 'active' : ''"
        >
          No
        </div>
      </div>
    </div>
    <div class="item-container">
      <div class="question">
        Does the home have gas lines ran on the street, and are they accessible?
      </div>
      <div class="button-container">
        <div
          class="button generator-button"
          @click="access = true"
          :class="access ? 'active' : ''"
        >
          Yes
        </div>
        <div
          class="button generator-button"
          @click="access = false"
          :class="!access ? 'active' : ''"
        >
          No
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["project"],
  data() {
    return {
      gasMeter: false,
      access: false,
    };
  },
  computed: {
    userConfig() {
      return this.$store.getters.userConfig;
    },
    payload() {
      return {
        gasMeter: this.gasMeter,
        access: this.access,
      };
    },
  },
  mounted() {
    this.access = this.project.access ? this.project.access : false;
    this.gasMeter = this.project.gasMeter ? this.project.gasMeter : false;
  },
};
</script>

<style scoped>
.generator-container {
  display: flex;
  justify-content: space-between;
  height: 15vh;
  margin: 0 2vw;
}
.item-container {
  display: flex;
  width: 42%;
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.button-container {
  margin: 0 3vw;
  display: flex;
  justify-content: space-between;
}
</style>

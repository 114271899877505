import Vue from "vue";
import VueRouter from "vue-router";

// Admin
import Admin from "../views/Admin/Admin.vue";
import AdminQuotes from "../views/Admin/AdminQuotes.vue";
import AdminLanding from "../views/Admin/AdminLanding.vue";
import AdminAddUser from "../views/Admin/AdminAddUser.vue";
import AssignSolar from "../views/Admin/AssignSolar.vue";
import SendEmail from "../views/Admin/AdminSendEmail.vue";

// Solar
import Solar from "../views/Solar/Solar.vue";
import SolarLanding from "../views/Solar/SolarLanding.vue";

// Company
import Company from "../views/Company/Company.vue";
import CompanyLanding from "../views/Company/CompanyLanding.vue";

// Rep
import Rep from "../views/Rep/Rep.vue";
import RepLanding from "../views/Rep/RepLanding.vue";
import NewLead from "../views/Common/NewLead.vue";

// Homeowner
import Homeowner from "../views/Homeowner/Homeowner.vue";

// Common
import Home from "../views/HomeV2.vue";
import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import FilterUser from "../views/Common/FilterUser.vue";
import ViewHomeowner from "../views/Common/ViewCustomer.vue";
import ViewRep from "../views/Common/ViewRep.vue";
import ViewCompany from "../views/Common/ViewCompany.vue";
import ProjectSettings from "../views/Common/ProjectSettings.vue";
import ViewQuote from "../views/Common/Quote.vue";

// ErrorPage
import PageNotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Home,
  },
  {
    path: "/su",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/algnsk",
    name: "AdminLogin",
    component: Login,
		props: (route) => ({ nav: route.query.nav }),
  },
  {
    path: "/rlgn",
    name: "RepLogin",
    component: Login,
		props: (route) => ({ nav: route.query.nav }),
  },
  {
    path: "/holn",
    name: "HomeownerLogin",
    component: Login,
		props: (route) => ({ nav: route.query.nav }),
  },
  {
    path: "/clgn",
    name: "CompanyLogin",
    component: Login,
		props: (route) => ({ nav: route.query.nav }),
  },
  {
    path: "/slgn",
    name: "SolarLogin",
    component: Login,
		props: (route) => ({ nav: route.query.nav }),
  },
  {
    path: "/a",
    name: "Admin",
    component: Admin,
		props: (route) => ({ nav: route.query.nav }),
    children: [
      {
        path: "landing",
        name: "AdminLanding",
        component: AdminLanding,
      },
      {
        path: "addUser",
        name: "AdminAddUser",
        component: AdminAddUser,
      },
      {
        path: "filterUser",
        name: "AdminFilterUser",
        component: FilterUser,
        props: (route) => ({ navFilter: route.query.nf }),
      },
      {
        path: "assignSolar",
        name: "AdminAssignSolar",
        component: AssignSolar,
      },
      {
        path: "sendEmail",
        name: "AdminSendEmail",
        component: SendEmail,
      },
      {
        path: "createQuote",
        name: "AdminCreateQuote",
        component: AdminQuotes,
        props: (route) => ({ userId: route.query.u }),
      },
      {
        path: "view/q",
        name: "AdminViewQuotes",
        component: ViewQuote,
        props: (route) => ({ userId: route.query.u, quoteId: route.query.q }),
      },
      {
        path: "view/h",
        name: "AdminViewHomeowner",
        component: ViewHomeowner,
        props: (route) => ({ userId: route.query.u }),
      },
      {
        path: "view/h/p",
        name: "AdminProjectSettings",
        component: ProjectSettings,
        props: (route) => ({ userId: route.query.u, projectId: route.query.p }),
      },
      {
        path: "view/r",
        name: "AdminViewRep",
        component: ViewRep,
        props: (route) => ({ userId: route.query.u }),
      },
      {
        path: "view/:id([cs])",
        name: "AdminViewCompany",
        component: ViewCompany,
        props: (route) => ({ userId: route.query.u }),
      },
    ],
  },
  {
    path: "/h",
    name: "Homeowner",
    component: Homeowner,
		props: (route) => ({ nav: route.query.nav }),
    children: [
      {
        path: "landing",
        name: "HomeownerLanding",
        component: ViewHomeowner,
      },
      {
        path: "view/q",
        name: "HomeownerViewQuotes",
        component: ViewQuote,
        props: (route) => ({ quoteId: route.query.q }),
      },
      {
        path: "landing/p",
        name: "HomeownerProjectSettings",
        component: ProjectSettings,
        props: (route) => ({ projectId: route.query.p }),
      },
    ],
  },
  {
    path: "/r",
    name: "Rep",
    component: Rep,
		props: (route) => ({ nav: route.query.nav }),
    children: [
      {
        path: "landing",
        name: "RepLanding",
        component: RepLanding,
      },
      {
        path: "filterUser",
        name: "RepFilterUser",
        component: FilterUser,
        props: (route) => ({ navFilter: route.query.nf }),
      },
      {
        path: "newLead",
        name: "RepNewLead",
        component: NewLead,
      },
      {
        path: "view/r",
        name: "RepWallet",
        component: ViewRep,
      },
      {
        path: "view/h",
        name: "RepViewHomeowner",
        component: ViewHomeowner,
        props: (route) => ({ userId: route.query.u }),
      },
      {
        path: "view/h/p",
        name: "RepProjectSettings",
        component: ProjectSettings,
        props: (route) => ({ userId: route.query.u, projectId: route.query.p }),
      },
      {
        path: "view/q",
        name: "RepViewQuotes",
        component: ViewQuote,
        props: (route) => ({ userId: route.query.u, quoteId: route.query.q }),
      },
    ],
  },
  {
    path: "/c",
    name: "Company",
    component: Company,
		props: (route) => ({ nav: route.query.nav }),
    children: [
      {
        path: "landing",
        name: "CompanyLanding",
        component: CompanyLanding,
      },
      {
        path: "filterUser",
        name: "CompanyFilterUser",
        component: FilterUser,
        props: (route) => ({ navFilter: route.query.nf }),
      },
      {
        path: "addRep",
        name: "CompanyAddRep",
        component: Signup,
      },
      {
        path: "view/c",
        name: "CompanyWallet",
        component: ViewCompany,
      },
      {
        path: "view/r",
        name: "CompanyViewRep",
        component: ViewRep,
        props: (route) => ({ userId: route.query.u }),
      },
      {
        path: "view/h",
        name: "CompanyViewHomeowner",
        component: ViewHomeowner,
        props: (route) => ({ userId: route.query.u }),
      },
      {
        path: "view/h/p",
        name: "CompanyProjectSettings",
        component: ProjectSettings,
        props: (route) => ({ userId: route.query.u, projectId: route.query.p }),
      },
      {
        path: "view/q",
        name: "CompanyViewQuotes",
        component: ViewQuote,
        props: (route) => ({ userId: route.query.u, quoteId: route.query.q }),
      },
    ],
  },
  {
    path: "/s",
    name: "Solar",
    component: Solar,
		props: (route) => ({ nav: route.query.nav }),
    children: [
      {
        path: "landing",
        name: "SolarLanding",
        component: SolarLanding,
      },
      {
        path: "filterUser",
        name: "SolarFilterUser",
        component: FilterUser,
        props: (route) => ({ navFilter: route.query.nf }),
      },
      {
        path: "newLead",
        name: "SolarNewLead",
        component: NewLead,
      },
      {
        path: "addRep",
        name: "SolarAddRep",
        component: Signup,
      },
      {
        path: "view/c",
        name: "SolarWallet",
        component: ViewCompany,
      },
      {
        path: "view/r",
        name: "SolarViewRep",
        component: ViewRep,
        props: (route) => ({ userId: route.query.u }),
      },
      {
        path: "view/h",
        name: "SolarViewHomeowner",
        component: ViewHomeowner,
        props: (route) => ({ userId: route.query.u }),
      },
      {
        path: "view/h/p",
        name: "SolarProjectSettings",
        component: ProjectSettings,
        props: (route) => ({ userId: route.query.u, projectId: route.query.p }),
      },
      {
        path: "view/q",
        name: "SolarViewQuotes",
        component: ViewQuote,
        props: (route) => ({ userId: route.query.u, quoteId: route.query.q }),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Avoid nav duplication errors
const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }
    return Promise.reject(err);
  });
};

export default router;

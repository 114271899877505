<template>
  <div>
    <div class="project-schedule">
      <div>
        <strong>{{ selectedProject.title }}</strong
        ><br />
        Scheduled Arrival:
        {{
          selectedProject.scheduledAt
            ? `${formatDate(selectedProject.scheduledAt)}`
            : "Pending"
        }}
      </div>
    </div>
    <div
      v-for="(stage, index) of stages"
      :key="'option-' + index"
      class="item-container"
      @click="setCompletion(index + 1)"
    >
      <div
        class="button stage"
        :class="[
          selectedProject.completion > index ? 'completed' : '',
          level === 'a' ? '' : 'disabled',
        ]"
      >
        {{ stage }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["selectedProject", "homeId"],
  methods: {
    formatDate(date) {
      date =
        date.charAt(date.length - 1) === "Z"
          ? new Date(date)
          : new Date(date + "Z");
      const time = date.getHours();
      return `${date.getMonth() + 1}-${date.getDate()} at ${
        time > 12 ? time - 12 : time
      }${time > 11 ? "pm" : "am"} to ${time > 10 ? time - 10 : time}${
        time > 9 ? "pm" : "am"
      }`;
    },
    async setCompletion(completion) {
      if (this.level !== "a") return;
      const project = this.selectedProject;
      project.completion = completion;
      await this.$http.put(`${this.$API}project/${project.projectId}/completion`, {
        homeId: this.homeId,
        completion: completion,
        context: project.tableName,
      });
    },
  },
  computed: {
    level() {
      return this.$store.getters.level;
    },
    stages() {
      return [
        this.selectedProject.stageOne,
        this.selectedProject.stageTwo,
        this.selectedProject.stageThree,
        this.selectedProject.stageFour,
        this.selectedProject.stageFive,
      ];
    },
  },
};
</script>

<style scoped>
.project-schedule {
  line-height: 1.3em;
  margin-bottom: 2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-container {
  display: flex;
  height: 6vh;
  align-content: center;
  margin: 1vh 0;
}
.completed {
  background: var(--blue-600);
}
.stage {
  width: 75%;
}
.disabled {
  cursor: default;
}
.disabled:hover {
  background: #ededed;
  color: #000;
}
.disabled.completed {
  background: var(--blue-600);
  color: #000;
}
</style>

<template>
  <div class="current-projects-container">
    <div class="header">
      <div>
        Current Projects:
      </div>
      <div>visit page >></div>
    </div>
    <div class="project-container">
      <div
        class="project"
        v-for="(project, index) of projectStages"
        :key="'stage-' + project"
				@click="showFilter(index)"
      >
        <div class="user-icon">{{ project }}</div>
        <div class="record-one">{{ numberOfProjects(index) }}</div>
        <div class="record-two">{{ totalBill(index) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectStages: ["DSS", "PER", "MO", "SC", "INV"],
    };
  },
  methods: {
		showFilter(stage) {
			const path = `${this.$route.matched[0].path}/filterUser?nf=${parseInt(stage) + 1}`;
			this.$router.push(path);
		},
    numberOfProjects(stage) {
      let count = 0;
      for (const customer of this.customers) {
        for (const project of customer.projectSnapShot) {
          if (project.completion === stage || (stage === 4 && project.completion === 5)) count++;
        }
      }
      return count;
    },
    totalBill(stage) {
      let bill = 0;
      for (const customer of this.customers) {
        for (const project of customer.projectSnapShot) {
          if (project.completion === stage) bill += project.bill;
        }
      }
      return this.formatBill(bill);
    },
		formatBill(bill) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(bill)
		},
  },
  computed: {
    customers() {
      return this.$store.getters.customers;
    },
  },
};
</script>

<style scoped>
.current-projects-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  font-size: 0.25rem;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vh;
  opacity: 0.5;
  font-weight: bold;
}
.project-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 1vw;
  width: calc(100% - 2vw);
  height: calc(100% - 13vh);
  background: #fff;
  border-radius: 25px;
  box-shadow: var(--box-shadow-light);
}
.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -2vh;
	cursor: pointer;
}
.user-icon {
  height: 10vh;
  width: 10vh;
  line-height: 10vh;
  font-size: 0.35rem;
  background: var(--blue-300);
  text-align: center;
  border-radius: 50%;
  color: #fff;
  margin-bottom: 2vh;
}
.record-one,
.footer-info-one,
.ballOne {
  font-weight: bold;
}
.record-two,
.footer-info-two,
.ballTwo {
  opacity: 0.5;
}
.footer-info-one,
.footer-info-two {
  display: flex;
  align-items: center;
  column-gap: 0.5vw;
}
.ballOne,
.ballTwo {
  height: 2vh;
  width: 2vh;
  background: #000;
  border-radius: 50%;
}
.footer {
  position: absolute;
  bottom: 10vh;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
</style>

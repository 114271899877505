<template>
  <div>
    <div class="left">
      <div class="photo-container">
        <img :src="item.photoUrl" alt="" class="image" />
      </div>
    </div>
    <div class="right">
      <div class="item-info">
        <div class="header">{{ item.header }}</div>
        <div>
          <div
            class="bullet-point"
            v-for="bullet of item.bulletItems"
            :key="bullet"
          >
            {{ bullet }}
          </div>
        </div>
        <div
          v-if="level !== 'u'"
          class="monthly-bubble-clickable"
          @click="displayCost = !displayCost"
        />
        <div class="monthly-bubble">
          <div :class="displayCost ? 'total-cost' : 'monthly-cost'">
            ${{
              !displayCost
                ? Math.round(item.item.bill / (loanYears * 12))
                : item.item.bill
            }}
          </div>
          <div v-if="!displayCost">per month</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "loanYears"],
  data() {
    return {
      displayCost: false,
    };
  },
  computed: {
    level() {
      return this.$store.getters.level;
    },
  },
};
</script>

<style scoped>
.left,
.right {
  max-width: 50%;
  width: 50%;
  position: relative;
}
.bullet-point {
  position: relative;
  font-size: 0.25rem;
  font-weight: bold;
  cursor: default;
  margin-bottom: 1vh;
}
.bullet-point::before {
  content: "";
  display: inline-block;
  height: 1vh;
  width: 1vh;
  background: var(--blue-300);
  margin-right: 0.5vw;
  border-radius: 50%;
  transform: translateY(-25%);
}
.bullet-point:hover::before {
  background: var(--blue-600);
}
.photo-container {
  overflow: hidden;
  width: fit-content;
  max-height: 40vh;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-right: auto;
}
.image {
  max-height: 40vh;
  max-width: 100%;
  object-fit: cover;
}
.item-info {
  position: relative;
  display: flex;
  max-height: 83vh;
  margin: -14vh 4vw 0 auto;
  width: 75%;
  flex-direction: column;
  justify-content: center;
}
.header {
  color: var(--blue-300);
  font-weight: bold;
  font-size: 0.35rem;
  margin-bottom: 2vh;
}
.monthly-bubble,
.monthly-bubble-clickable {
  position: absolute;
  bottom: -35vh;
  right: -6vw;
  height: 35vh;
  width: 35vh;
  background: var(--blue-300);
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.4rem;
}
.monthly-bubble-clickable {
  opacity: 0.01;
  z-index: 3;
}
.monthly-cost {
  font-size: 0.7rem;
  margin: -7vh 0 0;
}
.total-cost {
  font-size: .6rem;
  margin: -3vh 0 0;
}
</style>

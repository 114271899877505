<template>
  <div class="notification-container">
    <Bell class="bell" @click="toggleNotifications" />
    <div class="notifications" :class="open ? 'open' : ''">
      <div class="notification-header">Notifications</div>
      <div class="message-outer-container">
        <div
          class="message-container"
          v-for="(notification, index) of notifications"
          :key="'notification-' + index"
        >
          <img
            :src="unreadIcon"
            alt=""
            class="message-icon"
            :class="notification.unread ? 'unread' : ''"
          />
          <div
            class="notification-info"
            :class="notification.action === 'None' ? 'expand' : ''"
          >
            <div class="message">{{ notification.message }}</div>
            <div class="timestamp">
              {{ formatTime(notification.created_at) }}
            </div>
          </div>
          <div class="button-container" v-if="notification.action !== 'None'">
            <div
              class="button"
              @click="
                action(notification.action, notification.action_key, index)
              "
            >
              {{ notification.action }}
            </div>
            <div
              class="button"
              v-if="notification.action === 'Approve'"
              @click="confirmUser(notification.action_key, false, index)"
            >
              Deny
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bell-count" v-if="count > 0 && !open">{{ count }}</div>
  </div>
</template>

<script>
import Bell from "@/assets/svg/Notification.svg";
import unreadIcon from "@/assets/svg/Unread.svg?external";

export default {
  components: {
    Bell,
  },
  props: ["notifications", "openNav"],
  data() {
    return {
      open: false,
      unreadIcon: unreadIcon,
    };
  },
  methods: {
    formatTime(date) {
      date = new Date(date).toString();
      return `${date.substring(0, 4)}, ${date.substring(4, 7)} ${date.substring(
        8,
        10
      )}`;
    },
    action(action, key, index) {
      switch (action) {
        case "View":
          this.open = false;
          this.nav(key);
          break;
        case "Open":
          this.openDoc(key);
          break;
        case "Approve":
          this.confirmUser(key, true, index);
          break;
        default:
          break;
      }
    },
    nav(locationKey) {
      const notification = JSON.parse(locationKey);
      let route = this.$route.matched[0].path + "/view/";
      switch (notification.context) {
        case "user":
          route += "h";
          break;
        case "rep":
          route += "r";
          break;
        case "solar":
          route += "s";
          break;
        case "company":
          route += "c";
          break;
        default:
          break;
      }
      route += `?u=${notification.id}`;
      this.$router.push(route);
      this.$forceUpdate();
    },
    openDoc(doc) {
      window.open(`${this.$DOCUMENT_BUCKET}${doc}.html`, "_blank");
    },
    confirmUser(user, isApproved, index) {
      if (isApproved) {
        this.$store.dispatch("confirmUser", { user, index });
      } else {
        this.$store.dispatch("deleteUser", { user, index });
      }
    },
    toggleNotifications() {
      this.open = !this.open;
      if (this.count > 0 && this.open === false) {
        this.$store.commit("readAllNotifications");
        this.$socket.emit("readAllNotifications");
      }
    },
  },
  computed: {
    count() {
      let count = 0;
      for (const notification of this.notifications) {
        if (notification.unread) count++;
      }
      return count;
    },
  },
  watch: {
    openNav: {
      immediate: true,
      handler() {
        if (this.openNav === false) {
          this.open = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.notification-container {
  position: relative;
  min-width: 3vw;
  align-items: center;
}
.bell-count {
  position: absolute;
  top: 25%;
  right: 73%;
  border-radius: 50%;
  background: var(--error-color);
  color: var(--error-color);
  font-size: 0.15rem;
  overflow: hidden;
  width: 1vh;
  height: 1vh;
  padding: 0;
  transition: all 0.25s ease;
}
.bell {
  height: 5vh;
  transition: all 0.25s ease;
  cursor: pointer;
}
.bell:hover {
  fill: white;
}
.bell:hover ~ .bell-count {
  padding: 0.3vh 0.5vw;
  right: 68%;
  width: fit-content;
  height: initial;
  color: black;
  background: white;
  border-radius: 10px;
}
.notifications {
  height: 1vh;
  width: 0;
  position: absolute;
  top: 100%;
  right: -2vw;
  overflow: hidden;
  background: white;
  transition: height 0.35s ease 0s, width 0.25s linear 0.35s;
  z-index: 2;
}
.open {
  height: 79vh;
  width: calc(100% + 20vw);
  transition: height 0.35s ease 0.25s, width 0.25s linear 0s;
  border-bottom-left-radius: 25px;
}
.notification-header {
  font-size: 0.2rem;
  opacity: 0.6;
  padding-bottom: 1vh;
  border-bottom: 2px solid #00000070;
  margin: 2vh;
}
.message-outer-container {
  overflow: scroll;
  height: 73vh;
}
.message-container {
  font-size: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: auto;
  margin: 3vh 1vh;
}
.message-icon {
  min-width: 1.5vw;
  min-height: 1.5vw;
  max-height: 1.5vw;
  max-width: 1.5vw;
  margin: auto 0.5vw;
  border-radius: 50%;
  background: var(--blue-300);
}
.unread {
  background: var(--error-color);
}
.notification-info {
  display: flex;
  flex-direction: column;
  max-width: 11vw;
  justify-content: center;
}
.expand {
  max-width: 17.5vw;
  padding-right: 0.5vw;
}
.timestamp {
  font-size: 0.1rem;
  opacity: 0.5;
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 25%;
}
.button {
  width: 100%;
}
</style>

<template>
  <div class="navbar-container">
    <div
      v-for="page of pages"
      :key="page.display"
      class="nav-item"
      @click="nav(page)"
    >
      {{ page.display }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pages: [
        {
          display: "Homeowner Login",
          url: "holn",
        },
        {
          display: "Partner Login",
          url: "rlgn",
        },
      ],
    };
  },
  methods: {
    nav(page) {
      this.$router.push(page.url);
    },
  },
};
</script>

<style scoped>
.navbar-container {
  position: absolute;
  z-index: 2;
  top: 0;
  height: 1.125rem;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
}
.nav-item {
  line-height: 0.875rem;
  font-size: 0.325rem;
  margin: 0.125rem 0.4rem;
  height: 0.65rem;
  color: white;
}
.nav-item:last-child {
  margin-right: 1rem;
}
.nav-item:hover {
  border-bottom: 2px solid var(--primary-yellow);
  border-width: 50%;
  cursor: pointer;
}
@media screen and (max-width: 450px) {
	.navbar-container {
		flex-direction: column;
		top: 34vh;
		row-gap: 4vh;
	}
	.nav-item {
		padding: 2vh 5vw;
		margin: auto;
		border: 3px solid #fff;
		width: 40vw;
		text-align: center;
		border-radius: 15px;
		height: initial;
		line-height: initial;
	}
	.nav-item:last-child {
		margin: auto;
	}
}
</style>

<template>
  <div class="landing-container">
    <SearchBar
      :homeowners="customers"
      class="search-bar"
    />
    <AnalyticSnapshot
      class="analytics"
      :chartData="chartData"
      context="customers"
    />
    <CurrentProjects
      class="current-projects"
      :snapshot="snapshot"
      context="customers"
      infoOne="Projects in Stage"
      infoTwo="Revenue from Projects"
    />
    <Map class="map" :homeowners="customers" />
  </div>
</template>

<script>
import SearchBar from "@/components/Common/SearchUsers.vue";
import AnalyticSnapshot from "@/components/Common/AnalyticSnapshot.vue";
import CurrentProjects from "@/components/Common/CurrentProjects.vue";
import Map from "@/components/Common/Map.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SearchBar,
    AnalyticSnapshot,
    CurrentProjects,
    Map,
  },
  data() {
    return {
      stages: ["DSS", "PER", "MO", "SC", "INV"],
    };
  },
  methods: {
    selectUser(payload) {
      this.$emit("customerPage", payload);
    },
  },
  computed: {
    ...mapGetters(["customers", "userConfig"]),
    chartData() {
      const snapshot = [];
      const categories = [
        "Generac",
        "HVAC",
        "Pool Pumps",
        "Hot Water Heater",
        "Roofing",
        "Insulation",
        "Tree Trimming",
      ];
      for (const customer of this.customers) {
        let repIndex = snapshot
          .map((obj) => {
            return obj.repId;
          })
          .indexOf(customer.repId);
        if (repIndex === -1) {
          if (customer.repId === 0) {
            snapshot.push({
              repId: customer.repId,
              name: "Admin User",
              data: new Array(7).fill(0),
            });
          } else {
            snapshot.push({
              repId: customer.repId,
              name: `${customer.repFName} ${customer.repLName}`,
              data: new Array(7).fill(0),
            });
          }
          repIndex = snapshot.length - 1;
        }
        for (const project of customer.projectSnapShot) {
          switch (project.projectName) {
            case "generac":
              snapshot[repIndex].data[0]++;
              break;
            case "hvac":
              snapshot[repIndex].data[1]++;
              break;
            case "pool_pumps":
              snapshot[repIndex].data[2]++;
              break;
            case "hot_water_heater":
              snapshot[repIndex].data[3]++;
              break;
            case "roofing":
              snapshot[repIndex].data[4]++;
              break;
            case "insulation":
              snapshot[repIndex].data[5]++;
              break;
            case "tree_trimming":
              snapshot[repIndex].data[6]++;
              break;
            default:
              break;
          }
        }
      }
      return {
        categories: categories,
        series: snapshot,
      };
    },
    snapshot() {
      let snapshot = [];
      let index = 0;
      for (const stage of this.stages) {
        let snap = {
          analyticOne: 0,
          analyticTwo: 0,
          abbreviation: stage,
        };
        let countOne = 0;
        let countTwo = 0;
        for (const customer of this.customers) {
          for (const project of customer.projectSnapShot) {
            if (project.completion == index) {
              countOne++;
              countTwo += project.bill;
            }
          }
        }
        snap.analyticOne = countOne;
        snap.analyticTwo = countTwo;
        snapshot.push(snap);
        index++;
      }
      snapshot = snapshot.sort((a, b) => b.analyticOne - a.analyticOne);
      return snapshot;
    },
  },
};
</script>

<style scoped>
.landing-container {
  width: 100%;
  height: 91vh;
  background: #ededed;
  display: grid;
  grid-template-areas:
    "search analytics maps"
    "search projects maps";
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 3fr 4fr 3fr;
  column-gap: 2vw;
}
.search-bar {
  grid-area: search;
}
.analytics {
  grid-area: analytics;
}
.current-projects {
  grid-area: projects;
}
.map {
  grid-area: maps;
  height: 90%;
  width: 90%;
  margin: 5%;
}
</style>

<template>
    <div class="empty">This Project only contains photo information, please see the image section below to modify any portion of this project.</div>
</template>

<script>
export default {
    computed: {
        payload() {
            return {};
        }
    }
}
</script>

<style scoped>
.empty {
    width: 80%;
    text-align: center;
    margin: auto;
    padding: 2vh 0;
}
</style>
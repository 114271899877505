<template>
  <div class="signup-container" :class="/[asc]/.test(level) ? 'header-add' : ''">
    <div class="loading" v-if="loading">
      <Logo class="loading-icon" />
    </div>
    <div class="header" v-if="!/[asc]/.test(level)">
      <Logo class="logo" @click="$router.push('/')" />
    </div>
    <div class="card-container">
      <!-- Page 1 -->
      <div class="page-container" v-if="mainPage">
        Contact info

        <div class="contact-container">
          <input
            title="First Name"
            type="text"
            placeholder="First Name"
            class="input a"
            v-model="firstName"
            @change="removeError(0)"
            required
          />
          <input
            title="Last Name"
            type="text"
            placeholder="Last Name"
            class="input b"
            v-model="lastName"
            @change="removeError(1)"
            required
          />
          <input
            title="Email ex: something@somewhere.com"
            type="email"
            placeholder="Email"
            class="input c"
            @change="removeError(2)"
            v-model="email"
            required
          />
          <input
            title="Input numbers only"
            type="tel"
            placeholder="Phone Number"
            class="input d"
            v-model="phoneNumber"
            @change="removeError(3)"
            @input="phoneNumberFormatter()"
            required
          />
        </div>

        Shipping Address
        <div class="address-container">
          <input
            title="Home number and Street name"
            type="text"
            placeholder="Street Address"
            class="input a"
            v-model="address"
            @change="removeError(4)"
            required
          />
          <input
            title="City"
            type="text"
            placeholder="City"
            class="input b"
            v-model="city"
            @change="removeError(5)"
            required
          />
          <select
            class="input select c"
            v-model="state"
            @change="removeError(6)"
            required
          >
            <option v-for="state of states" :key="state">{{ state }}</option>
          </select>
          <input
            title="Zip code"
            type="text"
            placeholder="Zip"
            class="input d"
            v-model="zip"
            @change="removeError(7)"
            required
            pattern="[0-9]{5}"
          />
        </div>

        <div class="select-solar" v-if="!/[cs]/.test(level)">
          Onboarding as a
        </div>
        <div class="button-container" v-if="!/[cs]/.test(level)">
          <div
            class="button"
            @click="chooseType('Company', false)"
            :class="onboarding === 'Company' && !isSolar ? 'active' : ''"
          >
            Company
          </div>
          <div
            class="button"
            @click="chooseType('Representative', false)"
            :class="onboarding === 'Representative' ? 'active' : ''"
          >
            Representative
          </div>

          <div
            class="button"
            @click="chooseType('Company', true)"
            :class="isSolar ? 'active' : ''"
          >
            Solar
          </div>

          <div class="items check-item">
            <div
              ref="accept-terms"
              :class="acceptTerms === true ? 'active' : ''"
              @click="acceptTerms = !acceptTerms"
              class="check"
            />
            <div class="check-text">
              <a :href="$EMAIL_BUCKET + 'Terms_Of_Use.pdf'" target="_blank"
                >Accept Terms of Use</a
              >
            </div>
          </div>
          <div class="items check-item">
            <div
              ref="allow-hoa"
              :class="acceptPrivacy === true ? 'active' : ''"
              @click="acceptPrivacy = !acceptPrivacy"
              class="check"
            />
            <div class="check-text">
              <a
                :href="$EMAIL_BUCKET + 'OnboardingAgreements.pdf'"
                target="_blank"
                >Accept Onboarding Agreements</a
              >
            </div>
          </div>
        </div>

        <div class="button-container" v-else>
          <div class="items check-item">
            <div
              ref="accept-terms"
              :class="acceptTerms === true ? 'active' : ''"
              @click="acceptTerms = !acceptTerms"
              class="check"
            />
            <div class="check-text">
              <a :href="$EMAIL_BUCKET + 'Terms_Of_Use.pdf'" target="_blank"
                >Accept Terms of Use</a
              >
            </div>
          </div>
          <div class="items check-item">
            <div
              ref="allow-hoa"
              :class="acceptPrivacy === true ? 'active' : ''"
              @click="acceptPrivacy = !acceptPrivacy"
              class="check"
            />
            <div class="check-text">
              <a
                :href="$EMAIL_BUCKET + 'OnboardingAgreements.pdf'"
                target="_blank"
                >Accept Onboarding Agreements</a
              >
            </div>
          </div>
        </div>

        <div class="footer-container">
          <div class="error-msg">{{ error }}</div>
          <div class="submission-container">
            <div class="button submit" @click="back" v-if="!/[cs]/.test(level)">
              Back
            </div>
            <div class="button submit" @click="next">Next</div>
          </div>
        </div>
      </div>
      <!-- End Page 1 -->

      <!-- Page 2 -->
      <div class="page-container" v-else>
        <component
          :is="components[componentNumber]"
          @submit="openPopup"
          @back="mainPage = true"
        />
      </div>
      <!-- End Page 2 -->

      <!-- PopUps -->
      <div class="show-confirmation" v-if="showConfirmationSubmit">
        <div class="confirmation-title">
          {{
            sentEmail
              ? `Registration confirmed, an email will be sent once your account is approved.`
              : "Is the information correct?"
          }}
        </div>
        <div class="confirmation-button-container">
          <div
            class="button submit"
            @click="showConfirmationSubmit = false"
            v-if="!sentEmail"
          >
            No
          </div>
          <div
            class="button submit"
            @click="sentEmail ? close() : submit(payload)"
          >
            {{ sentEmail ? "Ok" : "Yes" }}
          </div>
        </div>
      </div>
      <!-- End Popups -->
    </div>
  </div>
</template>

<script>
import RepSignup from "@/components/Signup/RepSignup.vue";
import CompanySignup from "@/components/Signup/CompanySignup.vue";
import { States } from "@/assets/const.js";
import Logo from "@/assets/svg/Logo.svg";

export default {
  components: {
    Logo,
    RepSignup,
    CompanySignup,
  },
  data() {
    return {
      // basic info for both;
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      address: "",
      city: "",
      state: "AL",
      zip: "",
      isSolar: false,

      /* Remaining information from company/rep section detailed in '@/components/Signup */
      payload: {},
      /* ----- */

      onboarding: "Representative",
      showConfirmationSubmit: false,
      error: "",
      sentEmail: false,
      loading: false,
      hasAccount: false,
      acceptTerms: false,
      acceptPrivacy: false,

      states: States,

      // toggle between basic info and company/rep info
      mainPage: true,
      componentNumber: 0,
      components: [RepSignup, CompanySignup],
    };
  },
  computed: {
    level() {
      return this.$store.getters.level;
    },
		context() {
			if (this.onboarding === "Representative") return "rep";
			return "company";
		},
    submission() {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber.replace(/-|\(|\)|\s/g, ""),
        address: this.address,
        city: this.city,
        state: this.state,
        zip: this.zip,
        isSolar: this.isSolar,
        ...this.payload,
      };
    },
  },
  methods: {
    chooseType(onboarding, isSolar) {
      this.isSolar = isSolar;
      this.onboarding = onboarding;
    },
    // Field check methods
    checkFilled() {
      const inputFields = document.getElementsByClassName("input");
      let isFilled = true;
      for (const input of inputFields) {
        if (!input.checkValidity()) {
          input.classList.add("invalid-input");
          isFilled = false;
        }
      }
      if (!this.acceptTerms || !this.acceptPrivacy) isFilled = false;
      return isFilled;
    },
    clearAll() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phoneNumber = "";
      this.address = "";
      this.city = "";
      this.state = "";
      this.zip = "";
    },
    removeError(number) {
      const field = document.getElementsByClassName("input")[number];
      if (field.classList.contains("invalid-input"))
        field.classList.remove("invalid-input");
    },
    checkEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    phoneNumberFormatter() {
      this.phoneNumber = this.formatPhoneNumber(this.phoneNumber);
    },
    formatPhoneNumber(value) {
      if (!value) return value;
      const phoneNumber = value.replace(/[^\d]/g, "");
      const phoneNumberLength = phoneNumber.length;
      if (phoneNumberLength < 4) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )} - ${phoneNumber.slice(6, 10)}`;
    },

    // Table creations and assets for tables
    makePassword(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    async handlePhotoUpload(username) {
      let formData = new FormData();
      const file = this.payload["userPhoto"];
      const name = `${username}userPhoto`;
      const index = file.name.lastIndexOf(".");
      formData.append("image", file, `${name}${file.name.substr(index)}`);
      await this.$http.put(`${this.$API}image/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    async submit() {
      this.loading = true;
      this.showConfirmationSubmit = false;
      try {
        const res = await this.$http.post(`${this.$API}${this.context}`, {
          submission: this.submission,
        });
        this.error =
          res.status === 200
            ? "Account Creation Successful"
            : "Email Already in Use";
        if (this.payload.userPhoto && res.status === 200) {
          await this.handlePhotoUpload(res.data.username);
        }
        if (this.level === "a") this.$emit("clearAdmin");
        this.loading = false;
      } catch {
        this.error = "Error in uploading please try again";
        this.loading = false;
      } finally {
        this.mainPage = true;
      }
    },

    // Popup and page movement
    openPopup(payload) {
      this.payload = payload;
      this.showConfirmationSubmit = true;
    },
    close() {
      this.showConfirmationSubmit = false;
      this.sentEmail = false;
      this.clearAll();
    },
    next() {
      if (!this.checkFilled()) {
        this.error = "Please fill in all fields, and accept terms.";
        return;
      } else if (!this.checkEmail(this.email)) {
        this.error = "Please enter a valid email.";
        return;
      }
      this.componentNumber = this.onboarding === "Representative" ? 0 : 1;
      this.mainPage = false;
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.signup-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.25rem;
  overflow: hidden;
  background: #ededed;
}
.header-add {
	height: 91vh;
}
.header {
  width: 96vw;
  padding: 0 2vw;
  height: 10vh;
  background: var(--blue-300);
  display: flex;
}

.logo {
  height: 5vh;
  width: auto;
  margin: auto;
  transition: 0.5s ease all;
}
.logo:hover {
  cursor: pointer;
  fill: #fff;
}

.card-container {
  width: 70vw;
  height: 70vh;
  margin: auto;
  font-size: 0.3rem;
  background: #fff;
  box-shadow: var(--box-shadow);
  border-radius: 25px;
}
.select-solar {
  display: flex;
  align-items: center;
}
.page-container {
  margin: 4vh 3vw;
  display: flex;
  flex-direction: column;
  height: calc(100% - 8vh);
}
.contact-container,
.address-container {
  margin: 2vh 0;
  display: grid;
  column-gap: 1.5vw;
  row-gap: 2vh;
  height: fit-content;
  width: 100%;
}
.contact-container {
  grid-template-areas:
    "a b"
    "c d";
}
.address-container {
  grid-template-areas:
    "a a a a a a"
    "b b b c d d";
  margin-bottom: 4vh;
}
.a {
  grid-area: a;
}
.b {
  grid-area: b;
}
.c {
  grid-area: c;
}
.d {
  grid-area: d;
}
.input {
  width: calc(100% - 1.5vw);
}
.input:focus-visible {
  outline-style: ridge;
  outline-width: 1px;
  outline-color: var(--blue-300);
}
.select {
  height: 100%;
  width: 100%;
}
.button-container {
  display: flex;
  column-gap: 2vw;
  margin-top: 4vh;
  align-items: center;
}
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}
.items {
  height: 6vh;
  width: 100%;
  display: flex;
  align-items: center;
}
.check-item {
  width: fit-content;
  margin-left: 1vw;
}
.check-item:first-child {
  margin-left: 1vw;
}
.check {
  min-height: 4vh;
  min-width: 4vh;
  background: #ededed;
  cursor: pointer;
  margin-right: 1vw;
}
.check-text {
  font-size: 0.2rem;
}
.footer-container .items:first-child {
  margin-left: 3vw;
  width: calc(25% - 3vw);
}
.submission-container {
  width: 36%;
  display: flex;
  justify-content: space-between;
  column-gap: 2vw;
}
.button {
  width: fit-content;
  padding: 0 1vw;
}
.submit {
  width: 48%;
}
.show-confirmation {
  position: absolute;
  width: 30vw;
  height: 30vh;
  left: calc(50% - 15vw);
  top: calc(50% - 15vh);
  font-size: 0.3rem;
  background: #fff;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  border-radius: 25px;
}
.confirmation-title {
  font-size: 0.25rem;
  margin: auto;
  text-align: center;
  width: 80%;
}
.confirmation-button-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5vh;
}
</style>

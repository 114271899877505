<template>
  <div class="choose-email-container">
    <div class="header">
      {{
        emailSelection
          ? "Select a project or login information"
          : "Select an email template to send out below"
      }}
    </div>
    <div class="selection-container">
      <div
        class="button"
        :class="emailSelection === 'project' ? 'active' : ''"
        @click="selectEmail('project')"
      >
        New Subscriber
      </div>
      <div
        class="button"
        :class="emailSelection === 'login' ? 'active' : ''"
        @click="selectEmail('login')"
      >
        Login Information
      </div>
    </div>
    <div class="select-secondary-container" v-if="emailSelection === 'project'">
      <select class="input" v-model="user">
        <option v-for="user of customers" :value="user" :key="user.id">
          {{ formatName(user.fName, user.lName) }}
        </option>
        <option value="" selected>None</option>
      </select>
      <select class="input" v-model="project" v-if="user">
        <option
          v-for="project of projects"
          :key="project.title + ' ' + project.projectId"
          :value="project"
          >{{ project.title }}</option
        >
        <option value="" selected>None</option>
      </select>
    </div>
    <div class="select-secondary-container" v-if="emailSelection === 'login'">
      <select class="input" v-model="userClass">
        <option value="customers">Homeowners</option>
        <option value="reps">Reps</option>
        <option value="companies">Companies</option>
        <option value="" selected>Unknown</option>
      </select>
      <select class="input" v-model="user" v-if="userClass">
        <option v-for="user of userList" :key="user.id" :value="user.id">{{
          userClass === "companies"
            ? user.companyName
            : formatName(user.fName, user.lName)
        }}</option>
        <option value="" selected>None</option>
      </select>
    </div>
    <div class="footer">
      <div class="error-msg" v-if="errorMessage">{{ errorMessage }}</div>
      <div class="button" @click="submit">
        {{ emailSelection === "login" ? "Submit" : "Next" }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // Type of email to send out
      emailSelection: "",

      // get project info
      project: "",
      projects: [],
      tables: [
        "generac",
        "roofing",
        "tree_trimming",
        "pool_pumps",
        "insulation",
        "hot_water_heater",
        "hvac",
      ],

      // User information
      user: "",
      userClass: "",
      userList: "",
      errorMessage: "",
      context: ["user", "rep", "company"],
    };
  },
  computed: {
    ...mapGetters(["reps", "companies", "customers"]),
  },
  methods: {
    formatName(first, last) {
      return `${first.charAt(0).toUpperCase()}${first.substring(
        1
      )} ${last.charAt(0).toUpperCase()}${last.substring(1)}`;
    },
    selectEmail(email) {
      this.user = "";
      this.emailSelection = email;
    },
    submit() {
      if (!this.emailSelection) {
        this.errorMessage =
          "Please select an email template using the buttons above";
      } else {
        if (this.emailSelection === "project") this.handleProject();
        else if (this.emailSelection === "login") this.handleLogin();
      }
    },
    handleProject() {
      if (!this.project || !this.user)
        this.errorMessage = "Please select a project from above.";
      else {
        const payload = { emailSelection: this.emailSelection };
        const stages = ["One", "Two", "Three", "Four", "Five"];
        const projectStatus = this.project[
          `stage${stages[this.project.completion]}`
        ];
        payload.projectType = this.project.title;
        payload.projectId = this.project.projectId;
        payload.projectStatus = projectStatus;
        payload.projectTable = this.project.tableName;
        this.$emit("next", payload);
      }
    },
    handleLogin() {
      if (!this.user)
        this.errorMessage = "Please select a user to send info to.";
      else {
        const payload = { emailSelection: this.emailSelection };
        let context;
        switch (this.userClass) {
          case "customers":
            context = "user";
            break;
          case "reps":
            context = "rep";
            break;
          case "companies":
            context = "company";
            break;
          default:
            break;
        }
        payload.context = context;
        payload.id = this.user;
        this.$emit("confirmationMessage", "Login Information Sent!");
        this.$emit("submit", payload);
        this.emailSelection = "";
      }
    },
    async getUserProjects() {
      // Project Info
      if (!this.user.projects) {
        const projects = await this.$http.get(`${this.$API}user/${this.user.id}/projects`);
        if (projects.data) {
          this.projects = projects.data;
          for (const project of this.projects) {
            const payload = { project: project, userId: this.user.id };
            this.$store.commit("setUserProjects", payload);
          }
        }
      } else {
        this.projects = this.user.projects;
      }
    },
  },
  watch: {
    userClass: {
      immediate: true,
      handler() {
        this.user = "";
        this.userList = this[this.userClass];
      },
    },
    user: {
      immediate: true,
      handler() {
        if (this.emailSelection === "project") this.getUserProjects();
        this.errorMessage = "";
      },
    },
  },
};
</script>

<style scoped>
.choose-email-container {
  font-size: 0.25rem;
}
.header {
  width: 100%;
  text-align: center;
  margin-top: 2vh;
}
.selection-container {
  display: flex;
  column-gap: 2vw;
  margin: 5vh auto;
}
.button {
  min-width: 8vw;
  width: fit-content;
  padding: 0 3vw;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: auto 0 2vh;
  column-gap: 3vw;
}
.error-msg {
  color: var(--error-color);
  margin: auto;
  font-size: 0.3rem;
}
.select-secondary-container {
  margin: 2vh auto;
  width: 35vw;
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
  align-items: center;
}
.input {
  font-size: 0.2rem;
}
</style>

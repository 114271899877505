<template>
  <div class="roofing-container">
    <div class="item-container a">
      <div class="head">How old is the current roof?</div>
      <input type="number" class="input" v-model="ageOfRoof" />
    </div>
    <div class="item-container b">
      <div class="head">Please select the current type of roof</div>
      <select v-model="typeOfRoof" class="input select">
        <option
          v-for="roofType of roofTypeOptions"
          :key="roofType"
          :value="roofType"
          >{{ roofType }}</option
        >
        <option value="Unknown" selected>Unknown</option>
      </select>
    </div>
    <div class="item-container c">
      <div class="head">Check the requested replacements</div>
      <div class="button-container">
        <div
          class="button"
          :class="replaceGutter ? 'active' : ''"
          @click="toggle('Gutter')"
        >
          Gutter
        </div>
        <div
          class="button"
          :class="replaceSoffit ? 'active' : ''"
          @click="toggle('Soffit')"
        >
          Soffit
        </div>
        <div
          class="button"
          :class="!replaceGutter && !replaceSoffit ? 'active' : ''"
          @click="toggle('neither')"
        >
          Neither
        </div>
      </div>
    </div>
    <div class="item-container d">
      <div class="head">Color selection</div>
      <div class="button-container">
        <div
          class="button"
          :class="showShingles ? 'active' : ''"
          @click="showColors('shingles')"
        >
          Shingles
        </div>
        <div
          class="button"
          :class="showEdges ? 'active' : ''"
          @click="showColors('edges')"
        >
          Edges
        </div>
      </div>
    </div>
    <div class="selection-pop-out e" v-if="showEdges || showShingles">
      <div class="edge-shingle-colors" v-if="showEdges">
        <div
          class="edge-shingle"
          v-for="(edge, index) of edgeColors"
          :key="'edge-' + index"
        >
          <img
            :src="edge.img"
            class="edge-shingle-selection"
            alt=""
            :class="edge.name === edgeChoice ? 'choice-one-color' : ''"
            @click="edgeChoice = edge.name"
          />
          {{ edge.name }}
        </div>
      </div>
      <div
        class="edge-shingle-colors"
        v-if="showShingles"
        @wheel="handleScroll($event, 'shingleScroll')"
        ref="shingleScroll"
      >
        <div
          class="edge-shingle"
          v-for="(shingle, index) of shingleColors"
          :key="'edge-' + index"
        >
          <img
            :src="shingle.img"
            class="edge-shingle-selection"
            alt=""
            :class="
              shingle.name === shingleChoiceOne
                ? 'choice-one-color'
                : shingle.name === shingleChoiceTwo
                ? 'choice-two-color'
                : ''
            "
          />
          <div class="shingle-text">
            <div class="shingle-text-choice">
              {{ shingle.name }}
            </div>
            <div class="choice-blocks">
              <div
                class="choice-one"
                :class="
                  shingleChoiceOne === shingle.name ? 'choice-one-color-text' : ''
                "
                @click="toggleShingleColor(1, shingle.name)"
              >
                1
              </div>
              <div
                class="choice-two"
                :class="
                  shingleChoiceTwo === shingle.name ? 'choice-two-color-text' : ''
                "
                @click="toggleShingleColor(2, shingle.name)"
              >
                2
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["project"],
  data() {
    return {
      ageOfRoof: null,
      typeOfRoof: "Unknown",
      shingleChoiceOne: "",
      shingleChoiceTwo: "",
      edgeChoice: "",
      replaceGutter: false,
      replaceSoffit: false,
      replaceFascia: false,
      roofTypeOptions: [
        "Architectural Shingle",
        "Standing Seam Metal",
        "Tile",
        "Three Tab Shingle",
        "Exposed Fastener Metal",
        "Rolled Roofing",
        "Mixed Roofing",
        "Gravel",
      ],
      showEdges: false,
      showShingles: false,
      shingleColors: [
        {
          name: "Black Walnut",
          img: require("../../../assets/images/ShingleColors/BlackWalnut.png"),
        },
        {
          name: "Desert Sand",
          img: require("../../../assets/images/ShingleColors/DesertSand.png"),
        },
        {
          name: "Glacier White",
          img: require("../../../assets/images/ShingleColors/GlacierWhite.png"),
        },
        {
          name: "Natural Timber",
          img: require("../../../assets/images/ShingleColors/NaturalTimber.png"),
        },
        {
          name: "Olde English Pewter",
          img: require("../../../assets/images/ShingleColors/OldeEnglishPewter.png"),
        },
        {
          name: "Oxford Grey",
          img: require("../../../assets/images/ShingleColors/OxfordGrey.png"),
        },
        {
          name: "Painted Desert",
          img: require("../../../assets/images/ShingleColors/PaintedDesert.png"),
        },
        {
          name: "Rustic Black",
          img: require("../../../assets/images/ShingleColors/RusticBlack.png"),
        },
        {
          name: "Rustic Cedar",
          img: require("../../../assets/images/ShingleColors/RusticCedar.png"),
        },
        {
          name: "Rustic Slate",
          img: require("../../../assets/images/ShingleColors/RusticSlate.png"),
        },
        {
          name: "Shadow Grey",
          img: require("../../../assets/images/ShingleColors/ShadowGrey.png"),
        },
        {
          name: "Thunderstorm Grey",
          img: require("../../../assets/images/ShingleColors/ThunderstormGrey.png"),
        },
        {
          name: "Weathered Wood",
          img: require("../../../assets/images/ShingleColors/WeatheredWood.png"),
        },
      ],
      edgeColors: [
        {
          name: "Brown",
          img: require("../../../assets/images/TrimColors/BrownTrim.png"),
        },
        {
          name: "Black",
          img: require("../../../assets/images/TrimColors/BlackTrim.png"),
        },
        {
          name: "White",
          img: require("../../../assets/images/TrimColors/WhiteTrim.png"),
        },
      ],
    };
  },
  computed: {
    payload() {
      return {
        ageOfRoof: this.ageOfRoof,
        typeOfRoof: this.typeOfRoof,
        replaceGutter: this.replaceGutter,
        replaceSoffit: this.replaceSoffit,
        replaceFascia: this.replaceFascia,
        shingleColor: `{one: ${this.shingleChoiceOne}, two: ${this.shingleChoiceTwo}}`,
        edgeColor: this.edgeChoice,
      };
    },
  },
  methods: {
    toggle(selection) {
      if (selection === "none") {
        this.replaceGutter = false;
        this.replaceSoffit = false;
        this.replaceFascia = false;
      } else {
        this[`replace${selection}`] = !this[`replace${selection}`];
      }
    },
    toggleShingleColor(number, name) {
      if (number === 1) {
        if (name === this.shingleChoiceOne) {
          this.shingleChoiceOne = "";
        } else {
          if (name === this.shingleChoiceTwo) this.shingleChoiceTwo = "";
          this.shingleChoiceOne = name;
        }
      } else {
        if (name === this.shingleChoiceTwo) {
          this.shingleChoiceTwo = "";
        } else {
          if (name === this.shingleChoiceOne) this.shingleChoiceOne = "";
          this.shingleChoiceTwo = name;
        }
      }
    },
    showColors(selection) {
      if (selection === "shingles") {
        this.showEdges = false;
        this.showShingles = !this.showShingles;
      } else {
        this.showShingles = false;
        this.showEdges = !this.showEdges;
      }
    },
    handleScroll(e, selection) {
      e.preventDefault();
      this.$refs[selection].scrollLeft += e.deltaY;
    },
  },
  mounted() {
    this.ageOfRoof = this.project.ageOfRoof ? this.project.ageOfRoof : null;
    this.typeOfRoof = this.project.typeOfRoof
      ? this.project.typeOfRoof
      : "Unknown";
    this.edgeChoice = this.project.edgeChoice ? this.project.edgeChoice : "";
    if (this.project.shingleColor) {
      const oneIndex = this.project.shingleColor.indexOf("one:") + 5;
      const twoIndex = this.project.shingleColor.indexOf("two:") - 2;
      const threeIndex = this.project.shingleColor.length - 1;
      this.shingleChoiceOne = this.project.shingleColor.substring(
        oneIndex,
        twoIndex
      );
      this.shingleChoiceTwo = this.project.shingleColor.substring(
        twoIndex + 7,
        threeIndex
      );
    }
    this.replaceSoffit = this.project.replaceSoffit
      ? this.project.replaceSoffit
      : false;
    this.replaceGutter = this.project.replaceGutter
      ? this.project.replaceGutter
      : false;
    this.replaceFascia = this.project.replaceFascia
      ? this.project.replaceFascia
      : false;
  },
};
</script>

<style scoped>
.roofing-container {
  display: grid;
  width: 100%;
  grid-template-areas:
    "a b"
    "c d"
    "e e";
  justify-items: center;
  row-gap: 4vh;
}
.item-container {
  width: 30vw;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.head {
  margin-bottom: 2vh;
}
.button-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.edge-shingle-colors {
  display: flex;
  column-gap: 4vw;
  overflow: auto;
  padding: 1vh 0;
}
.edge-shingle {
  display: flex;
  align-items: center;
  column-gap: 1vw;
  padding: 1vh 0;
}
.edge-shingle-selection {
  height: 9vh;
  width: 9vh;
  object-fit: cover;
  cursor: pointer;
}
.selection-pop-out {
  display: flex;
  justify-content: center;
  width: 71vw;
}
.a {
  grid-area: a;
}
.b {
  grid-area: b;
}
.c {
  grid-area: c;
}
.d {
  grid-area: d;
}
.e {
  grid-area: e;
}

.shingle-text {
  display: flex;
  flex-direction: column;
  margin: 1vh 0;
  width: 62%;
  font-size: 0.18rem;
  justify-content: space-evenly;
}
.choice-one,
.choice-two {
  padding: 1vh 1vw;
  background: #ededed;
  color: #000;
  cursor: pointer;
}
.choice-one:hover {
  background: var(--blue-300);
}
.choice-two:hover {
  background: var(--primary-yellow);
  color: #000;
}
.choice-one-color-text {
  background: var(--blue-600);
}
.choice-two-color-text {
  background: var(--secondary-orange);
}

.choice-one-color,
.choice-two-color {
  height: calc(9vh - 8px);
  width: calc(9vh - 8px);
  border: 4px solid var(--blue-600);
}
.choice-two-color {
  border: 4px solid var(--secondary-orange);
}
.shingle-text-choice {
  text-align: center;
}
.choice-blocks {
  display: flex;
}

</style>

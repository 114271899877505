import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import * as VueGoogleMaps from "vue2-google-maps";
import { io } from "socket.io-client";
import VueSocketIO from "vue-socket.io";
import HighchartsVue from "highcharts-vue";
import VueCookies from "vue-cookies";

// Global Components
import "@/assets/css/main.css";

Vue.use(VueCookies, { expire: "7d" });

axios.interceptors.request.use(function(config) {
	if (/^https:\/\/maps.googleapis.com\/map/.test(config.url)) return config;
  const token = VueCookies.get("access");
  if (token && /^((?!login).)*$/.test(config.url))
    config.headers.Authorization = `Bearer ${token}`;
  return config;
});

Vue.use(VueAxios, axios);
Vue.use(HighchartsVue);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API,
  },
});

export const SocketInstance = io(process.env.VUE_APP_SOCKET_INSTANCE);

Vue.use(
  new VueSocketIO({
    connection: SocketInstance,
    vuex: {
      store,
      actionPrefix: "socket_function_",
      mutationPrefix: "socket_function_",
    },
  })
);

Vue.prototype.$https = axios;
Vue.prototype.$API = process.env.VUE_APP_API;
Vue.prototype.$BUCKET = process.env.VUE_APP_BUCKET;
Vue.prototype.$EMAIL_BUCKET = process.env.VUE_APP_EMAIL_BUCKET;
Vue.prototype.$DOCUMENT_BUCKET = process.env.VUE_APP_DOCUMENT_BUCKET;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="send-email-container">
    <div class="loading" v-if="loading"><Icon class="loading-icon" /></div>
    <component
      class="card"
      :is="pages[currentPage]"
      @next="next"
      @back="currentPage--"
      @submit="submit"
      @confirmationMessage="setDisplay"
      :emailInfo="payload"
    />
    <div class="display-message">{{displayMessage}}</div>
  </div>
</template>

<script>
import Icon from "@/assets/svg/Logo.svg";
import GetEmailInfo from "@/components/Admin/AdminGetEmailInfo.vue";
import ChooseEmail from "@/components/Admin/AdminChooseEmail.vue";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      pages: [ChooseEmail, GetEmailInfo],
      currentPage: 0,
      payload: {},
      loading: false,
      displayMessage: ""
    };
  },
  methods: {
    next(payload) {
      this.payload = {
        ...this.payload,
        ...payload,
      };
      this.currentPage++;
    },
    setDisplay(message) {
      this.displayMessage = message;
      setTimeout(() => {
        this.displayMessage = "";
      }, 10 * 1000);
    },
    async submit(payload) {
      this.loading = true;
      this.payload = {
        ...this.payload,
        ...payload,
      };
      if (this.payload.emailSelection === "project") {
        await this.$http.post(`${this.$API}project/${this.payload.projectId}/subscribe`, this.payload);
      } else {
        await this.$http.get(`${this.$API}${this.payload.context}/${this.payload.id}/sendLogin`);
      }
      this.payload = {};
      this.currentPage = 0;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.send-email-container {
  height: 91vh;
  width: 100vw;
}
.card {
	position: absolute;
  display: flex;
  flex-direction: column;
  height: 50vh;
  width: 75vw;
  margin: 17.5vh 12.5vw;
	background: #fff;
  padding: 3vh 3vw;
  border-radius: 25px;
  box-shadow: var(--box-shadow);
}
.display-message {
  position: absolute;
  color: var(--blue-300);
  bottom: 23.75vh;
  font-size: .25rem;
  left: 16vw;
}
</style>

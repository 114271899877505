import { render, staticRenderFns } from "./TreeTrimming.vue?vue&type=template&id=865ff476&scoped=true&"
import script from "./TreeTrimming.vue?vue&type=script&lang=js&"
export * from "./TreeTrimming.vue?vue&type=script&lang=js&"
import style0 from "./TreeTrimming.vue?vue&type=style&index=0&id=865ff476&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "865ff476",
  null
  
)

export default component.exports
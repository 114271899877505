<template>
  <div>
    <div class="loading" v-if="loading">
      <Logo class="loading-icon" />
    </div>
    <Header :navItems="navItems" :openNav="openNav" />
    <div @click="closeNav" class="bg">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
// Components
import Header from "@/components/Common/Header/Header.vue";
import Logo from "@/assets/svg/Logo.svg";

// Icons
import Customers from "@/assets/svg/UserProfile.svg";
import Money from "@/assets/svg/Money4.svg";
import HomeIcon from "@/assets/svg/Star.svg";
import Plus from "@/assets/svg/Plus2.svg";
import Logout from "@/assets/svg/Logout4.svg";

import { mapGetters } from "vuex";

export default {
  props: ["nav"],
  components: {
    Header,
    Logo,
  },
  data() {
    return {
      loading: false,
      openNav: true,
      navItems: [
        {
          copy: "Dashboard",
          link: "landing",
          icon: HomeIcon,
        },
        {
          copy: "Search Users",
          link: "filterUser",
          icon: Customers,
        },
        {
          copy: "Add Rep",
          link: "addRep",
          icon: Plus,
        },
        {
          copy: "Wallet",
          link: "view/c",
          icon: Money,
        },
        {
          copy: "Logout",
          link: "logout",
          icon: Logout,
        },
      ],
    };
  },
  methods: {
    closeNav() {
      this.openNav = false;
      setTimeout(() => {
        this.openNav = true;
      }, 500);
    },
    async getList() {
      this.loading = true;
      const reps = await this.$http.put(`${this.$API}company/reps`, {
        companyCode: this.userConfig.companyCode,
      });
      const promises = [];
      if (!reps.data) {
        this.loading = false;
        return;
      }
      for (const rep of reps.data) {
        promises.push(this.$http.get(`${this.$API}rep/${rep.id}/user`));
      }
      let customers = [];
      (await Promise.all(promises)).forEach((res) => {
        if (res.data.length > 0) customers = customers.concat(res.data);
      });
      this.$store.commit("setCustomers", { customers: customers });
      if (reps.data.length > 0)
        this.$store.commit("setReps", { reps: reps.data });
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["userConfig", "customers", "reps"]),
  },
  async mounted() {
    if (!this.userConfig.id) this.$router.push("/");
    await this.getList();
    if (this.nav) this.$router.push(this.nav);
  },
};
</script>

<style scoped>
.bg {
  min-height: 91vh;
  background: #ededed;
}
</style>

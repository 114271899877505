<template>
  <div class="card-container">
    <div class="options-container">
      <div class="items">
        <div
          class="button"
          :class="options.includes('roofing') ? 'active' : ''"
          @click="toggle('roofing', 0, 3)"
        >
          Roof Quote
        </div>
      </div>
      <div class="items">
        <div
          class="button"
          :class="options.includes('tree_trimming') ? 'active' : ''"
          @click="toggle('tree_trimming', 1, 3)"
        >
          Tree Quote
        </div>
      </div>
      <div class="items">
        <div
          class="button"
          :class="options.includes('generac') ? 'active' : ''"
          @click="toggle('generac', 2, 1)"
        >
          Generator
        </div>
      </div>
      <div class="items">
        <div
          class="button"
          :class="options.includes('insulation') ? 'active' : ''"
          @click="toggle('insulation', 3, 1)"
        >
          Attic Insulation
        </div>
      </div>
      <div class="items">
        <div
          class="button"
          :class="options.includes('pool_pumps') ? 'active' : ''"
          @click="toggle('pool_pumps', 4, 1)"
        >
          Pool Pump
        </div>
      </div>
      <div class="items">
        <div
          class="button"
          :class="options.includes('hot_water_heater') ? 'active' : ''"
          @click="toggle('hot_water_heater', 5, 1)"
        >
          Hybrid Hot Water Heater
        </div>
      </div>
      <div class="items">
        <div
          class="button"
          :class="options.includes('hvac') ? 'active' : ''"
          @click="toggle('hvac', 6, 2)"
        >
          HVAC
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	props: ['options'],
	data() {
		return {
			componentName: null,
			componentNumber: 0,
			maxPages: 0,
		}
	},
	computed: {
		payload() {
			return {
				componentName: this.componentName,
				componentNumber: this.componentNumber,
				maxPages: this.maxPages
			}
		}
	},
	methods: {
    toggle(componentName, componentNumber, maxPages) {
      this.componentName = componentName;
      this.componentNumber = componentNumber;
      this.maxPages = maxPages;
      if (!this.options.includes(componentName)) {
        this.$emit('select', this.payload)
      } else {
				this.$emit('clear', this.payload);
			}
    },
	}
};
</script>

<style scoped>

.card-container {
  width: 70vw;
  height: 70vh;
  margin: 10vh auto;
  font-size: 0.3rem;
  background: #fff;
  box-shadow: var(--box-shadow);
  border-radius: 25px;
}
.options-container {
  font-size: 0.25rem;
  height: 100%;
  display: grid;
  grid-template-areas:
    "a b c"
    "d e f"
    ". g .";
  align-items: center;
  justify-content: space-evenly;
}
.items {
  height: 6vh;
  width: 100%;
  display: flex;
  align-items: center;
}
.check-item {
  width: fit-content;
  margin-left: 1vw;
}
.check-item:first-child {
  margin-left: 1vw;
}
.check {
  height: 4vh;
  width: 4vh;
  background: #ededed;
  cursor: pointer;
  margin-right: 1vw;
}
.check-text {
  font-size: 0.2rem;
  width: 2rem;
}
.button {
	padding: .5vh 1vw
}
</style>

<template>
  <div class="landing-container">
    <SearchBar
      :reps="activeReps"
      :homeowners="customers"
      @adminSnapShot="setSnapShot"
      class="search-bar"
    />
    <AnalyticSnapshot
      class="analytics"
      :chartData="chartData"
      :context="snapShotType"
    />
    <CurrentProjects
      class="current-projects"
      :snapshot="snapshot"
      :context="snapShotType"
    />
    <Map class="map" :homeowners="customers" />
  </div>
</template>

<script>
import SearchBar from "@/components/Common/SearchUsers.vue";
import AnalyticSnapshot from "@/components/Common/AnalyticSnapshot.vue";
import CurrentProjects from "@/components/Common/CurrentProjects.vue";
import Map from "@/components/Common/Map.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SearchBar,
    AnalyticSnapshot,
    CurrentProjects,
    Map,
  },
  data() {
    return {
      snapShotType: "customers",
    };
  },
  methods: {
    setSnapShot(userType) {
      this.snapShotType = userType;
    },
  },
  computed: {
    ...mapGetters(["reps", "customers", "userConfig"]),
    snapshot() {
      let snapshot = [];
      for (const rep of this.reps) {
        let repSnap = {
          analyticOne: 0,
          analyticTwo: rep.points,
          id: rep.id,
          abbreviation: `${rep.fName.charAt(0).toUpperCase()}${rep.lName
            .charAt(0)
            .toUpperCase()}`,
          companyName: rep.companyName,
        };
        let count = 0;
        for (const customer of this.customers) {
          if (customer.repId === rep.id) count++;
        }
        repSnap.analyticOne = count;
        snapshot.push(repSnap);
      }
      snapshot = snapshot.sort((a, b) => b.analyticOne - a.analyticOne);
      return snapshot;
    },
    chartData() {
      const snapshot = [];
      const categories = [
        "Generac",
        "HVAC",
        "Pool Pumps",
        "Hot Water Heater",
        "Roofing",
        "Insulation",
        "Tree Trimming",
      ];
      for (const customer of this.customers) {
        let repIndex = snapshot
          .map((obj) => {
            return obj.repId;
          })
          .indexOf(customer.repId);
        if (repIndex === -1) {
          snapshot.push({
            repId: customer.repId,
            name: `${customer.repFName} ${customer.repLName}`,
            data: new Array(7).fill(0),
          });
          repIndex = snapshot.length - 1;
        }
        for (const project of customer.projectSnapShot) {
          switch (project.projectName) {
            case "generac":
              snapshot[repIndex].data[0]++;
              break;
            case "hvac":
              snapshot[repIndex].data[1]++;
              break;
            case "pool_pumps":
              snapshot[repIndex].data[2]++;
              break;
            case "hot_water_heater":
              snapshot[repIndex].data[3]++;
              break;
            case "roofing":
              snapshot[repIndex].data[4]++;
              break;
            case "insulation":
              snapshot[repIndex].data[5]++;
              break;
            case "tree_trimming":
              snapshot[repIndex].data[6]++;
              break;
            default:
              break;
          }
        }
      }
      return {
        categories: categories,
        series: snapshot,
      };
    },
    activeReps() {
      return this.reps.filter((a) => a.isActive);
    },
  },
};
</script>

<style scoped>
.landing-container {
  width: 100%;
  height: 91vh;
  background: #ededed;
  display: grid;
  grid-template-areas:
    "search analytics maps"
    "search projects maps";
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 3fr 4fr 3fr;
  column-gap: 2vw;
}
.search-bar {
  grid-area: search;
}
.analytics {
  grid-area: analytics;
}
.current-projects {
  grid-area: projects;
}
.map {
  grid-area: maps;
  height: 90%;
  width: 90%;
  margin: 5%;
}
</style>

export const States = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WY",
];

export function FinanceOptions() {
  let list = [];

  list = list.concat(constructList("GoodLeap", 10, 3, 4));
  list = list.concat(constructList("GoodLeap", 15, 3, 6));
  list = list.concat(constructList("GoodLeap", 20, 0, 7));
  list = list.concat(constructList("GoodLeap", 25, 3, 8))

  list = list.concat(constructList("Sunlight Financial", 20, 0, 1));
  list = list.concat(constructList("Sunlight Financial", 25, 0, 0));

	list = list.concat(constructList("Mosaic", 25, 0, 0));

	list.push({
		company: "GoodLeap",
		months: 15 * 12,
		apr: 3.49
	});
	
  return list;
}

function constructList(name, years, start, end) {
  const list = [];
  const aprOptions = [1.49, 1.99, 2.49, 2.99, 3.99, 4.49, 4.99, 5.99, 6.99];
  for (let i = start; i < end + 1; i++) {
    list.push({
      company: name,
      months: years * 12,
      apr: aprOptions[i],
    });
  }
	return list;
}

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import cookies from "vue-cookies";

const api = process.env.VUE_APP_API;
const docBucket = process.env.VUE_APP_DOCUMENT_BUCKET;

Vue.use(Vuex);
// For socket actions and mutations prefix: socket_function_

export default new Vuex.Store({
  state: {
    userConfig: {},
    customers: [],
    reps: [],
    companies: [],
    images: {},
    notifications: [],
    level: "",
  },
  getters: {
    userConfig: (state) => {
      return state.userConfig;
    },
    customers: (state) => {
      return state.customers;
    },
    reps: (state) => {
      return state.reps;
    },
    companies: (state) => {
      return state.companies;
    },
    images: (state) => {
      return state.images;
    },
    notifications: (state) => {
      return state.notifications;
    },
    level: (state) => {
      return state.level;
    },
  },
  mutations: {
    logout(state) {
      state.userConfig = [];
      state.customers = [];
      state.reps = [];
      state.companies = [];
      state.images = {};
      state.notifications = [];
      state.level = "";
      cookies.remove("access");
      cookies.remove("refresh");
    },
    /**
     * Set user information for individual logging in. Used in all views
     * @arg {Object} payload - Set of all user data, varies per login
     */
    setUserConfig(state, payload) {
      delete payload.refreshToken;
      delete payload.accessToken;
      state.userConfig = payload;
    },
    /**
     * Update a setting for current user, under components/Settings.vue
     * @arg {Object} payload - A list of the changes sent to db, to be altered here
     */
    updateUserConfig(state, payload) {
      for (const item in payload) {
        state.userConfig[item] = payload[item];
      }
    },
    /**
     * Update a different users settings from admin
     * @arg {Object} payload
     * @arg {int} id - user id to change
     * @arg {string} userType - type of user to modify
     * @arg {string || int} item - key of item for user config to change
     */
    updateUser(state, payload) {
      let userType;
      switch (payload.userType) {
        case "user":
          userType = "customers";
          break;
        case "rep":
          userType = "reps";
          break;
        default:
          userType = "companies";
          break;
      }
      const user = state[userType].filter((a) => a.id === payload.id)[0];
      for (const item in payload) {
        if (item !== "id") {
          user[item] = payload[item];
        }
      }
      if (userType === "reps" && state.companies.length > 0) {
        const company = state.companies.filter(
          (a) => a.companyCode === user.companyCode
        );
        if (company[0]) {
          user.companyName = company[0].companyName;
        }
      }
    },
    /**
     * Set customers currently under rep, for rep view
     * @arg {Array} payload.customers - List of all customers and associated information
     */
    setCustomers(state, payload) {
      state.customers = payload.customers;
    },
    /**
     * When a new customer is created, add to existing list to pull
     * @arg {Object} payload - All the user data collected in NewLead.vue
     */
    addCustomer(state, payload) {
      state.customers.unshift(payload);
    },
    /**
     * Add reps to list, used in company/admin view
     * @arg {Array} payload.reps - The list of users associated with company or all users for admin
     */
    setReps(state, payload) {
      state.reps = payload.reps;
    },
    /**
     * When a new rep is created, add to existing list to pull
     * @arg {Object} payload - queried new rep
     */
    addRep(state, payload) {
      state.reps.unshift(payload);
    },
    /**
     * Set Companies from admin
     * @arg {Object} payload - Company information coming in
     * @arg {Array} payload.companies - List of companies to save from query
     */
    setCompanies(state, payload) {
      state.companies = payload.companies;
    },
    /**
     * When a new company is created, add to existing list to pull
     * @arg {Object} payload - queried new company
     */
    addCompany(state, payload) {
      state.companies.unshift(payload);
    },
    /**
     * Adds a temporary notification when a user is past due for job
     * @arg {Object} notification - the notification to push
     * @arg {int} notification.id - user id associated
     */
    addNotification(state, notification) {
      state.notifications.unshift(notification);
    },
    /**
     * update a users set solar company from admin view
     * @arg {object} payload - Base object
     * @arg {int} userId - user being assigned to company
     * @arg {int} companyId - company being assigned
     */
    setSolarCompany(state, payload) {
      const user = state.customers.filter((a) => a.id === payload.userId)[0];
      const company = state.companies.filter(
        (a) => a.id === payload.companyId
      )[0];
      user.solarCompany = company.companyName;
    },
    /**
     * Grab notes for a project in project settings
     * @arg {Object} payload - queried project
     * @arg {int} id - id of homeowner to search
     * @arg {string} tableName - name of project to add notes to
     * @arg {Object[]} notes - list of notes to add to user
     */
    setNotes(state, payload) {
      let user;
      if (payload.isHomeowner) {
        user = state.userConfig;
      } else {
        user = state.customers.filter((a) => a.id === payload.id)[0];
      }
      const project = user.projects.filter(
        (a) => a.tableName === payload.tableName
      )[0];
      project.notes = payload.notes;
    },
    addNote(state, payload) {
      let user;
      if (payload.isHomeowner) {
        user = state.userConfig;
      } else {
        user = state.customers.filter((a) => a.id === payload.id)[0];
      }
      const project = user.projects.filter(
        (a) => a.tableName === payload.tableName
      )[0];
      if (!project.notes) project.notes = [];
      project.notes.unshift(payload);
    },
    /**
     * Update state of rep | company, set isActive to true
     * @arg {Object} payload
     * @arg {"rep" | "company"} context which set to alter
     * @arg {int} id which user in set to alter
     */
    confirmUser(state, payload) {
      const user =
        payload.context === "rep"
          ? state.reps.filter((a) => a.id === payload.id)[0]
          : state.companies.filter((a) => a.id === payload.id)[0];
      user.isActive = true;
    },
    /**
     * Update state of rep | company, remove rejected user
     * @arg {Object} payload
     * @arg {"rep" | "company"} context which set to alter
     * @arg {int} id which user in set to alter
     */
    deleteUser(state, payload) {
      if (payload.context === "rep") {
        state.reps = state.reps.filter((a) => a.id !== payload.id);
      } else {
        state.companies = state.companies.filter((a) => a.id !== payload.id);
      }
    },
    /**
     * Set images for current user
     * @arg {Object} payload - The set of images pulled from api (key, value of name and url)
     */
    setImages(state, payload) {
      state.images = payload;
    },
    /**
     * Save projects for users in rep/company/admin view
     * @arg {Object} payload - User and associated project
     * @arg {int} payload.userId - Id associated with user in customers table
     * @arg {Object} payload.project - Complete information on the user project pulled from api
     */
    setUserProjects(state, payload) {
      const customer = state.customers.filter(
        (a) => a.id === payload.userId
      )[0];
      if (!customer.projects) customer.projects = [];
      if (customer.projects) customer.projects.push(payload.project);
    },
    /**
     * Add user project
     * @arg {boolean} isHomeowner - check if homeowner requests
     * @arg {int} userId - Who's project to access
     * @arg {string} projectType - What project is being added
     * @arg {object} projectOptions - Settings for project to add
     */
    addProject(state, payload) {
      let user;
      if (payload.isHomeowner) {
        user = state.userConfig;
      } else {
        user = state.customers.filter((a) => a.id === payload.userId)[0];
      }
      if (!user.projects) user.projects = [];
      user.projects.push(payload.project);
    },
    /**
     *  Update a customer project in project settings for rep/company/admin
     * @arg {Object} payload - User project information
     * @arg {int} userId - User whos project is being accessed
     * @arg {int} payload.projectId - Id of project to alter
     * @arg {string} payload.key - Value in project to modify
     * @arg {string} payload.value - Value to set in project
     * @arg {boolean} payload.isHomeowner - Check to set userconfig or customers
     */
    updateProjects(state, payload) {
      let user;
      if (payload.isHomeowner) {
        user = state.userConfig;
      } else {
        user = state.customers.filter((a) => a.id === payload.userId)[0];
      }
      const project = user.projects.filter(
        (a) => a.projectId === payload.projectId
      )[0];
      project[payload.key] = payload.value;
    },
    /**
     * Sets quotes for a user
     * @arg {Object} payload
     * @arg {boolean} payload.isHomeowner
     * @arg {int?} payload.userId
     * @arg {Object[]} payload.quotes
     */
    setQuotes(state, payload) {
      let user;
      if (payload.isHomeowner) {
        user = state.userConfig;
      } else {
        user = state.customers.filter((a) => a.id === payload.userId)[0];
      }
      user.quotes = payload.quotes;
    },
    /**
     * Accept quote for a user
     * @arg {Object} payload
     * @arg {int} id - id of quote to modify
     * @arg {int?} userId - id of user to modify
     */
    acceptQuote(state, payload) {
      let user;
      if (payload.userId) {
        user = state.customers.filter((a) => a.id === payload.userId)[0];
      } else {
        user = state.userConfig;
      }
      const quote = user.quotes.filter((a) => a.id === payload.id)[0];
      quote.accepted = true;
    },
    /**
     * Updates project snapshot for use on landing pages
     * @arg {Object} payload - detailed list of where to find project snapshot
     * @arg {int} userId - Id of user to get projects from
     * @arg {string} projectName - Name of project to modify for user
     * @arg {string} key - What section of project to alter
     * @arg {string} value - What to set section of project to
     */
    updateProjectSnapshot(state, payload) {
      const snapshot = state.customers.filter((a) => a.id === payload.userId)[0]
        .projectSnapShot;
      snapshot.filter((a) => a.projectName === payload.projectName)[0][
        payload.key
      ] = payload.value;
    },
    /**
     * @arg {int} userId - User id associated with update
     * @arg {Object[]} documents - List of urls and names pointing to user documents
     * @arg {string} context - Which user to grab
     * @arg {boolean} isUser - if visiting own page
     */
    updateCustomerDocument(state, payload) {
      let user;
      if (payload.isUser) user = state.userConfig;
      else
        user = state[payload.context].filter((a) => a.id === payload.userId)[0];
      if (!user.documents) user.documents = [];
      payload.documents.forEach((document) => user.documents.push(document));
    },
    /**
     * Add photo to user
     * @arg {Object} payload - Incoming photo information
     * @arg {string} payload.context - The respected state array to alter (rep/cust)
     * @arg {int} payload.userId - The id associated with table to alter
     * @arg {string} payload.photoName - The key for photo Url
     * @arg {string} payload.photo - The url value for photo
     */
    setUserImages(state, payload) {
      const user = state[payload.context].filter(
        (a) => a.id === payload.userId
      )[0];
      if (!user.photos) user.photos = {};
      if (user.photos) user.photos[payload.photoName] = payload.photo;
    },
    /**
     * Alter an imageUrl in images
     * @arg {Object} payload - The Photo information
     * @arg {string} payload.key - The Photo's key within the images object
     * @arg {string} payload.value - The Photo's url associated with above key
     */
    alterImage(state, payload) {
      state.images[payload.key] = payload.value;
    },
    /**
     * Remove an image from bucket, and reflect here
     * @arg {string} payload - The Photos key to be removed
     */
    deleteImage(state, payload) {
      delete state.images[payload];
    },
    /**
     * Set user solar install date on view
     * @arg {Object} payload - The solar date information
     * @arg {int} payload.userId - User id associated with customer
     * @arg {date || null} payload.solarInstallDate - Installation date set from Company
     */
    setSolarInstall(state, payload) {
      const user = state.customers.filter((a) => a.id === payload.userId)[0];
      user.solarInstallDate = payload.solarInstallDate;
    },
    /**
     * Set user location from google
     * @arg {Object} payload - The solar date information
     * @arg {int} payload.userId - User id associated with customer
     * @arg {date || null} payload.location - lat/lng of users house
     */
    setCustomerLocation(state, payload) {
      const user = state.customers.filter((a) => a.id === payload.userId)[0];
      user.lat = payload.location.lat;
      user.lng = payload.location.lng;
    },
    /**
     * Adjust finance options for a quote
     * @arg {object} payload - quoteId user, and finance option,
     * @arg {int} userId - User to modify, null if homeowner,
     * @arg {int} quoteId - Quote to modify finance options for
     * @arg {object} financeOption - Finance settings
     * @arg {string} company - Finance option company
     * @arg {float} apr - APR for finance option
     * @arg {int} months - Months included in finance loan option
     */
    setFinanceOptions(state, payload) {
      let user;
      if (payload.userId) {
        user = state.customers.filter((a) => a.id === payload.userId)[0];
      } else {
        user = state.userConfig;
      }
      const quote = user.quotes.filter((a) => a.id === payload.quoteId)[0];
      quote.apr = payload.financeOption.apr;
      quote.company = payload.financeOption.company;
      quote.loanYears = payload.financeOption.months / 12;
    },
    /**
     * Sets leaderboard on rep login
     * @arg {Object} state.userConfig - modifications to userConfiguration
     * @arg {Leaderboard[]} leaderboard - contains the following:
     *
     * @typedef Leaderboard
     * @type {object}
     * @property {string} name - Rep name assoicated with below
     * @property {int} totalProjects - Number of projects under rep
     * @property {int} points - Points associated with above projects
     */
    setLeaderboard(state, leaderboard) {
      state.userConfig.leaderboard = leaderboard;
    },
    /**
     * Reset Notifications on logout
     * @arg {*} state
     */
    resetNotifications(state) {
      state.notifications = [];
    },
    readAllNotifications(state) {
      for (const notification of state.notifications) {
        notification.unread = 0;
      }
    },
    /**
     * Set the privileges of the user
     * @arg {*} level
     */
    setLevel(state, level) {
      state.level = level;
    },
    /* ------------ SOCKET FUNCTIONS ------------ */

    /**
     * Socket connection sending initial payload of notifications on login
     * @arg {string[]} notifications - individual messages for user
     */
    socket_function_recievedNotifications(state, notifications) {
      if (state.notifications.length === 0) {
        for (const notification of notifications) {
          state.notifications.push(notification);
        }
      }
    },
    /**
     * Socket connection sending any messages after initial login
     * @arg {string} notification - Message published to user
     */
    socket_function_newNotification(state, notification) {
      state.notifications.unshift(notification);
    },

    /**
     * Socket connection sending delete request if actioned by another user
     * @arg {string} action_key - Unique key to remove.
     */
    socket_function_deleteNotification(state, action_key) {
      state.notifications = state.notifications.filter(
        (a) => a.action_key !== action_key
      );
    },

    socket_function_newQuote(state, notification) {
      const payload = JSON.parse(notification);
      const user =
        payload.context === "userId"
          ? state.customers.filter((a) => a.id === payload.id)[0]
          : state.reps.filter((a) => a.id === payload.id)[0];
      if (!user.documents) user.documents = [];
      const { url, title } = getDocTitle(payload.quote);
      user.documents.unshift({ url, title });
    },

    socket_function_newQuoteUser(state, notification) {
      const payload = JSON.parse(notification);
      const { url, title } = getDocTitle(payload.quote);
      state.userConfig.documents.unshift({ url, title });
    },
    /* ------------ END SOCKET FUNCTIONS ------------ */
  },
  actions: {
    /* -------- Handle new user notification -------- */
    confirmUser({ commit, state }, payload) {
      const notification = state.notifications[payload.index];
      const user = JSON.parse(notification.action_key);
      axios
        .put(`${api}${user.context}/${user.id}/confirm`, {
          notification: notification.action_key,
        })
        .then(() => {
          commit("confirmUser", {
            context: user.context,
            id: user.id,
          });
        });
    },
    deleteUser({ commit, state }, payload) {
      const notification = state.notifications[payload.index];
      const user = JSON.parse(notification.action_key);
      axios
        .put(`${api}${user.context}/${user.id}`, {
          notification: notification.action_key,
        })
        .then(() => {
          commit("deleteUser", {
            context: user.context,
            user: user.id,
          });
        });
    },
    /* -------- Handle new user notification -------- */

    /**
     * On notification, update with new user
     * @arg {number} id - id of user to query for
     */
    socket_function_getUserById({ commit }, id) {
      axios.get(`${api}user/${id}`).then((res) => {
        commit("addCustomer", res.data[0]);
      });
    },
    socket_function_getRepById({ commit }, id) {
      axios.get(`${api}rep/${id}`).then((res) => {
        commit("addRep", res.data[0]);
      });
    },
    socket_function_getCompanyById({ commit }, id) {
      axios.get(`${api}company/${id}`).then((res) => {
        commit("addCompany", res.data[0]);
      });
    },
  },
});

const getDocTitle = (quoteName) => {
  const url = `${docBucket}${quoteName}.html`;
  const pattern = /-.*/;
  let title = quoteName.match(pattern)[0].substring(1);
  title = title.charAt(0).toUpperCase() + title.slice(1);
  return { url, title };
};

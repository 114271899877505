<template>
  <div class="rep-container">
    <div class="loading" v-if="loading">
      <Logo class="loading-icon" />
    </div>
    <div class="company-info-title">Company Information</div>
    <div class="company-info-container">
      <input
        type="text"
        title="Company Code"
        placeholder="Company Identification Code (enter a 0 for independent or unsure, can change later)"
        v-model="companyCode"
        class="input"
        @change="removeError(0)"
        required
      />
      <input
        type="text"
        title="Manager Name"
        placeholder="Manager Name"
        v-model="managerName"
        class="input"
        @change="removeError(1)"
        required
      />
    </div>
    <div class="photo-upload-title">Upload Profile Photo</div>
    <div class="photo-container">
      <label class="alt-sub" :class="userPhoto ? 'hidden-click' : ''">
        <input
          type="file"
          class="photo-input"
          @change="handlePhoto"
          accept=".jpg,.png"
        />
        Click to Upload
      </label>
      <img
        class="photo"
        :class="userPhoto ? '' : 'hidden'"
        alt=""
        :src="userPhoto"
      />
    </div>
    <div class="submit-container">
      <div class="err-msg">{{ errMsg }}</div>
      <div class="button-container">
        <div class="button" @click="back">Back</div>
        <div class="button" @click="submit">Submit</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import Logo from "@/assets/svg/Logo.svg";
export default {
  components: {
    Logo,
  },
  data() {
    return {
      companyCode: '',
      managerName: "",
      userPhoto: "",
      userPhotoUrl: "",
      errMsg: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['userConfig', 'level']),
    payload() {
      return {
        companyCode: this.companyCode,
        managerName: this.managerName,
        userPhoto: this.userPhotoUrl,
        context: "rep",
      };
    },
  },
  methods: {
    checkFilled() {
      const inputFields = document.getElementsByClassName("input");
      let isFilled = true;
      for (const input of inputFields) {
        if (!input.checkValidity()) {
          input.classList.add("invalid-input");
          isFilled = false;
        }
      }
      return isFilled;
    },
    removeError(number) {
      const field = document.getElementsByClassName("input")[number];
      if (field.classList.contains("invalid-input"))
        field.classList.remove("invalid-input");
      this.errMsg = "";
    },
    handlePhoto(e) {
      const file = e.target.files[0];
      const photo = URL.createObjectURL(file);
      this.userPhoto = photo;
      this.userPhotoUrl = file;
    },
    formatName(first, last) {
      return `${first.charAt(0).toUpperCase()}${first.substring(1)} ${last.charAt(0).toUpperCase()}${last.substring(1)}`
    },
    submit() {
      if (this.checkFilled()) {
        this.$emit("submit", this.payload);
      } else {
        this.errMsg = "Please fill out all fields";
      }
    },
    back() {
      this.$emit("back");
    },
  },
  mounted() {
    if (/[cs]/.test(this.level)) {
      this.companyCode = this.userConfig.companyCode;
      this.managerName = this.formatName(this.userConfig.fName, this.userConfig.lName);
    }
  }
};
</script>

<style scoped>
.rep-container {
  font-size: 0.35rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.company-info-title {
  margin-top: 4vh;
}
.company-info-container {
  margin-top: 4vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.input {
  width: 47%;
}
.hidden {
  display: none;
}
.hidden-click {
  opacity: 0;
  margin-bottom: -22vh;
}
.photo-upload-title {
  margin-top: 5vh;
  width: 100%;
  text-align: center;
}
.photo-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.photo {
  margin-top: 2vh;
  height: 22vh;
  width: 22vh;
  object-fit: cover;
  border-radius: 50%;
}
.alt-sub {
  margin-top: 2vh;
  height: calc(11vh + 0.1rem);
  padding-top: calc(11vh - 0.1rem);
  width: 22vh;
  border-radius: 50%;
  background: #ededed;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.2rem;
  cursor: pointer;
  text-align: center;
}
.submit-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
.err-msg {
  color: var(--error-color);
}
.button-container {
  display: flex;
  justify-content: space-between;
  width: 40%;
}
.button {
  width: 47%;
}
</style>

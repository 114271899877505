<template>
  <div v-if="sections > 1" class="dot-container">
    <div class="button" @click="back">{{ page === 0 ? "Exit" : "Back" }}</div>
    <div
      v-for="n of sections"
      :key="'dot-' + n"
      :class="page === n - 1 ? 'completed' : ''"
      class="progress-dots"
    />
    <div class="button" @click="next">
      {{ page === sections - 1 ? "Submit" : "Next" }}
    </div>
  </div>
  <div v-else class="dot-container">
    <div class="button" @click="back">{{ page === 0 ? "Exit" : "Back" }}</div>
    <div class="button" @click="next">
      {{ page === sections - 1 ? "Submit" : "Next" }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["sections"],
  data() {
    return {
      //current page
      page: 0,
    };
  },
  methods: {
    next() {
      if (this.page < this.sections - 1) {
        this.page += 1;
        this.$emit("next");
      } else {
        this.$emit("submit", { id: this.id, payload: this.payload });
      }
    },
    back() {
      if (this.page > 0) {
        this.page -= 1;
        this.$emit("previous");
      } else {
        this.$emit("quit");
      }
    },
  },
};
</script>

<style scoped>
.dot-container {
  margin: auto 0 4vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.progress-dots {
  background: #898989;
  width: 3vh;
  height: 3vh;
  border-radius: 50%;
}
.completed {
  background: var(--blue-300);
}
.button {
  width: 15%;
}
</style>

<template>
  <div class="notes">
    <div class="spacing" v-if="level !== 'a'">
      <strong>Whats Next:</strong> <br/>{{ whatsNext }}</div>
    <div class="notes-header">Project Info</div>
    {{ notes }}
  </div>
</template>

<script>
export default {
  props: ["selectedProject"],
  data() {
    return {
      notes: "",
    };
  },
  computed: {
    level() {
      return this.$store.getters.level;
    },
    whatsNext() {
      const whatsNext = [
        "We are currently waiting on Photo uploads!",
        this.selectedProject.whatsNextOne,
        this.selectedProject.whatsNextTwo,
        this.selectedProject.whatsNextThree,
        this.selectedProject.whatsNextFour,
        this.selectedProject.whatsNextFive,
      ];
      return whatsNext[this.selectedProject.completion];
    },
  },
  methods: {
    formatNotes() {
      const project = this.selectedProject;
      let notes = `Solar Install Date: ${this.solarInstallDate},`;
      switch (project.title) {
        case "Generator":
          notes += `
						Home has an active gas meter: ${!!project.gasMeter},
						Gas meter has access from the street: ${!!project.access}.
					`;
          break;
        case "Roofing":
          notes += `
						Age of roof: ${project.ageOfRoof ? project.ageOfRoof : "Unknown"},
						Type of roof: ${project.typeOfRoof},
						Require a soffit replacement: ${!!project.replaceSoffit},
						Require a gutter replacement: ${!!project.replaceGutter},
						Require a fascia replacement: ${!!project.replaceFascia},
						Desired shingle color: ${
              project.shingleColor.one ? project.shingleColor : "Undecided"
            },
						Desired edge color: ${project.edgeColor ? project.edgeColor : "Undecided"},
					`;
          // Unusued
          // Gravel based: ${!!project.gravel},
          // Double layered roof: ${!!project.doubleLayers},
          // Number of stories: ${project.stories},
          break;
        case "Tree Trimming/Removal":
          notes += `
						Gate access of more than 6ft: ${!!project.hasGateAccess},
						Documented hazards: ${project.hazards},
						Septic Tank in the home: ${!!project.hasSepticTank},
						${
              project.septicTankLocation
                ? "Septic tank location: " + project.septicTankLocation + ","
                : ""
            }
						Tree count for project: ${project.treeCount},
						Restriction during cutting: ${project.permissions},
						Trimming required: ${!!project.trimming},
						Removal required: ${!!project.removal},
						Tress are within 10 feet of powerline: ${!!project.powerLineProx},   
					`;
          break;
        case "Pool Pumps":
          notes += "";
          break;
        case "Insulation":
          notes += `
						Type of insulation: ${project.typeOf}
					`;
          break;
        case "Hot Water Heater":
          notes += `
						Gas or electric heater: ${project.isGas},
						Location of heater: ${project.location ? project.location : "not documented"},
					`;
          break;
        case "HVAC":
          notes += "";
          break;
        default:
          break;
      }
      notes += `
        Subscriptions:
      `;
      if (this.selectedProject.subscribers.length > 0) {
        for (const subscriber of this.selectedProject.subscribers) {
          notes += `
            Name: ${
              subscriber.contactName
            }, Type: ${subscriber.contactType.charAt(0).toUpperCase() +
            subscriber.contactType.substring(1)}, Company: ${
            subscriber.companyName
          }
          `;
        }
      } else {
        notes += `
          None;
        `;
      }
      notes = notes.replace(/^ +/gm, "");
      return notes;
    },
  },
  watch: {
    selectedProject: {
      handler() {
        this.notes = this.formatNotes();
      },
    },
  },
  mounted() {
    this.notes = this.formatNotes();
  },
};
</script>

<style scoped>
.notes-header {
  font-weight: bold;
  margin-bottom: 1vh;
  position: sticky;
  top: 0;
  background: #d4d4d4;
}
.notes {
  height: 32vh;
  margin: 3vh 0 2vh;
  white-space: pre-line;
  overflow: auto;
}
.spacing {
  margin-bottom: 2vh;
}
</style>

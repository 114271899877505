<template>
  <div class="pool-pumps-container">
    <div class="pool-pump-header">Please Provide Images of the Pool Pump:</div>
    <div class="pool-pump-photo-container">
      <img
        class="photo"
        alt=""
        @click="handleClick(false)"
        :src="poolPumpPhoto"
      />
      <label class="sub">
        <input
          type="file"
          class="photo-input"
          @change="handlePhoto($event, 'poolPumpPhoto')"
          accept=".jpg,.png"
          ref="poolPumpPhoto"
        />
        Pool Pump
      </label>
    </div>
    <div class="pool-pump-tag-container">
      <img
        class="photo"
        alt=""
        @click="handleClick(true)"
        :src="poolTagPhoto"
      />
      <label class="sub">
        <input
          type="file"
          class="photo-input"
          @change="handlePhoto($event, 'poolTagPhoto')"
          accept=".jpg,.png"
          ref="poolTagPhoto"
        />
        Data Tag
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentPage"],
  data() {
    return {
      poolPumpPhoto: require("../../../../assets/images/PoolPump2.png"),
      poolTagPhoto: require("../../../../assets/images/PoolPumpTag2.png"),
      poolPumpPhotoUrl: "",
      poolTagPhotoUrl: "",
    };
  },
  computed: {
    payload() {
      return {};
    },
    imagePayload() {
      return {
        PoolPump: this.poolPumpPhotoUrl,
        PoolTag: this.poolTagPhotoUrl,
      };
    },
  },
  methods: {
    handlePhoto(e, photoChoice) {
      const url = `${photoChoice}Url`;
      const file = e.target.files[0];
      const photo = URL.createObjectURL(file);
      this[photoChoice] = photo;
      this[url] = file;
    },
    handleClick(isTag) {
      if (isTag) this.$refs.poolTagPhoto.click();
      else this.$refs.poolPumpPhoto.click();
    },
  },
};
</script>

<style scoped>
.pool-pumps-container {
  height: calc(100% - 7vh);
  width: 86%;
  display: grid;
  grid-template-areas:
    ". a a ."
    "b b c c";
  margin: auto;
}
.pool-pump-header {
  grid-area: a;
  margin: auto;
  font-size: 0.3rem;
}
.pool-pump-photo-container,
.pool-pump-tag-container {
  grid-area: b;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.pool-pump-tag-container {
  grid-area: c;
}
.photo {
  max-width: 24vw;
  max-height: 30vh;
  margin: 0;
  cursor: pointer;
}
.photo:hover ~ .sub {
  background: var(--blue-600);
  color: #fff;
}
</style>

<template>
  <div class="quote-container">
    <div class="loading" v-if="loading"><Icon class="loading-icon" />"</div>
    <Cog
      class="cog"
      @click="popup = true"
      v-if="/[rcsa]/.test(level) && !/createQuote/.test($route.path)"
    />
    <QuoteHeader :user="user" :items="activeItems" />
    <Roofing v-if="roofing" :item="roofing" :loanYears="loanYears" />
    <component
      v-for="(component, index) of activeComponents"
      :is="index % 2 === 0 ? RightItem : LeftItem"
      :item="component"
      :loanYears="loanYears"
      :key="'item-' + index"
      class="section"
    />
    <QuoteFooter
      :quote="quote"
      :name="`${user.fName} ${user.lName}`"
      :userId="userId"
    />
    <div class="popup-container" v-if="/[rcsa]/.test(level) && popup === true">
      <div class="card">
        <div class="popup-text">Select a finance option from below:</div>
        <label for="company">Select a company</label>
        <select class="input" v-model="financeCompany" id="company">
          <option
            v-for="(option, index) of financeCompanyOptions"
            :value="option"
            :key="index + '-company'"
          >
            {{ option }}
          </option>
        </select>
        <label for="financeOption">Select a loan option</label>
        <select class="input" v-model="financeOption" id="financeOption">
          <option
            v-for="(option, index) of filteredFinance"
            :value="option"
            :key="index + '-finance'"
          >
            {{ Math.floor(option.months / 12) }} Years, {{ option.apr }}%, (26%
            ITC)
          </option>
        </select>
        <div class="button-container-2">
          <div class="button" @click="confirmFinance">Confirm</div>
          <div class="button" @click="popup = false">Back</div>
        </div>
      </div>
    </div>
    <div class="button-container" v-if="/createQuote/.test($route.path)">
      <div class="button" @click="submit">Submit</div>
      <div class="button" @click="$router.back()">Back</div>
    </div>
  </div>
</template>

<script>
import QuoteHeader from "@/components/Common/Quotes/QuoteHeader.vue";
import QuoteFooter from "@/components/Common/Quotes/QuoteFooter.vue";

import Roofing from "@/components/Common/Quotes/Roofing.vue";
import LeftItem from "@/components/Common/Quotes/LeftItem.vue";
import RightItem from "@/components/Common/Quotes/RightItem.vue";

import { FinanceOptions } from "@/assets/const.js";
import Icon from "@/assets/svg/Logo.svg";
import Cog from "@/assets/svg/Cog2.svg";

import { mapGetters } from "vuex";

export default {
  props: ["items", "userId", "quoteId"],
  components: {
    QuoteHeader,
    QuoteFooter,
    Roofing,
    Icon,
    Cog,
  },
  data() {
    return {
      popup: false,
      loading: false,
      financeOption: {},
      financeCompany: "",
      financeOptions: FinanceOptions(),
      loanYears: "",
      RightItem: RightItem,
      LeftItem: LeftItem,
      header: {
        tree_trimming: "Tree Trimming",
        hot_water_heater: "HHWH",
        hvac: "HVAC",
        insulation: "Insulation",
        pool_pumps: "Pool Pump",
        generac: "Generator",
      },
      photoUrl: {
        tree_trimming: require("../../assets/images/Quotes/TreeTrimming.jpg"),
        hot_water_heater: require("../../assets/images/Quotes/HWH.png"),
        hvac: require("../../assets/images/Quotes/HVAC.jpg"),
        insulation: require("../../assets/images/Quotes/Insulation.jpeg"),
        pool_pumps: require("../../assets/images/Quotes/PoolPump.jpg"),
        generac: require("../../assets/images/Quotes/Generator.jpg"),
      },
      bulletItems: {
        tree_trimming: [
          "Will consist of topping, removing, and stump grinding specified tree",
          "Will consist of trimming limbs on specified tree for maximum solar production",
          "All tree work debris will be removed from property and site cleanliness maintained",
        ],
        hot_water_heater: [
          "Drain and remove existing hot water heater",
          "Replace and install energy efficient hybrid hot water heater",
          "Includes existing equipment removal and site cleanliness maintained",
        ],
        hvac: [
          "Remove and dispose of existing equipment",
          "Install new air handler and heat pump condenser",
          "Copper Line flushed or replaced to accommodate new refrigerant type",
          "Plywood decking will be replaced if needed",
          "Outside condensing unit will be installed on concrete pad",
          "Drain line overflow safety switch installed",
          "1 year parts and labor warranty",
        ],
        insulation: [
          "Test existing attic insulation for possible mold and asbestos",
          "Remove affected areas",
          "Apply green fiber insulation througout accessible attic space up to R38",
        ],
        pool_pumps: [
          "Install Pentair Superflo variable speed poolpump",
          "Disposal of existing pool pump",
          "System tests",
        ],
        generac: [
          "ATS (Automatic Transfer Switch) Install",
          "Wet Cell Battery",
          "Generator Pad",
          "Electrical Ran",
          "Gas Line Connected to Meter",
          "Initial Start Up of Unit",
          "WiFi Enabled App Connected",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userConfig", "customers", "level"]),
    user() {
      if (this.userId)
        return this.customers.filter((a) => a.id === parseInt(this.userId))[0];
      return this.userConfig;
    },
    quote() {
      if (this.quoteId) {
        return this.user.quotes.filter(
          (a) => a.id === parseInt(this.quoteId)
        )[0];
      }
      return null;
    },
    activeItems() {
      if (this.quote) {
        return this.constructItems(this.quote.projects);
      }
      return this.items;
    },
    financeCompanyOptions() {
      const names = [];
      for (const company of this.financeOptions) {
        if (names.indexOf(company.company) === -1) names.push(company.company);
      }
      return names;
    },
    filteredFinance() {
      if (!this.financeCompany) return;
      return this.financeOptions.filter(
        (a) => a.company === this.financeCompany
      );
    },
    activeComponents() {
      let ret = [];
      for (const item of this.activeItems) {
        if (item.project !== "roofing") {
          ret.push({
            item: item,
            header: this.header[item.project],
            photoUrl: this.photoUrl[item.project],
            bulletItems: this.bulletItems[item.project],
          });
        }
      }
      return ret;
    },
    roofing() {
      const item = this.activeItems.filter((a) => a.project === "roofing");
      if (item[0])
        return {
          project: "roofing",
          projectInfo: this.user.projects.filter(
            (a) => a.tableName === "roofing"
          )[0],
          bill: item[0].bill,
        };
      else return null;
    },
  },
  methods: {
    constructItems(quoteItems) {
      let ret = [];
      for (const item of quoteItems) {
        const project = this.user.projects.filter(
          (a) => a.tableName === item.project
        )[0];
        ret.push({
          project: item.project,
          projectInfo: project,
          bill: item.bill,
        });
      }
      return ret;
    },
    async confirmFinance() {
      if (this.financeOption.company && this.quoteId) {
        await this.$http.put(`${this.$API}user/quote/${this.quoteId}/set`, {
          apr: this.financeOption.apr,
          loanYears: this.financeOption.months / 12,
          company: this.financeOption.company,
        });
        this.$store.commit("setFinanceOptions", {
          // Check if user is homeowner
          userId:
            this.user.username === this.userConfig.username
              ? null
              : this.user.id,
          quoteId: parseInt(this.quoteId),
          financeOption: this.financeOption,
        });
        this.loanYears = this.financeOption.months / 12;
        this.popup = false;
      }
    },
    async submit() {
      this.loading = true;
      try {
        const quoteId = (
          await this.$http.post(`${this.$API}admin/quote`, {
            homeId: this.user.homeId,
          })
        ).data[0].id;
        for (const project of this.items) {
          const projectId = project.projectInfo.projectId;
          await this.$http.put(`${this.$API}project/${projectId}/bill`, {
            bill: project.bill,
          });
          this.$store.commit("updateProjects", {
            projectId: projectId,
            key: "bill",
            value: project.bill,
            userId: parseInt(this.userId),
          });
          await this.$http.post(
            `${this.$API}admin/quote/${quoteId}/project/${projectId}`
          );
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
        return;
      }
      this.loading = false;
    },
  },
  mounted() {
    if (this.quoteId && !this.quote.apr) {
      this.popup = true;
      this.loanYears = 20;
    } else if (this.quoteId && this.quote.apr) {
      this.loanYears = this.quote.loanYears;
      this.financeCompany = this.quote.company;
      this.financeOption = this.financeOptions.filter(
        (a) =>
          a.apr === this.quote.apr &&
          a.months === this.quote.loanYears * 12 &&
          a.company === this.quote.company
      )[0];
    } else {
      this.loanYears = 20;
    }
  },
};
</script>

<style scoped>
.quote-container {
  position: relative;
  width: 82vw;
  height: fit-content;
  overflow-x: hidden;
  overflow-y: clip;
  margin: auto;
  color: #00000087;
  background: #fff;
  font-size: 0.3rem;
}
.section {
  position: relative;
  display: flex;
  align-items: center;
  margin: 5vh 0;
  min-height: 50vh;
}
.button-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2vh;
  width: 30vw;
  top: 5vh;
  right: 0;
}
.popup-container {
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000a8;
}
.card {
  width: 60vw;
  height: 30vh;
  padding: 5vh 5vw;
  background: #fff;
  border-radius: 25px;
  margin: 30vh auto;
  display: flex;
  flex-direction: column;
}
.popup-text {
  margin-bottom: 3vh;
}
.button-container-2 {
  display: flex;
  justify-content: space-around;
  margin-top: auto;
}
.cog {
  position: absolute;
  z-index: 1;
  height: calc(3vh + 1vw);
  stroke: #fff;
  right: 2vw;
  top: 4vh;
  cursor: pointer;
}
.cog:hover {
  -webkit-animation: rotate 4s linear infinite;
  -moz-animation: rotate 4s linear infinite;
  animation: rotate 4s linear infinite;
}
/* Animations */
@-moz-keyframes rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div class="photo-carousel-container">
    <div class="sudo-carousel" @click="$emit('closePhoto')" />
    <div class="photo-carousel">
      <img class="photo-carousel-photo" :src="photoDisplay" alt="" />
      <div class="button-container">
        <div class="button clickable" @click="photoChange(0)">Previous</div>
        <div class="button clickable" @click="photoChange(1)">Next</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	props: ['userPhotos'],
	data() {
		return {
      photoNumber: 0,
		}
	},
	computed: {
    photoDisplay() {
      const photos = [];
      for (const key in this.userPhotos) {
        photos.push(this.userPhotos[key]);
      }
      return photos[this.photoNumber];
    },
	},
  methods: {
    photoChange(forward) {
      const maxLength = Object.keys(this.userPhotos).length - 1;
      if (forward) {
        if (this.photoNumber < maxLength) {
          this.photoNumber++;
        } else {
          this.photoNumber = 0;
        }
      } else {
        if (this.photoNumber > 0) {
          this.photoNumber--;
        } else {
          this.photoNumber = maxLength;
        }
      }
    },
  },
};
</script>

<style scoped>
.photo-carousel-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}
.sudo-carousel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}
.photo-carousel {
  position: absolute;
  height: 74vh;
  width: 50vw;
  top: 13vh;
  left: 25vw;
  background: #fff;
  border-radius: 25px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}
.photo-carousel-photo {
  height: 50%;
  max-width: 100%;
  margin: auto;
}

.button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: auto;
  justify-items: center;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #fff;
  background: var(--blue-300);
}
</style>

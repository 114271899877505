<template>
  <div class="roofing-container">
    <div class="roofing-info-container">
      <div class="info-container">
        <div class="header">
          {{
            item.projectInfo.typeOfRoof === "Unknown"
              ? "Shingle Reroof"
              : item.projectInfo.typeOfRoof
          }}
        </div>
        <div
          class="bullet-info"
          v-for="(bullet, index) of bulletPoints"
          :key="'bullet' + index"
        >
          <div class="info">{{ bullet }}</div>
        </div>
      </div>
      <img
        src="../../../assets/images/Quotes/Roofing.jpg"
        alt=""
        class="roof-photo"
      />
    </div>
    <div class="roof-image-container">
      <img
        src="../../../assets/images/Quotes/Builders.png"
        alt=""
        class="roofers"
      />
      <div
        v-if="this.level !== 'u'"
        class="monthly-bubble-clickable"
        @click="displayCost = !displayCost"
      />
      <div class="monthly-bubble">
        <div :class="displayCost ? 'total-cost' : 'monthly-cost'">
          ${{
            !displayCost ? Math.round(item.bill / (loanYears * 12)) : item.bill
          }}
        </div>
        <div v-if="!displayCost">per month</div>
      </div>
      <div class="warrenty">
        Includes 25 year warranty<br />
        10 year work warranty
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "loanYears"],
  data() {
    return {
      displayCost: false,
      roofCopy: {
        metal: "",
        tile: "",
        shingle: "",
      },
      bulletPoints: [
        "Furnish Labor and material",
        "Tear off old roof/remove existing shingles",
        "Inspect and re-nail all decking to code",
        "Remove and replace compromised decking",
        "Replace all eave pipe boots and vents",
        "Install peel & stick synthetic underlayment",
        "Includes waste removal/permitting & fees",
      ],
    };
  },
  computed: {
    level() {
      return this.$store.getters.level;
    },
    info() {
      const roofType = this.item.projectInfo.typeOfRoof.toLowerCase();
      switch (true) {
        case roofType.indexOf("metal") !== -1:
          return this.roofCopy.metal;
        case roofType.indexOf("shingle") !== -1:
          return this.roofCopy.shingle;
        case roofType.indexOf("tile") !== -1:
          return this.roofCopy.tile;
        default:
          return this.roofCopy.tile;
      }
    },
  },
};
</script>

<style scoped>
.roofing-container {
  position: relative;
  margin: 12vh 0;
  overflow: hidden;
}
.roofing-info-container {
  display: flex;
  align-items: center;
}
.info-container {
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin-left: 2vw;
  margin-bottom: 4vh;
}
.header {
  color: var(--blue-300);
  font-weight: bold;
  font-size: 0.45rem;
  margin-bottom: 2vh;
}
.bullet-info {
  display: flex;
  align-items: center;
  column-gap: 1vw;
  cursor: default;
  margin: 0 1vw 1vh;
}
.info {
  position: relative;
  font-size: 0.25rem;
  font-weight: bold;
}
.info::before {
  content: "";
  display: inline-block;
  height: 1vh;
  width: 1vh;
  background: var(--blue-300);
  margin-right: 0.5vw;
  border-radius: 50%;
  transform: translateY(-25%);
}
.bullet-info:hover .info::before {
  background: var(--blue-600);
}

.roof-photo {
  width: 50vw;
  border-radius: 25px;
}
.warrenty {
  width: 50vw;
  text-align: center;
  font-weight: bold;
}
.roof-image-container {
  position: relative;
  min-height: 60vh;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}
.roofers {
  width: 100%;
  position: relative;
  z-index: 2;
}
.monthly-bubble,
.monthly-bubble-clickable {
  position: absolute;
  left: -3vw;
  height: calc(30vh + 16vw);
  width: calc(30vh + 16vw);
  background: var(--blue-300);
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 0.4rem;
}
.monthly-bubble-clickable {
  z-index: 3;
  opacity: 0.01;
}
.monthly-cost {
  font-size: calc(9vh + 2vw);
  margin: calc(2vh + 2vw) 0 -5px;
}
.total-cost {
  font-size: calc(7vh + 2vw);
  margin: calc(7vh + 2vw) 0 -5px;
}
</style>

<template>
  <div class="add-user-container">
    <div class="selection" v-if="choice === 'none'">
      <div class="header">
        Select a type of user to sign up.
      </div>
      <div class="button-container-selection">
        <div class="button choice" @click="choice = 'ho'">Homeowner</div>
        <div class="button choice" @click="choice = 'rc'">Representative</div>
        <div class="button choice" @click="choice = 'rc'">Company</div>
      </div>
      <div class="filler"/>
    </div>
    <div class="choice-header" v-else>
      <div class="button back" @click="choice = 'none'">Back</div>
      <div v-if="choice === 'ho'" />
      <div class="input-container" v-if="choice === 'ho'">
        <div class="input-caption">
          Select a rep for user to be assigned to:
        </div>
        <select class="input select rep-choice" v-model="repSelection">
          <option v-for="rep of reps" :key="rep.id" class="rep" :value="rep.id">{{rep.fName}} {{rep.lName}}</option>
          <option :value="0" class="rep">Admin</option>
          <option value="none" class="rep" selected>None</option>
        </select>
      </div>
    </div>
    <div class="cover" v-if="repSelection === 'none' && choice === 'ho'">Please Select A Rep Before Moving Forward</div>
    <component class="signup-container" v-if="choice !== 'none'" :is="selection[choice]" :repId="repSelection" @clearAdmin="clearAdmin"/>
  </div>
</template>

<script>
import SignUpRC from "@/views/Signup.vue";
import SignUpHO from "@/views/Common/NewLead.vue";

export default {
  data() {
    return {
      choice: "none",
      selection: {
        none: '',
        rc: SignUpRC,
        ho: SignUpHO,
      },
      repSelection: 'none',
    }
  },
  computed: {
    reps() {
      return this.$store.getters.reps.filter(a => a.isActive);
    }
  },
  methods: {
    clearAdmin() {
      this.choice = 'none';
      this.repSelection = 'none';
    }
  }
}
</script>

<style scoped>
.selection {
  font-size: .3rem;
	background: #fff;
}
.header {
  width: 100vw;
  text-align: center;
  line-height: 10vh;
}
.button-container-selection {
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
}
.choice {
  width: 23vw;
}
.filler {
  height: 72vh;
  background: #ededed;
  margin-top: 4vh;
}
.choice-header {
  height: 10vh;
  font-size: .3rem;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
	background: #fff;
}
.back {
  width: 15vw;
  margin: auto 0;
}
.input-container {
  display: flex;
  align-items: center;
}
.input-caption {
  margin-right: 1vw;
}
.rep-choice {
  width: 12vw;
  margin: auto 0;
}
.cover {
  position: absolute;
  width: 100vw;
  height: 81vh;
  z-index: 2;
  background-color: #00000094;
  color: white;
  font-size: .5rem;
  line-height: 71vh;
  text-align: center;
}
/* Fixes to signup for rep/company */
.signup-container {
  height: 81vh;
}

/* Fixes to signup for ho */
.add-user-container {
  width: 100%;
  height: 91vh;
}
</style>>
<template>
  <div class="login-container">
    <div class="loading" v-if="loading">
      <Logo class="loading-icon" />
    </div>
    <div class="header">
      <div v-if="isRepOrCompany && !isMobile" class="toggle-item-container">
        <div class="toggle-slider" ref="slider" style="left: 0; width: 0" />
        <div
          v-for="type of types"
          :key="'search-' + type.name"
          class="toggle-item"
          :ref="type.name"
          :class="selected.name === type.name ? 'selected' : ''"
          @click="swap(type)"
        >
          <div>
            {{ type.name }}
          </div>
        </div>
      </div>
      <Logo class="logo" @click="$router.push('/')" />
      <div
        v-if="isRepOrCompany && !isMobile"
        class="sign-up"
        @click="$router.push('/su')"
      >
        Sign Up
      </div>
    </div>
    <div class="card-container">
      <div v-if="!changePass" class="inner-container">
        <div class="profile">
          <img
            class="profile-pic"
            :src="profilePic ? profilePic : defaultPic"
            alt="Profile Picture"
          />
          {{ name ? name : "Welcome" }}
        </div>
        <div class="info-container">
          <input
            type="text"
            placeholder="Email"
            v-model="email"
            class="input"
            @keyup="handleKey"
          />
          <input
            type="password"
            placeholder="Password"
            v-model="password"
            class="input"
            @keyup="handleKey"
          />
          <div class="invalid-login">{{ loginError }}</div>
          <div class="button" @click="login()">Login to Account</div>
        </div>
        <div class="footer">
          Forgot Password? <a @click="changePass = true">Click Here</a>
        </div>
      </div>
      <div class="inner-container" v-else>
        <div class="center">
          <div class="forgot-pass-header">Enter the following information:</div>
          <div class="input-container">
            <input
              type="text"
              title="Email"
              placeholder="Email"
              v-model="forgotPassEmail"
              class="input"
            />
          </div>
          <div class="button-container">
            <div class="button" @click="back">Back</div>
            <div class="button" @click="forgotPassword">Submit</div>
          </div>
          <div class="forgot-pass-msg" v-html="forgotPassMsg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/assets/svg/Logo.svg";
export default {
	props: ["nav"],
  components: {
    Logo,
  },
  data() {
    return {
      isMobile: false,
      profilePic: null,
      defaultPic: require("../assets/svg/UserProfile.svg?external"),
      name: null,
      context: "",
      isRepOrCompany: false,
      loading: false,
      path: "",
      email: "",
      password: "",
      loginError: "",
      level: "",
      selected: { name: "Rep", route: "/rlgn" },
      types: [
        {
          name: "Rep",
          route: "/rlgn",
        },
        {
          name: "Company",
          route: "/clgn",
        },
        {
          name: "Solar",
          route: "/slgn",
        },
      ],
      // changePassword
      changePass: false,
      forgotPassEmail: "",
      forgotPassMsg: "",
    };
  },
  methods: {
    async login(userDetails) {
      this.loading = true;
      const res = userDetails
        ? userDetails
        : await this.$http.put(`${this.$API}login/${this.context}`, {
            email: this.email,
            pass: this.password,
          });
      if (res.data.length === 1) {
        this.$cookies.set("refresh", res.data[0].refreshToken);
        this.$cookies.set("access", res.data[0].accessToken);
        // Get Photos
        const res2 = await this.$http.put(`${this.$API}image/get`, {
          prefix: res.data[0].username,
        });
        const photos = {};
        if (res2.data) {
          for (const image of res2.data[0]) {
            const index = image.name.lastIndexOf(".");
            photos[
              image.name.substring(0, index)
            ] = `${this.$BUCKET}${image.name}`;
          }
        }
        // Get Documents
        const res3 = await this.$http.put(`${this.$API}document/get`, {
          prefix: res.data[0].username,
        });
        const docs = [];
        const pattern = /-.*/;
        if (res3.data) {
          for (const titles of res3.data[0]) {
            let title = titles.name;
            title = title.match(pattern)[0].substring(1);
            title = title.charAt(0).toUpperCase() + title.slice(1);
            title = title.replace(".html", "");
            docs.push({ url: `${this.$DOCUMENT_BUCKET}${titles.name}`, title });
          }
        }

        // Commit to storage
        const userConfig = res.data[0];
        userConfig.pass = this.password;
        userConfig.documents = docs;
        this.$store.commit("setUserConfig", userConfig);
        this.$store.commit("setLevel", this.level);
        if (photos) this.$store.commit("setImages", photos);

        // Connect to socket and store
        const socketName = `${this.context.charAt(0).toUpperCase()}${
          userConfig.id
        }`;
        if (this.context !== "admin") {
          this.$socket.emit("setRoom", socketName);
        } else {
          this.$socket.emit("setRoom", "ansp");
        }
        this.$socket.emit("getNotifications");

        // Nav
        this.loading = false;
				const route = this.nav ? `${this.path}?nav=${this.nav}` : this.path;
        this.$router.push(route);
      } else {
        this.loading = false;
        this.loginError = "Incorrect username or password";
      }
    },
    handleKey(e) {
      if (e.key === "Enter") this.login();
    },
    swap(type) {
      if (!this.$refs.slider) return;
      this.selected = type;
      if (screen.width > 449) {
        this.isMobile = false;
        this.$refs.slider.style.left = `${this.$refs[type.name][0].offsetLeft +
          2}px`;
        this.$refs.slider.style.width = `${this.$refs[type.name][0]
          .offsetWidth - 3}px`;
      } else {
        this.isMobile = true;
        this.$refs.slider.style.left = `${this.$refs[type.name][0].offsetLeft -
          4}px`;
        this.$refs.slider.style.width = `${this.$refs[type.name][0]
          .offsetWidth + 8}px`;
      }
      if (this.$route.path === type.route) return;
      else this.$router.push(type.route);
    },
    async forgotPassword() {
      this.loading = true;
      const res = await this.$http.put(
        `${this.$API}${this.context}/resetPassword`,
        {
          email: this.forgotPassEmail,
        }
      );
      if (!res.data) {
        this.forgotPassMsg = "<span>Email not found.</span>";
      } else {
        this.forgotPassMsg = `<span>An email has been sent to ${this.forgotPassEmail} with your new password.</span>`;
      }
      this.loading = false;
    },
    back() {
      this.changePass = false;
      this.forgotPassEmail = "";
      this.forgotPassUsername = "";
      this.forgotPassMsg = "";
    },
    handleResize() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.swap(this.selected);
      }, 100);
    },
    setup() {
      window.addEventListener("resize", () => {
        this.handleResize();
      });
    },
    tearDown() {
      window.removeEventListener("resize", () => {
        this.handleResize();
      });
    },
  },
  created() {
    if (this.isRepOrCompany) {
      this.setup();
      setTimeout(() => {
        this.swap(this.selected);
      }, 100);
    }
  },
  unmounted() {
    this.tearDown();
  },
  async mounted() {
		if (this.$cookies.get("refresh")) {
			const user = await this.$http.put(`${this.$API}login/return`, {
				token: this.$cookies.get("refresh"),
			});
			if (user.data[0] && user.data[0].context === this.level) {
				this.login(user);
			}
		}
  },
  watch: {
    $route: {
      // Reuse component for all logins, handle api call and reroute
      immediate: true,
      handler() {
        const path = this.$route.path;
        switch (path) {
          case "/algnsk":
            this.path = "a/landing";
            this.isRepOrCompany = false;
            this.context = "admin";
            this.level = "a";
            break;
          case "/rlgn":
						this.selected = this.types[0]
            this.path = "r/landing";
            this.isRepOrCompany = true;
            this.context = "rep";
            this.level = "r";
            break;
          case "/clgn":
						this.selected = this.types[1]
            this.path = "c/landing";
            this.isRepOrCompany = true;
            this.context = "company";
            this.level = "c";
            break;
          case "/slgn":
						this.selected = this.types[2]
            this.path = "s/landing";
            this.isRepOrCompany = true;
            this.context = "solar";
            this.level = "s";
            break;
          default:
            this.path = "h/landing";
            this.isRepOrCompany = false;
            this.context = "user";
            this.level = "u";
            break;
        }
      },
    },
  },
};
</script>

<style scoped>
.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 0.25rem;
  overflow: hidden;
  background: #ededed;
}
.header {
  width: 96vw;
  padding: 0 2vw;
  height: 10vh;
  background: var(--blue-300);
  display: flex;
  position: relative;
}
.logo {
  position: absolute;
  left: calc(50% - 3vh);
  top: 2.5vh;
  height: 5vh;
  width: 6vh;
  transition: 0.5s ease all;
}
.logo:hover {
  cursor: pointer;
  fill: #fff;
}
.card-container {
  width: 45vw;
  margin: auto;
  height: 60vh;
  border-radius: 25px;
  position: relative;
  background: white;
  box-shadow: var(--box-shadow);
}
.inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center {
  margin: auto;
  width: 75%;
}
.forgot-pass-header {
  margin-bottom: 3vh;
}
.input-container {
  margin-bottom: 2vh;
}
.button-container {
  display: grid;
  grid-template-areas: "a b";
  column-gap: 2vw;
}
.card-header {
  position: absolute;
  right: 0;
}
.sign-up {
  position: absolute;
  right: 0;
  margin: 2vh 2vw;
  padding: 0.5vh 1vw;
  color: #000;
  border: 3px solid black;
  cursor: pointer;
  transition: all 0.2s ease;
  line-height: 4vh;
  border-radius: 25px;
}
.sign-up:hover {
  color: #fff;
  border-color: #fff;
}
.profile {
  display: flex;
  flex-direction: column;
  height: 15vh;
  margin-top: 10vh;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.profile-pic {
  height: calc(3vh + 3vw);
  width: calc(3vh + 3vw);
  margin-bottom: 1vh;
}
.info-container {
  display: flex;
  flex-direction: column;
  width: 56%;
  margin-top: 2vh;
}
.input {
  width: calc(100% - 12px);
  margin-bottom: 2vh;
}
.invalid-login {
  height: 5vh;
  width: 100%;
  text-align: center;
  color: #ec5e5e;
}
.button {
  width: 100%;
}
.footer {
  margin-top: auto;
  margin-bottom: 4vh;
  font-size: 0.2rem;
}
.toggle-item-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: auto 0 auto 0vw;
  height: 5vh;
  border: 3px solid #000;
  color: #000;
  border-radius: 25px;
  cursor: pointer;
  transition: border-color 0.5s ease;
}
.toggle-item {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  opacity: 0.5;
  font-weight: bold;
  user-select: none;
  width: fit-content;
  padding: 0 1vw;
  transition: color 0.5s ease, opacity 0.5s ease;
}
.toggle-item:hover {
  color: #fff;
  opacity: 1;
}
.toggle-item-container:hover {
  border-color: #fff;
}
.selected {
  opacity: 1;
  color: #fff;
}
.toggle-slider {
  position: absolute;
  z-index: 1;
  border-radius: 25px;
  background: #000;
  top: 0.25vh;
  height: 4.5vh;
  transition: all 0.5s ease;
}
.forgot-pass-msg {
  display: flex;
  align-items: flex-end;
  height: 10vh;
  color: var(--blue-600);
}
a {
  margin-left: 2vw;
}
@media screen and (max-width: 450px) {
  .card-container {
    width: 80vw;
  }
  .profile {
    font-size: 0.4rem;
    margin-top: 5vh;
  }
  .profile-pic {
    border: 4px solid #000;
    border-radius: 50%;
    height: calc(5vh + 5vw);
    width: calc(5vh + 5vw);
  }
  .info-container {
    width: 75%;
    margin-top: 5vh;
  }
  .input {
    width: calc(100% - 12px);
    box-shadow: inset 1px 1px 13px 0px #00000014;
    margin-bottom: 2vh;
  }
  .button {
    width: 70%;
    margin: auto;
    background: var(--blue-600);
    color: #fff;
  }
  .button:hover {
    background: var(--blue-300);
  }
  .toggle-item-container {
    width: 50vw;
    justify-content: space-evenly;
  }
  .logo-mobile {
    left: calc(68% - 3vh);
  }
}
</style>

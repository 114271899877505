<template>
  <div>
    <div class="loading" v-if="loading">
      <Logo class="loading-icon" />
    </div>
    <Header :navItems="navItems" :openNav="openNav" />
    <div @click="closeNav" class="bg">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
// Components
import Header from "@/components/Common/Header/Header.vue";
import Logo from "@/assets/svg/Logo.svg";

// Icons
import Customers from "@/assets/svg/UserProfile.svg";
import HomeIcon from "@/assets/svg/Star.svg";
import Logout from "@/assets/svg/Logout4.svg";
import Money from "@/assets/svg/Money4.svg";
import Plus from "@/assets/svg/Plus2.svg";

import { mapGetters } from "vuex";

export default {
  props: ["nav"],
  components: {
    Header,
    Logo,
  },
  data() {
    return {
      loading: false,
      openNav: true,
      navItems: [
        {
          copy: "Dashboard",
          link: "landing",
          icon: HomeIcon,
        },
        {
          copy: "Search Users",
          link: "filterUser",
          icon: Customers,
        },
        {
          copy: "New Lead",
          link: "newLead",
          icon: Plus,
        },
        {
          copy: "Add Rep",
          link: "addRep",
          icon: Plus,
        },
        {
          copy: "Wallet",
          link: "view/c",
          icon: Money,
        },
        {
          copy: "Logout",
          link: "logout",
          icon: Logout,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["customers", "userConfig"]),
  },
  methods: {
    async getList() {
      this.loading = true;
      const users = await this.$http.get(
        `${this.$API}company/${this.userConfig.id}/solar`
      );
      if (!users.data) {
        this.loading = false;
        return;
      }
      this.$store.commit("setCustomers", { customers: users.data });
      this.loading = false;
    },
    closeNav() {
      this.openNav = false;
      setTimeout(() => {
        this.openNav = true;
      }, 500);
    },
  },
  async mounted() {
    if (!this.userConfig.id) this.$router.push("/");
    await this.getList();
    if (this.nav) this.$router.push(this.nav);
  },
};
</script>

<style scoped>
.bg {
  min-height: 91vh;
  background: #ededed;
}
</style>

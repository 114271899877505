<template>
  <div class="wallet-container">
    <div class="loading" v-if="loading"><Icon class="loading-icon" /></div>
    <Settings
      v-if="editing"
      @exit="editing = false"
      :user="activeUser"
      userType="rep"
    />
    <div class="rep-info-section">
      <div class="header">
        <div class="header-name">{{ activeUser.fName }}'s Wallet</div>
        <div class="header-project-number">
          {{ projectCount }} project{{ projectCount > 1 ? "s" : "" }}
        </div>
        <div class="header-money-projection">{{ totalPoints }} Points</div>
      </div>

      <div class="latest-customers">
        <div class="customer-header">
          Latest Customers <span class="more">See more ></span>
        </div>
        <div class="all-customer-container">
          <div
            v-for="(customer, index) of customers"
            :key="'customer-' + index"
            class="customer-container"
            @click="nav(customer.id)"
          >
            <div class="customer-divider" />
            <div class="customer-name">
              {{ customer.fName }} {{ customer.lName }}
            </div>
            <div class="customer-date">
              {{ formatDate(customer.createdAt) }}
            </div>
            <div class="customer-price">
              {{ calcPoints(customer.projectSnapShot) }} points
            </div>
          </div>
        </div>
      </div>

      <div class="reward-container">
        Your reward points
        <div class="progress-bar-outer" />
        <div class="progress-bar-inner" />
        <div class="points">
          {{ totalPoints }}
        </div>
        <!-- <div v-for="(tier, index) of tierList" :key="'tier-' + index" class="tier-list" :class="'tier-' + index">
          <div class="item-header">{{tier.header}}</div>
          <div class="item-desc">{{tier.desc}}</div>
        </div> -->
      </div>

      <div class="level-container">
        <div v-for="(n, index) of toText" :key="'level-' + index" class="level">
          Level {{ n }}
          <div class="divider-level" />
        </div>
      </div>
    </div>

    <div class="rep-extras">
      <img
        :src="userImg"
        alt="profile-pic"
        class="rep-profile-pic"
        :class="level === 'a' ? 'profile-pic-select' : ''"
        @click="openPhotoUpload"
      />
      <div class="rep-full-name">
        {{ formatName(activeUser.fName, activeUser.lName) }}
        <Cog class="cog" @click="editing = true" v-if="level === 'a'" />
      </div>
      <div class="rep-add-info">
        <Phone class="ball" /> {{ formatPhoneNumber(activeUser.phone) }}
      </div>
      <div class="rep-add-info">
        <Mail class="ball" /> {{ activeUser.email }}
      </div>
      <div class="rep-add-info">
        <Location class="ball" /> {{ activeUser.address }},
        {{ activeUser.city }} {{ activeUser.state }}, {{ activeUser.zip }}
      </div>
      <div class="document-container">
        Documents
        <div
          v-for="(document, index) of activeUser.documents"
          :key="'document-' + index"
          class="document"
          @click="open(document.url)"
        >
          <Document class="ball" /> {{ document.title }}
        </div>
      </div>
    </div>
    <div
      class="photo-pop-up-bg"
      v-if="photoPopUp === true"
      @click="photoPopUp = false"
    />
    <div class="photo-pop-up-card" v-if="photoPopUp === true">
      <div class="photo-container">
        <img class="photo" alt="" :src="userImg" />
        <label class="sub">
          <input
            type="file"
            class="photo-input"
            @change="handlePhoto($event)"
            accept=".jpg,.png"
          />
          Profile Picture
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Settings from "@/components/Common/UserSettings.vue";

import Phone from "@/assets/svg/Phone2.svg";
import Mail from "@/assets/svg/Mail2.svg";
import Document from "@/assets/svg/Document2.svg";
import Location from "@/assets/svg/DropPin.svg";
import Cog from "@/assets/svg/Cog2.svg";
import Icon from "@/assets/svg/Logo.svg";

export default {
  props: ["userId"],
  components: {
    Settings,

    Phone,
    Icon,
    Mail,
    Document,
    Location,
    Cog,
  },
  data() {
    return {
      photoPopUp: false,
      loading: false,
      editing: false,
      toText: ["One", "Two", "Three", "Four"],
      userPhotos: {},
      userImg: require("../../assets/svg/Profile2.svg?external"),
    };
  },
  computed: {
    ...mapGetters(["userConfig", "images", "level", "reps"]),
    activeUser() {
      return this.userId
        ? this.reps.filter((a) => a.id === parseInt(this.userId))[0]
        : this.userConfig;
    },
    customers() {
      return this.$store.getters.customers.filter(
        (a) => a.repId === this.activeUser.id
      );
    },
    projectCount() {
      let sum = 0;
      this.customers.forEach((customer) => {
        if (customer.projectSnapShot) {
          customer.projectSnapShot.forEach(() => {
            sum++;
          });
        }
      });
      return sum;
    },
    totalPoints() {
      let points = 0;
      this.customers.forEach((customer) => {
        if (customer.projectSnapShot) {
          points += this.calcPoints(customer.projectSnapShot);
        }
      });
      return points;
    },
  },
  methods: {
    openPhotoUpload() {
      if (this.level !== "a") return;
      this.photoPopUp = true;
    },
    async handlePhoto(e) {
      // Set photo Choice
      this.loading = true;
      const file = e.target.files[0];
      const photo = URL.createObjectURL(file);
      this.userImg = photo;
      await this.handlePhotoUpload(file);
      this.loading = false;
    },
    async handlePhotoUpload(file) {
      // Append data for send
      let formData = new FormData();
      const name = `${this.activeUser.username}userPhoto`;
      const index = file.name.lastIndexOf(".");
      formData.append("image", file, `${name}${file.name.substr(index)}`);
      await this.$http.put(`${this.$API}image/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Format data for storage local
      const url = `${this.$BUCKET}${name}${file.name.substr(index)}`;
      this.$store.commit("alterImage", {
        key: `${this.activeUser.username}userPhoto`,
        value: url,
      });
    },
    formatDate(createdAt) {
      return `${createdAt.substring(5, 7)}.${createdAt.substring(
        8,
        10
      )}.${createdAt.substring(0, 4)}`;
    },
    formatPhoneNumber(number) {
      if (number.length === 11) number = number.substring(1);
      return `(${number.substring(0, 3)}) ${number.substring(
        3,
        6
      )} - ${number.substring(6)}`;
    },
    formatName(first, last) {
      return `${first.charAt(0)}${first.substring(1)} ${last.charAt(
        0
      )}${last.substring(1)}`;
    },
    open(documentUrl) {
      window.open(documentUrl, "__blank");
    },
    nav(id) {
      const path = `${this.$route.path.substring(
        0,
        this.$route.path.length - 1
      )}h?u=${id}`;
      this.$router.push(path);
    },
    setImg() {
      const photo = this.userPhotos[`${this.activeUser.username}userPhoto`];
      this.userImg = photo
        ? photo
        : require("../../assets/svg/Profile2.svg?external");
    },
    async getUserPhotos() {
      if (!this.activeUser.photos) {
        await this.$http
          .put(`${this.$API}image/get`, {
            prefix: this.activeUser.username,
          })
          .then((res) => {
            if (res.data[0].length === 0) {
              this.$store.commit("setUserImages", {
                userId: this.activeUser.id,
                photoName: "",
                photo: "",
                context: "reps",
              });
            }
            for (const image of res.data[0]) {
              const index = image.name.lastIndexOf(".");
              const photoName = image.name.substring(0, index);
              const photo = `${this.$BUCKET}${image.name}`;
              const payload = {
                userId: this.activeUser.id,
                photoName: photoName,
                photo: photo,
                context: "reps",
              };
              this.userPhotos[photoName] = photo;
              this.$store.commit("setUserImages", payload);
            }
          });
      } else {
        this.userPhotos = this.activeUser.photos;
      }
    },
    async getDocuments() {
      const res = await this.$http.put(`${this.$API}document/get`, {
        prefix: this.activeUser.username,
      });
      const docs = [];
      const pattern = /-.*/;
      if (!res.data) return;
      for (const titles of res.data[0]) {
        let title = titles.name;
        title = title.match(pattern)[0].substring(1);
        title = title.charAt(0).toUpperCase() + title.slice(1);
        title = title.replace(".html", "");
        docs.push({ url: `${this.$DOCUMENT_BUCKET}${titles.name}`, title });
      }
      this.$store.commit("updateCustomerDocument", {
        userId: this.activeUser.id,
        documents: docs,
        context: "reps",
        isUser: !!this.userId,
      });
    },
    calcPoints(projects) {
      if (projects) {
        let sum = 0;
        for (const project of projects) {
          sum += project.bill;
        }
        return Math.round(sum / 100);
      }
      return "0";
    },
  },
  async mounted() {
    if (this.userId) await this.getUserPhotos();
    else this.userPhotos = this.images;
    if (!this.activeUser.documents) await this.getDocuments();
    this.setImg();
  },
};
</script>

<style scoped>
.wallet-container {
  background: #f3f3f3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  font-size: 0.25rem;
}
.rep-info-section,
.rep-extras {
  display: flex;
  flex-direction: column;
}
.rep-info-section {
  width: calc(82% - 6vw);
  margin: 3vh 3vw 0 3vw;
}
.rep-extras {
  width: 18%;
  margin: 3vh 0 0 0;
}
.header {
  display: flex;
  flex-direction: column;
}
.header-name {
  font-size: 0.3rem;
  font-weight: bold;
  margin-bottom: 1vh;
}
.header-money-projection {
  position: relative;
  top: -0.45rem;
  font-size: 0.45rem;
  font-weight: bold;
  margin-left: auto;
}
.latest-customers {
  background: #fff;
  border-radius: 50px;
  height: 16vh;
  overflow: hidden;
  padding: 3vh 3vw;
  box-shadow: var(--box-shadow);
}
.customer-header {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vh;
}
.all-customer-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 12vh;
  padding: 0 1vh;
}
.more {
  font-weight: 100;
  opacity: 0.4;
  font-size: 0.2rem;
  line-height: 0.3rem;
  cursor: pointer;
}
.customer-container {
  display: flex;
  height: 5vh;
  padding: 1vh 0;
  cursor: pointer;
}
.customer-container:hover .customer-divider {
  background: var(--blue-300);
}
.customer-divider {
  height: 80%;
  background: var(--blue-600);
  width: 0.3vw;
  margin: auto 0;
}
.customer-name {
  margin: auto 1vw;
}
.customer-date {
  margin: auto 0;
  opacity: 0.4;
  font-size: 0.2rem;
}
.customer-price {
  margin: auto 0 auto auto;
  font-weight: bold;
}
.reward-container {
  margin: 6vh auto 2vh;
  position: relative;
  cursor: pointer;
}
.reward-container:hover .progress-bar-outer {
  background: var(--blue-300);
}
.progress-bar-outer {
  height: 23vh;
  width: 23vh;
  background: var(--blue-600);
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 11vh);
  top: 5vh;
}
.progress-bar-inner {
  height: 20vh;
  width: 20vh;
  background: #dbdbdb;
  border-radius: 50%;
  margin: 1.5vh 1.5vh;
  position: absolute;
  left: calc(50% - 11vh);
  top: 5vh;
}
.points {
  position: absolute;
  width: 17vh;
  height: 17vh;
  background: #efefef;
  border-radius: 50%;
  line-height: 17vh;
  text-align: center;
  margin: 3vh 3vh;
  left: calc(50% - 11vh);
  font-size: 0.5rem;
  font-weight: bold;
  top: 5vh;
}
.level-container {
  display: flex;
  margin-top: auto;
  height: 3vh;
  padding: 4vh 2vw;
  background: #fff;
  border-radius: 50px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: var(--box-shadow);
}
.level {
  width: 25%;
  text-align: center;
  cursor: pointer;
}
.level:hover .divider-level {
  background: var(--blue-300);
}
.divider-level {
  width: 60%;
  height: 6px;
  margin: auto;
  margin-top: 7px;
  background: var(--blue-600);
  border-radius: 50px;
}
.rep-profile-pic {
  object-fit: cover;
  height: 15vh;
  width: 15vh;
  margin: auto;
  margin-left: 4vw;
  border-radius: 50%;
}
.profile-pic-select {
  cursor: pointer;
}
.rep-full-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.25rem;
  margin: 2vh 2vw 2vh 0;
}
.rep-add-info,
.document {
  font-size: 0.2rem;
  margin-bottom: 2vh;
  display: flex;
  cursor: pointer;
}
.rep-add-info:hover .ball,
.document:hover .ball {
  fill: var(--blue-300);
}
.rep-add-info {
  width: calc(100% - 3vw);
  overflow-wrap: anywhere;
}
.document:first-child {
  margin-top: 2vh;
}
.document-container {
  margin-top: auto;
  height: 46vh;
  padding: 2vh 1vw 0 1vw;
  margin-right: 2vw;
  background: #dbdbdb;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.ball {
  min-width: 3vh;
  height: 3vh;
  fill: var(--blue-600);
  border-radius: 50%;
  margin-right: 0.5vw;
}

.cog {
  height: 4vh;
  width: 4vh;
  opacity: 0.5;
  cursor: pointer;
}
.cog:hover {
  -webkit-animation: rotate 4s linear infinite;
  -moz-animation: rotate 4s linear infinite;
  animation: rotate 4s linear infinite;
}

/* Photo container */
.photo-pop-up-bg {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  background: #000000a8;
}
.photo-pop-up-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  width: 17vw;
  height: 43vh;
  padding: 5vh 5vw;
  background: #fff;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
}
.photo-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.photo {
  max-width: 24vw;
  max-height: 30vh;
  margin: 0;
  cursor: pointer;
}
.photo:hover ~ .sub {
  background: var(--blue-600);
  color: #fff;
}

/* Animations */
@-moz-keyframes rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

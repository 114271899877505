<template>
  <div class="search-users-container">
    <div class="toggle-item-container" v-if="displayToggle">
      <div class="toggle-slider" ref="slider" style="left: 0; width: 0;" />
      <div
        v-for="(users, type) of activeProps"
        :key="'search-' + type"
        class="toggle-item"
        :ref="type"
        :class="selected === type ? 'selected' : ''"
        @click="select(type)"
      >
        <div v-if="activeProps[type]">
          {{ type.charAt(0).toUpperCase() }}{{ type.substring(1) }}
        </div>
      </div>
    </div>
    <input
      type="text"
      class="input"
      :class="!displayToggle ? 'drop-down' : ''"
      placeholder="Search"
      v-model="search"
    />
    <div class="user-container">
      <div
        v-for="(user, index) of filteredType"
        :key="'user-' + index"
        class="user"
        @click="nav(user.id)"
      >
        <div
          class="primary"
          v-if="selected !== 'companies' && selected !== 'solar'"
        >
          {{ user.fName }} {{ user.lName }}
        </div>
        <div class="primary" v-else>{{ user.companyName }}</div>
        <div
          class="secondary"
          :class="user.blocked ? 'orange' : user.completed ? 'completed' : ''"
        >
          {{ displayUserInfo(user) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["solar", "companies", "reps", "homeowners"],
  data() {
    return {
      selected: "homeowners",
      timeout: "",
      search: "",
      categories: {
        generac: "Generator",
        hvac: "HVAC",
        pool_pumps: "Pool Pump",
        hot_water_heater: "Hot Water Heater",
        roofing: "Roof",
        insulation: "Insulation",
        tree_trimming: "Tree Trimming",
      },
    };
  },
  computed: {
    displayToggle() {
      let count = 0;
      if (this.reps) count++;
      if (this.companies) count++;
      if (this.homeowners) count++;
      return count >= 2;
    },
    selectedType() {
      return this.$props[this.selected];
    },
    filteredType() {
      if (
        (this.selected === "companies" && this.companies) ||
        (this.selected === "solar" && this.solar)
      ) {
        return this.selectedType.filter((a) =>
          a.companyName.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return this.selectedType.filter((a) =>
          `${a.fName.toLowerCase()} ${a.lName.toLowerCase()}`.includes(
            this.search.toLowerCase()
          )
        );
      }
    },
    activeProps() {
      const props = {};
      for (const prop in this.$props) {
        if (this.$props[prop]) {
          props[prop] = this.$props[prop];
        }
      }
      return props;
    },
  },
  methods: {
    select(type) {
      let userType = type;
      this.selected = type;
      if (this.$refs.slider) {
        this.$refs.slider.style.left = `${this.$refs[type][0].offsetLeft -
          2}px`;
        this.$refs.slider.style.width = `${this.$refs[type][0].offsetWidth +
          4}px`;
      }
      if (type === "homeowners") {
        userType = "customers";
      } else if (type === "solar") {
        userType = "companies";
      }
      this.$emit("adminSnapShot", userType);
    },
    displayUserInfo(user) {
      if (this.selected === "homeowners" && user.projectSnapShot.length > 0) {
        let mostRecent = user.projectSnapShot[0];
        for (const project of user.projectSnapShot) {
          if (project.scheduledAt && project.completion > mostRecent.completion)
            mostRecent = project;
        }
        if (mostRecent.blockedStatus) {
          user.blocked = true;
          return `${this.categories[mostRecent.projectName]} Blocked: ${
            mostRecent.blockedStatus
          }`;
        }
        user.blocked = false;
        return this.daysUntilInstall(mostRecent, user);
      } else if (this.selected === "reps") {
        return user.companyName;
      } else if (this.selected === "companies" || this.selected === "solar") {
        return this.numberOfReps(user.companyName);
      }
    },
    daysUntilInstall(project, user) {
      const projectText = this.categories[project.projectName];
      if (project.completion === 5) {
				user.completed = true;
				return "Installation Completed";
			}
      if (!project.scheduledAt) return `Install Pending: ${projectText}`;

      const today = new Date().getTime();
      let timeUntil = new Date(project.scheduledAt).getTime();
      timeUntil = Math.floor((timeUntil - today) / (1000 * 60 * 60 * 24));

      if (timeUntil === 0) {
        return `${projectText} Install Today`;
      }

      if (timeUntil < 0) {
        return `${projectText} is Past Due`;
      }

      let string = `${timeUntil} Day${timeUntil === 1 ? "" : "s"} Until `;
      if (project.projectName === "tree_trimming") {
        return string + "Tree Trim/Removal";
      }
      return string + `${projectText} Install`;
    },
    numberOfReps(companyName) {
      let count = 0;
      for (const rep of this.reps) {
        if (rep.companyName === companyName) count++;
      }
      return `${count} Rep${count === 1 ? "" : "s"}`;
    },
    handleResize() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.select(this.selected);
      }, 100);
    },
    nav(userId) {
      const route = `${this.$route.matched[0].path}/view/${this.selected
        .charAt(0)
        .toLowerCase()}?u=${userId}`;
      this.tearDown();
      this.$router.push(route);
    },
    setup() {
      window.addEventListener("resize", () => {
        this.handleResize();
      });
    },
    tearDown() {
      window.removeEventListener("resize", () => {
        this.handleResize();
      });
    },
    calcBill(index) {
      if (!this.homeowners) return;
      let sum = 0;
      for (const project of this.homeowners[index].projectSnapShot) {
        sum += project.bill;
      }
      return sum;
    },
  },
  created() {
    this.setup();
    setTimeout(() => {
      if (this.displayToggle) this.select("homeowners");
    }, 100);
  },
  unmounted() {
    this.tearDown();
  },
};
</script>

<style scoped>
.search-users-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  font-size: 0.25rem;
}
.toggle-item-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 2vh auto;
  border: 7px solid var(--blue-300);
  border-radius: 25px;
  cursor: pointer;
  font-size: 0.22rem;
}
.toggle-item {
  position: relative;
  z-index: 2;
  top: -1px;
  opacity: 0.5;
  font-weight: bold;
  width: fit-content;
  padding: 0.5vh 1vw;
  transition: color 0.5s ease, opacity 0.5s ease;
}
.selected {
  opacity: 1;
  color: #fff;
}
.toggle-slider {
  position: absolute;
  z-index: 1;
  border-radius: 25px;
  background: var(--blue-300);
  top: -4px;
  height: calc(0.22rem + 1vh + 14px);
  transition: all 0.5s ease;
}
.input {
  background: #fff;
  border: none;
  width: 85%;
  font-size: 0.25rem;
}
input::-webkit-input-placeholder {
  font-weight: bold;
  opacity: 0.5;
}
input:-moz-placeholder {
  font-weight: bold;
  opacity: 0.5;
}
input::-moz-placeholder {
  font-weight: bold;
  opacity: 0.5;
}
input:-ms-input-placeholder {
  font-weight: bold;
  opacity: 0.5;
}
input::placeholder {
  font-weight: bold;
  opacity: 0.5;
}
input:focus-visible {
  outline: none;
}
.drop-down {
  margin-top: 4vh;
}
.user-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 1vw;
  column-gap: 1vw;
  row-gap: 2vh;
  max-height: 66vh;
  width: 93%;
  margin-top: 2vh;
  padding: 2vh 0.6vw;
  overflow: auto;
  border-radius: 25px;
}
.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1vh;
  width: 90%;
  font-size: 0.2rem;
  text-align: center;
  margin: auto;
  height: 15vh;
  max-height: 15vh;
  text-overflow: ellipsis;
  padding: 1vh 0;
  border-radius: 15px;
  box-shadow: var(--box-shadow-light);
  background: #fff;
  cursor: pointer;
  transition: width 0.4s ease, height 0.4s ease;
}
.user:hover {
  width: 100%;
  height: 16vh;
  max-height: 16vh;
}
.primary {
  font-weight: bold;
  text-align: center;
}
.secondary {
  width: 85%;
  color: var(--blue-100);
  text-align: center;
}
.orange {
  color: #ff8c00;
}
.completed {
	color: green;
}
</style>

<template>
  <div class="quotes-container">
    <div v-if="displayQuote === false">
      <div class="user-information-container">
        <div>{{ user.fName }} {{ user.lName }}</div>
        <div>{{ formatPhoneNumber(user.phone) }}</div>
        <div>{{ user.email }}</div>
        <div>{{ user.address }}, {{ user.city }} {{ user.state }}</div>
      </div>
      <div class="projects">
        <div
          class="projects-container"
          v-for="project of user.projects"
          :key="project.title"
        >
          <div
            class="button"
            @click="toggleProject(project)"
            :class="activeDisplay.includes(project.tableName) ? 'active' : ''"
          >
            {{ project.title }}
          </div>
          <input
            type="number"
            class="input quotes-input"
            :class="activeDisplay.includes(project.tableName) ? 'show' : ''"
						@input="updatePrice(project.tableName, $event)"
            v-model="prices[project.tableName.toLowerCase().replace(/ /g, '')]"
          />
        </div>
      </div>
      <div class="button-options">
        <div class="button" @click="$router.back()">Back</div>
        <div class="button inactive" v-if="activeDisplay.length === 0">
          Display Quote
        </div>
        <div class="button" @click="displayQuote = true" v-else>
          Display Quote
        </div>
      </div>
    </div>
		<Quote :userId="userId" :items="active" v-else />
  </div>
</template>

<script>
import Quote from "@/views/Common/Quote.vue";

export default {
  props: ["userId"],
	components: {
		Quote,
	},
  data() {
    return {
      active: [],
      activeDisplay: [],
      displayQuote: false,
      prices: {
        roofing: 0,
        tree_trimming: 0,
        generac: 0,
        insulation: 0,
        hot_water_heater: 0,
        hvac: 0,
        pool_pumps: 0,
      },
    };
  },
	computed: {
		customers() {
			return this.$store.getters.customers;
		},
		user() {
			return this.customers.filter(a => a.id === parseInt(this.userId))[0];
		}
	},
  methods: {
		updatePrice(table, e) {
			const project = this.active.filter(a => a.project === table)[0];
			if (project) {
				project.bill = e.target.value;
			}
		},
    formatPhoneNumber(number) {
      if (number.length === 11) number = number.substring(1);
      return `(${number.substring(0, 3)}) ${number.substring(
        3,
        6
      )} - ${number.substring(6)}`;
    },
    toggleProject(project) {
      if (this.activeDisplay.indexOf(project.tableName) !== -1) {
        this.activeDisplay = this.activeDisplay.filter(a => a !== project.tableName);
        this.active = this.active.filter(a => a.project !== project.tableName);
      } else {
        this.activeDisplay.push(project.tableName);
        this.active.push({project: project.tableName, projectInfo: project, bill: this.prices[project.tableName]}); 
      }
    },
  },
};
</script>

<style scoped>
.quotes-container {
  width: 100%;
  background: #d4d4d4;
  height: 91vh;
  overflow: auto;
  position: relative;
  font-size: 0.3rem;
}
.user-information-container {
  float: left;
  margin: -5vh 3vw;
}
.projects {
  width: fit-content;
  margin: 10vh auto -10vh;
}
.projects-container {
  display: flex;
  height: fit-content;
  width: 31vw;
  align-items: center;
  margin-bottom: 1vh;
}
.button {
  font-size: 0.2rem;
  width: 60%;
  padding: 0 1vw;
}
.button-options {
  display: flex;
  width: 30vw;
  margin: 30vh auto 0;
}
.button-options .button {
  margin-right: 5%;
  width: 90%;
}
.quotes-input {
  margin-left: 20px;
}
</style>

<template>
  <div class="map-container">
    <GmapMap
      class="google-maps"
      :zoom="zoom"
      :center="mapCenter"
      map-type-id="roadmap"
      :options="mapOptions"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :icon="{
          url: require('../../assets/images/DropPin.png'),
          scaledSize: { width: 30, height: 45, f: 'px', b: 'px' },
        }"
        :offsetX="50"
        :offsetY="50"
        @click="mapCenter = m.position"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: ["homeowners"],
  data() {
    return {
      mapCenter: {
        lat: 28.538336,
        lng: -81.379234,
      },
      mapOptions: {},
      zoom: 12,
      markers: [],
      places: [],
    };
  },
  methods: {
    async addMarker() {
      for (const homeowner of this.homeowners) {
        const location = await this.getLocation(homeowner);
        if (location) {
          const marker = {
            lat: location.lat,
            lng: location.lng,
          };
          this.markers.push({ position: marker });
        }
      }
    },
    formatLocation(customer) {
      return `${customer.address.replace(" ", "+")},+${customer.city.replace(
        " ",
        "+"
      )},+${customer.state}`;
    },
    async getLocation(customer) {
      if (customer.lat && customer.lng) return {lat: customer.lat, lng: customer.lng};
      const googleLocation = this.formatLocation(customer);
      let location;
      location = await this.$https.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${googleLocation}&key=${process.env.VUE_APP_GOOGLE_MAPS_API}`
      );
      if (location.data.results.length > 0) {
        location = location.data.results[0].geometry.location;
        await this.$http.put(`${this.$API}user/${customer.id}/location`, {
          location: location
        });
        this.$store.commit("setCustomerLocation", {
          userId: customer.id,
          location: location,
        });
        return location;
      } else {
        return null;
      }
    },
  },
  async mounted() {
    if (this.homeowners) {
      setTimeout(async () => {
        await this.addMarker();
        if (this.markers.length > 0) {
          this.mapCenter = this.markers[0].position;
        }
      }, 500);
    }
  },
};
</script>

<style scoped>
.map-container {
  height: 100%;
  width: 100%;
  font-size: 0.25rem;
}
.google-maps {
  height: 100%;
  width: 100%;
}
</style>

<template>
  <div class="email-choice-container">
    <div class="option">
      <div class="header">Rep Info</div>
      <div class="field-container">
        <input
          type="text"
          placeholder="First Name"
          v-model="repFName"
          class="input"
        />
        <input
          type="text"
          placeholder="Last Name"
          v-model="repLName"
          class="input"
        />
        <input
          type="email"
          placeholder="Email"
          v-model="repEmail"
          class="input"
        />
        <input
          type="tel"
          placeholder="Phone Number"
          v-model="repPhoneNumber"
          class="input"
          @input="phoneNumberFormatter('rep')"
        />
      </div>
    </div>
    <div class="option">
      <div class="header">Sales Company Info</div>
      <div class="field-container">
        <input
          type="text"
          placeholder="Company Name"
          v-model="companyName"
          class="input"
        />
        <input
          type="text"
          placeholder="Contact Name"
          v-model="companyContactName"
          class="input"
        />
        <input
          type="email"
          placeholder="Email"
          v-model="companyEmail"
          class="input"
        />
        <input
          type="tel"
          placeholder="Phone Number"
          v-model="companyPhoneNumber"
          class="input"
          @input="phoneNumberFormatter('company')"
        />
      </div>
    </div>
    <div class="option">
      <div class="header">Solar Install Info</div>
      <div class="field-container">
        <input
          type="text"
          placeholder="Company Name"
          v-model="solarName"
          class="input"
        />
        <input
          type="text"
          placeholder="Contact Name"
          v-model="solarContactName"
          class="input"
        />
        <input
          type="email"
          placeholder="Email"
          v-model="solarEmail"
          class="input"
        />
        <input
          type="tel"
          placeholder="Phone Number"
          v-model="solarPhoneNumber"
          class="input"
          @input="phoneNumberFormatter('solar')"
        />
      </div>
    </div>
    <div class="footer">
      <Notification class="notice-icon" />
      <div class="footer-text">
        All above parties will receive either SMS or Email Notifications on this
        project.
      </div>
      <div class="error">{{ errorMessage }}</div>
      <div class="button" @click="$emit('back')">Previous</div>
      <div class="button" @click="next">Submit</div>
    </div>
  </div>
</template>

<script>
import Notification from "@/assets/svg/Unread.svg";

export default {
  props: ["emailInfo"],
  components: {
    Notification,
  },
  data() {
    return {
      repFName: "",
      repLName: "",
      repEmail: "",
      repPhoneNumber: "",
      companyName: "",
      companyContactName: "",
      companyEmail: "",
      companyPhoneNumber: "",
      solarName: "",
      solarContactName: "",
      solarEmail: "",
      solarPhoneNumber: "",
      errorMessage: "",
    };
  },
  computed: {
    payload() {
      let payload = this.$data;
      delete payload.errorMessage;
      for (const key in payload) {
        if (!payload[key]) delete payload[key];
        if (/PhoneNumber/.test(key) && payload[key])
          payload[key] = payload[key].replace(/-|\(|\)|\s/g, "");
      }
      return payload;
    },
  },
  methods: {
    next() {
      if (this.checkRep() || this.checkCompany() || this.checkSolar()) {
        this.$emit(
          "confirmationMessage",
          "User(s) have been successfully subscribed!"
        );
        this.$emit("submit", this.payload);
      } else this.errorMessage = "Please fill in at least one section";
    },
    checkRep() {
      return (
        this.repFName && this.repLName && this.repEmail && this.repPhoneNumber
      );
    },
    checkCompany() {
      return (
        this.companyName &&
        this.companyContactName &&
        this.companyEmail &&
        this.companyPhoneNumber
      );
    },
    checkSolar() {
      return (
        this.solarName &&
        this.solarContactName &&
        this.solarEmail &&
        this.solarPhoneNumber
      );
    },
    prefill() {
      if (this.emailInfo) {
        for (const key in this.emailInfo) {
          this[key] = this.emailInfo[key];
        }
      }
    },
    phoneNumberFormatter(type) {
      this[`${type}PhoneNumber`] = this.formatPhoneNumber(
        this[`${type}PhoneNumber`]
      );
    },
    formatPhoneNumber(value) {
      if (!value) return value;
      const phoneNumber = value.replace(/[^\d]/g, "");
      const phoneNumberLength = phoneNumber.length;
      if (phoneNumberLength < 4) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )} - ${phoneNumber.slice(6, 10)}`;
    },
  },
  mounted() {
    this.prefill();
  },
};
</script>

<style scoped>
.email-choice-container {
  font-size: 0.25rem;
  row-gap: 2vh;
}
.option {
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
}
.header {
  font-weight: bold;
}
.field-container {
  display: grid;
  grid-template-areas:
    "a b"
    "c d";
  column-gap: 1vw;
  row-gap: 1vh;
}
.input {
  width: stretch;
}
.footer {
  height: 10vh;
  display: flex;
  align-items: center;
  font-size: 0.25rem;
}
.notice-icon {
  height: 3vh;
  width: 3vh;
  background: var(--blue-300);
  border-radius: 50%;
  cursor: help;
}
.notice-icon:hover ~ .footer-text {
  font-size: 0.21rem;
  width: 31vw;
}
.footer-text {
  width: 30vw;
  font-size: 0.2rem;
  font-weight: bold;
  opacity: 0.7;
  margin: 0 auto 0 1vw;
  transition: font-size 0.2s ease, width 0.2s ease;
  cursor: help;
}
.footer-text:hover {
  font-size: 0.21rem;
  width: 31vw;
}
.button {
  width: 12vw;
  margin-left: 1vw;
}
.error {
  color: var(--error-color);
  margin: auto;
}
</style>

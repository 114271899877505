<template>
  <div class="analytic-container">
    <div class="header">
      <div>Project Data</div>
      <div>visit page >></div>
    </div>
    <div class="chart-container">
      <Chart :options="chartOptions" class="chart" />
      <div class="box" />
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  props: ["chartData", "context"],
  components: {
    Chart: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
          labels: {
            autoRotation: undefined,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Total Projects",
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: [],
        colors: [
          "#29aae3",
          "#ffe55c",
          "#5155ad",
          "#fef2b3",
          "#8185e2",
          "#3871e0",
        ],
      },
    };
  },
  methods: {
    sliceData() {
      this.chartOptions.xAxis.categories = this.chartData.categories;
      let series = this.chartData.series;
      series = series
        .sort(
          (a, b) =>
            a.data.reduce((c, d) => c + d) - b.data.reduce((c, d) => c + d)
        )
        .slice(series.length - 6 > 0 ? series.length - 6 : 0);
      this.chartOptions.series = series;
    },
  },
  mounted() {
    setTimeout(() => {
      this.sliceData;
    }, 500);
  },
  watch: {
    context: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.chartOptions.xAxis.categories = this.chartData.categories;
          this.chartOptions.series = this.chartData.series;
          this.sliceData();
        }, 500);
      },
    },
  },
};
</script>

<style scoped>
.analytic-container {
  height: 100%;
  width: 100%;
  font-size: 0.25rem;
  overflow: auto;
}
.header {
  display: flex;
  justify-content: space-between;
  margin: 2vh 0;
  opacity: 0.5;
  font-weight: bold;
}
.chart-container {
  display: flex;
  position: relative;
  width: calc(100% - 2vw);
  height: calc(100% - 15vh);
  padding: 2vh 1vw;
  background: #fff;
  border-radius: 25px;
  box-shadow: var(--box-shadow-light);
}
.chart {
  height: 100%;
  margin: auto;
}
.box {
  position: absolute;
  bottom: 1vh;
  right: 1vw;
  width: calc(100% - 2vw);
  height: 3vh;
  z-index: 2;
  background: #fff;
}
</style>

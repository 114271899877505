<template>
  <div class="customer-info-container">
    <div class="loading" v-if="loading"><Icon class="loading-icon" />"</div>
    <Settings
      v-if="editing"
      @exit="editing = false"
      :user="user"
      userType="user"
    />
    <PhotoCarousel
      :userPhotos="userPhotos"
      @closePhoto="photoPopUp = false"
      v-if="photoPopUp"
    />
    <div class="project-timelines" v-if="!loading && projects.length > 0">
      <ProjectCarousel
        :projects="projects"
				@refreshProjects="projects = user.projects"
        @projectSelection="selectProject"
        :userId="userId"
      />
      <div class="project-note-container">
        <div class="project-note-left">
          <ProjectInfo
            :selectedProject="selectedProject"
            :homeId="user.homeId"
          />
        </div>
        <div class="project-note-right">
          <Notes :selectedProject="selectedProject" />
          <SettingsAndService
            :user="user"
            :selectedProject="selectedProject"
            @showProject="showProject"
          />
        </div>
      </div>
    </div>
    <div class="no-customer-project" v-if="projects.length === 0 && !loading">
      No projects currently on record, please contact and set up an appointment
    </div>
    <div class="customer-information-container" v-if="!loading">
      <UserInfo
        :user="user"
        :userPhotos="userPhotos"
        :rep="rep"
        :selectedProject="selectedProject"
        @editing="editing = true"
      />
      <Documents :user="user" @open="open" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Notes from "@/components/Common/ViewCustomer/Notes.vue";
import SettingsAndService from "@/components/Common/ViewCustomer/SettingsAndService.vue";
import ProjectCarousel from "@/components/Common/ViewCustomer/ProjectCarousel.vue";
import PhotoCarousel from "@/components/Common/ViewCustomer/PhotoCarousel.vue";
import UserInfo from "@/components/Common/ViewCustomer/UserInfo.vue";
import ProjectInfo from "@/components/Common/ViewCustomer/ProjectInfo.vue";
import Documents from "@/components/Common/ViewCustomer/Documents.vue";
import Settings from "@/components/Common/UserSettings.vue";

import Icon from "@/assets/svg/Logo.svg";

export default {
  components: {
    Notes,
    ProjectCarousel,
    PhotoCarousel,
    SettingsAndService,
    UserInfo,
    ProjectInfo,
    Documents,

    Settings,
    Icon,
  },
  props: ["userId"],
  data() {
    return {
      loading: false,
      projects: [],
      userPhotos: {},
      selection: 0,
      // Photo carousel
      photoPopUp: false,
      // Solar
      solarInstallDate: "Pending",
      // Settings pop up
      editing: false,
    };
  },
  computed: {
    ...mapGetters(["level", "images", "userConfig", "customers"]),
    user() {
      if (this.userId)
        return this.customers.filter((a) => a.id === parseInt(this.userId))[0];
      return this.userConfig;
    },
    rep() {
      if (this.user.repId === 0) {
        return {
          fName: "Admin",
          lName: "User",
          email: "NA",
          phone: "0000000000",
          companyName: "Home Up",
        };
      }
      if (/[ac]/.test(this.level)) {
        return this.$store.getters.reps.filter(
          (a) => a.id === this.user.repId
        )[0];
      } else if (this.level === "r") {
        return this.userConfig;
      } else {
        return {
          fName: this.user.repFName,
          lName: this.user.repLName,
          email: this.user.repEmail,
          phone: this.user.repPhone,
          companyName: this.user.companyName,
        };
      }
    },
    selectedProject() {
      return this.projects[this.selection];
    },
  },
  methods: {
    selectProject(index) {
      this.selection = index;
    },
    formatDate(date) {
      date = new Date(date + "z");
      const time = date.getHours();
      return `${date.getMonth() + 1}-${date.getDate()} at ${
        time > 12 ? time - 12 : time
      }${time > 11 ? "pm" : "am"} to ${time > 10 ? time - 10 : time}${
        time > 9 ? "pm" : "am"
      }`;
    },
    open(docUrl) {
      if (docUrl === "photos") {
        this.photoPopUp = true;
      } else {
        window.open(docUrl, "_blank");
      }
    },
    async getQuotes(isHomeowner) {
      if (!this.user.quotes) {
        const quotes = (
          await this.$http.get(`${this.$API}user/${this.user.id}/quote`)
        ).data;
        this.$store.commit("setQuotes", {
          quotes,
          isHomeowner,
          userId: this.user.id,
        });
      }
    },
    async getSolar(isHomeowner) {
      if (!this.user.solarInstallDate) {
        const date = await this.$http.get(
          `${this.$API}user/${this.user.id}/solar`
        );
        if (date.data[0].scheduledAt) {
          const setDate = date.data[0].scheduledAt;
          this.solarInstallDate = this.formatDate(setDate);
          this.$store.commit(
            `${isHomeowner ? "updateUserConfig" : "setSolarInstall"}`,
            {
              userId: this.user.id,
              solarInstallDate: setDate,
            }
          );
        } else {
          this.$store.commit(
            `${isHomeowner ? "updateUserConfig" : "setSolarInstall"}`,
            {
              userId: this.user.id,
              solarInstallDate: "NA",
            }
          );
        }
      } else {
        if (this.user.solarInstallDate !== "NA")
          this.solarInstallDate = this.formatDate(this.user.solarInstallDate);
      }
    },
    async getProject(isHomeowner) {
      if (!this.user.projects) {
        const projects = await this.$http.get(
          `${this.$API}user/${this.user.id}/projects`
        );
        if (projects.data) {
          this.projects = projects.data;
          for (const project of this.projects) {
            const payload = { project: project, userId: this.user.id };
            if (!isHomeowner) {
              this.$store.commit("setUserProjects", payload);
            }
          }
          if (isHomeowner)
            this.$store.commit("updateUserConfig", {
              projects: this.projects,
            });
        }
      } else {
        this.projects = this.user.projects;
      }
    },
    async getUserInfo() {
      // Solar date info
      const isHomeowner = this.level === "u";
      await this.getSolar(isHomeowner);
      // Project Info
      await this.getProject(isHomeowner);
      // Quote Info
      await this.getQuotes(isHomeowner);
    },
    async getUserPhotos() {
      if (!this.user.photos && this.level !== "u") {
        await this.$http
          .put(`${this.$API}image/get`, {
            prefix: this.user.username,
          })
          .then((res) => {
            if (!res.data || res.data[0].length === 0) {
              this.$store.commit("setUserImages", {
                userId: this.user.id,
                photoName: "",
                photo: "",
                context: "customers",
              });
              return;
            }
            for (const image of res.data[0]) {
              const photoName = image.name.substring(0, image.name.length - 4);
              const photo = `${this.$BUCKET}${image.name}`;
              const payload = {
                userId: this.user.id,
                photoName: photoName,
                photo: photo,
                context: "customers",
              };
              this.userPhotos[photoName] = photo;
              this.$store.commit("setUserImages", payload);
            }
          });
      } else {
        this.userPhotos = this.level === "u" ? this.images : this.user.photos;
      }
    },
    async getDocuments() {
      const res = await this.$http.put(`${this.$API}document/get`, {
        prefix: this.user.username,
      });
      const docs = [];
      const pattern = /-.*/;
      if (!res.data) return;
      for (const titles of res.data[0]) {
        let title = titles.name;
        title = title.match(pattern)[0].substring(1);
        title = title.charAt(0).toUpperCase() + title.slice(1);
        title = title.replace(".html", "");
        docs.push({ url: `${this.$DOCUMENT_BUCKET}${titles.name}`, title });
      }
      this.$store.commit("updateCustomerDocument", {
        userId: this.user.id,
        documents: docs,
        context: "customers",
        isUser: !!this.userId,
      });
    },
    showProject() {
      this.$emit("showProject", this.selection);
    },
  },
  async mounted() {
    this.loading = true;
    await this.getUserInfo();
    await this.getUserPhotos();
    if (!this.user.documents) await this.getDocuments();
    this.loading = false;
  },
};
</script>

<style scoped>
.customer-info-container {
  width: 100%;
  background: #d4d4d4;
  height: 91vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  font-size: 0.3rem;
}
.project-timelines,
.no-customer-project {
  width: calc(66% - 8vw);
  height: calc(100% - 8vh);
  margin: 4vh 4vw;
  display: flex;
  flex-direction: column;
}
.no-customer-project {
  justify-content: center;
}
.search-bar-container {
  display: flex;
  width: 100%;
}
.right {
  margin-left: auto;
  width: 30%;
}
.project-note-container {
  font-size: 0.75em;
  width: 100%;
  height: 50vh;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}
.project-note-left {
  width: 35%;
  height: 100%;
}
.project-note-right {
  width: 60%;
  height: 100%;
}
.customer-information-container {
  width: calc(34% - 4vw);
  height: calc(100% - 4vh);
  margin: 4vh 4vw 0 0;
  display: flex;
  flex-direction: column;
}
.button {
  width: 100%;
  margin-top: 1vh;
}
/* Datepicker css */
.datepicker-container {
  width: 9vw;
}
</style>

<template>
  <div id="app">
    <div class="session-timeout" v-if="sessionTimeout">
      <div class="session-timeout-card">
        <div>Session Expiration</div>
        <div class="session-timeout-sub">
          Your session has ended, please log in again to continue using our
          services
        </div>
        <div class="button session-timeout-button" @click="logout">Back</div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      sessionTimeout: false,
    };
  },
  computed: {
    level() {
      return this.$store.state.level;
    },
  },
  methods: {
    async refreshToken() {
      if (!this.level) return;
      const token = (
        await this.$http.put(`${this.$API}login/refresh`, {
          token: this.$cookies.get("refresh"),
        })
      ).data;
      if (!token) {
        this.sessionTimeout = true;
        return;
      } else {
        this.$cookies.set("access", token.accessToken);
      }
    },
    logout() {
      this.$store.commit("logout");
      this.$router.push("/");
      this.sessionTimeout = false;
    },
  },
  async mounted() {
    setInterval(async () => {
      await this.refreshToken();
    }, 8 * 60 * 1000);
  },
};
</script>

<style>
/* Session timeout */
.session-timeout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
}
.session-timeout-card {
  position: absolute;
  height: 35vh;
  width: 50vw;
  left: 23vw;
  top: 25.5vh;
  padding: 4vh 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #fff;
  border-radius: 25px;
  font-size: 0.3rem;
}
.session-timeout-sub {
	font-size: .25rem;
	text-align: center;
	width: 80%;
	margin: auto;
}
.session-timeout-button {
	margin: 0 auto;
}

/* Global Styles */
@font-face {
  font-family: Aileron-bold;
  src: url("./assets/font/Aileron-Bold.otf");
}
@font-face {
  font-family: Aileron-semi-bold;
  src: url("./assets/font/Aileron-SemiBold.otf");
}
@font-face {
  font-family: Aileron-reg;
  src: url("./assets/font/Aileron-Regular.otf");
}
* {
  font-family: Aileron-reg;
}
html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: Aileron-reg;
  /* min font +  max-min font * (current - min view / max-min possible view) */
  font-size: calc(40px + (80 - 40) * ((100vw - 300px) / (1600 - 300)));
}
::placeholder {
  font-family: Aileron-reg;
}
#app {
  height: 100vh;
}
</style>

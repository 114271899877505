<template>
  <div class="filter-container">
    <input
      type="text"
      class="input search-bar"
      placeholder="Search"
      v-model="search"
    />
    <div class="left">
      <div class="card">
        <div class="milestone">
          <div class="title">Milestone</div>
          <div
            class="stages"
            v-for="milestone of milestones"
            :key="milestone.title"
            @click="toggle('milestone', milestone.key)"
          >
            <div
              class="check"
              :class="milestoneFilter.includes(milestone.key) ? 'checked' : ''"
            />
            <div class="text">{{ milestone.title }}</div>
          </div>
        </div>
        <div class="job">
          <div class="title">Job Priority</div>
          <!-- <div class="stages" v-for="job of jobs" :key="job" @click="toggle('job', job)">
						<div class="check" :class="jobFilter.includes(job.toLowerCase()) ? 'checked' : ''"/><div class="text">{{job}}</div>
					</div> -->
          <div class="text">Incoming Soon</div>
        </div>
        <div class="project">
          <div class="title">Project Type</div>
          <div
            class="stages"
            v-for="project of projectTypes"
            :key="project.title"
            @click="toggle('project', project.key)"
          >
            <div
              class="check"
              :class="projectFilter.includes(project.key) ? 'checked' : ''"
            />
            <div class="text">{{ project.title }}</div>
          </div>
        </div>
        <div class="location">
          <div class="title">Location</div>
          <div
            class="stages"
            v-for="location of locations"
            :key="location"
            @click="toggle('location', location)"
          >
            <div
              class="check"
              :class="
                locationFilter.includes(location.toLowerCase()) ? 'checked' : ''
              "
            />
            <div class="text">{{ location }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <UserContainer :users="homeowners" :search="search" @nav="nav" />
    </div>
  </div>
</template>

<script>
import UserContainer from "@/components/Common/UserContainer.vue";

export default {
  props: ["navFilter"],
  components: {
    UserContainer,
  },
  data() {
    return {
      search: "",
      milestoneFilter: [],
      jobFilter: [],
      projectFilter: [],
      locationFilter: [],
      milestones: [
        { title: "New Project", key: 0 },
        { title: "Digital Site Survey", key: 1 },
        { title: "Permitting", key: 2 },
        { title: "Materials Ordered", key: 3 },
        { title: "Service Completed", key: 4 },
        { title: "Invoiced", key: 5 },
        // { title: "Invoiced", key: 5 },
        // { title: "Closed", key: 6 },
        // { title: "Canceled", key: 7 },
      ],
      jobs: ["Urgent", "Normal"],
      projectTypes: [
        { title: "Roofing", key: "roofing" },
        { title: "Tree Trimming", key: "tree_trimming" },
        { title: "HVAC", key: "hvac" },
        { title: "Insulation", key: "insulation" },
        { title: "Hot Water Heater", key: "hot_water_heater" },
        { title: "Pool Pump", key: "pool_pumps" },
        { title: "Generator", key: "generac" },
      ],
      locations: [],
    };
  },
  computed: {
    homeowners() {
      return this.filter(this.$store.getters.customers);
    },
  },
  methods: {
    getLocations() {
      const locations = [];
      for (const homeowner of this.homeowners) {
        const city = homeowner.city
          .split(" ")
          .map((a) => a.charAt(0).toUpperCase() + a.slice(1))
          .join(" ");
        const location = `${city} ${homeowner.state}`;
        if (locations.indexOf(location) === -1) {
          locations.push(location);
        }
      }
      return locations;
    },
    toggle(filterType, item) {
      const itemType = `${filterType}Filter`;
      if (typeof item === "number") {
        this.toggleNumber(itemType, item);
      } else {
        this.toggleString(itemType, item);
      }
    },
    toggleNumber(itemType, item) {
      if (this[itemType].includes(item)) {
        this[itemType] = this[itemType].filter((a) => a !== item);
      } else {
        this[itemType].push(item);
      }
    },
    toggleString(itemType, item) {
      if (this[itemType].includes(item.toLowerCase())) {
        this[itemType] = this[itemType].filter((a) => a !== item.toLowerCase());
      } else {
        this[itemType].push(item.toLowerCase());
      }
    },
    filter(homeowners) {
      return this.filterProjectType(
        this.filterMilestone(this.filterLocation(homeowners))
      );
    },
    filterProjectType(homeowners) {
      if (this.projectFilter.length === 0) return homeowners;
      const filtered = [];
      for (const homeowner of homeowners) {
        let toPush = false;
        homeowner.projectSnapShot.forEach((project) => {
          if (this.projectFilter.indexOf(project.projectName) !== -1)
            toPush = true;
        });
        if (toPush) filtered.push(homeowner);
      }
      return filtered;
    },
    filterMilestone(homeowners) {
      if (this.milestoneFilter.length === 0) return homeowners;
      const filtered = [];
      for (const homeowner of homeowners) {
        let toPush = false;
        homeowner.projectSnapShot.forEach((project) => {
          if (this.milestoneFilter.indexOf(project.completion) !== -1)
            toPush = true;
        });
        if (toPush) filtered.push(homeowner);
      }
      return filtered;
    },
    filterLocation(homeowners) {
      if (this.locationFilter.length === 0) return homeowners;
      const filtered = [];
      for (const homeowner of homeowners) {
        const homeownerlocation = `${homeowner.city.toLowerCase()} ${homeowner.state.toLowerCase()}`;
        let toPush = false;
        for (const location of this.locationFilter) {
          if (location === homeownerlocation) toPush = true;
        }
        if (toPush) filtered.push(homeowner);
      }
      return filtered;
    },
    filterFromNav() {
      this.toggle('milestone', parseInt(this.navFilter));
    },
    nav(userId) {
      this.$emit("customerPage", { userType: "customers", number: userId });
    },
  },
  mounted() {
    this.locations = this.getLocations();
    if (this.navFilter) this.filterFromNav();
  },
};
</script>

<style scoped>
.filter-container {
  position: relative;
  font-size: 0.24rem;
  display: flex;
  column-gap: 1vw;
  justify-content: center;
  width: 100vw;
  height: 91vh;
  background: #ededed;
}
.search-bar {
  position: absolute;
  left: 5%;
  top: 2.5vh;
  width: 90%;
  background: #fff;
  border: none;
  color: #000;
  opacity: 0.7;
  font-size: 0.25rem;
}
.left {
  margin-top: 10vh;
  width: 30vw;
}
.card {
  background: #fff;
  padding: 4vh 2vw;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  display: grid;
  grid-template-areas:
    "milestone job"
    "project location";
  column-gap: 2vw;
  row-gap: 1vh;
  height: calc(100% - 8vh);
}
.milestone,
.job,
.project,
.location {
  display: flex;
  flex-direction: column;
  height: fit-content;
  row-gap: 1vh;
  max-height: 40vh;
  overflow: auto;
}
.milestone {
  grid-area: milestone;
}
.job {
  grid-area: job;
}
.project {
  grid-area: project;
}
.location {
  grid-area: location;
}
.title {
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  padding: 0.5vh 0;
  font-size: 0.25rem;
  font-weight: bold;
  color: #000;
  background: #fff;
}
.stages {
  display: flex;
  column-gap: 0.5vw;
  align-items: center;
}
.check {
  width: 2vh;
  height: 2vh;
  border: 3px solid var(--blue-300);
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
}
.checked {
  background: var(--blue-300);
}
.text {
  opacity: 0.7;
}
.right {
  margin-top: 10vh;
  width: 60vw;
}
</style>

<template>
  <div class="header-container">
    <Arrow
      class="arrow"
      :class="open ? 'arrow-up' : 'arrow-down'"
      @click="openStages"
    />
    <Navbar
      :class="open ? '' : 'nav-hide'"
      :open="open"
      @nav="nav"
      @closeNav="open = false"
      :navItems="navItems"
    />
    <Logo class="hu-logo" @click="nav('landing')" />
    <Bell
      class="user-container"
      :notifications="notifications"
      :openNav="openNav"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Common/Header/Navbar.vue";
import Bell from "@/components/Common/Notifications/Notification.vue";

import Arrow from "@/assets/svg/RightArrow.svg";
import Logo from "@/assets/svg/Logo.svg";

export default {
  components: {
    Bell,
    Arrow,
    Logo,
    Navbar,
  },
  props: ["navItems", "openNav"],
  data() {
    return {
      open: false,
    };
  },
  computed: {
    notifications() {
      return this.$store.getters.notifications;
    },
    route() {
      return this.$route.matched[0].path;
    },
  },
  methods: {
    nav(location) {
      if (location === "logout") {
        this.$store.commit("logout");
        this.$router.push("/");
				return;
      }
      const route = `${this.route}/${location}`;
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
      this.open = 0;
    },
    openStages() {
      this.open = !this.open;
    },
  },
  watch: {
    openNav: {
      immediate: true,
      handler() {
        if (this.openNav === false) {
          this.open = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.header-container {
  position: relative;
  width: 96vw;
  padding: 0 2vw;
  height: 9vh;
  background: var(--blue-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.arrow,
.hu-logo,
.user-img {
  height: 3.75vh;
  width: auto;
  margin: auto 0;
  cursor: pointer;
  transition: 0.25s ease all;
}
.hu-logo {
  height: 5vh;
}

.hu-logo:hover {
  fill: #fff;
}
.arrow-down {
  transform: rotate(90deg);
}
.arrow-right {
  transform: rotate(0deg);
}
.arrow-up {
  transform: rotate(-90deg);
}
.arrow:hover {
  stroke: #fff;
}
.nav-container {
  position: absolute;
  left: 0vw;
  top: 9vh;
  z-index: 99;
  font-size: 0.25rem;
  height: 86vh;
  width: 13vw;
  background: #ededed;
  display: flex;
  flex-direction: column;
  padding-top: 5vh;
  overflow: hidden;
  transition: all 0.5s ease;
}
.nav-hide {
  height: 0;
  margin: 0;
  padding: 0;
}
.selectable {
  cursor: pointer;
  height: 6vh;
  line-height: 6vh;
  text-align: center;
  width: 80%;
  white-space: nowrap;
  margin: 0 auto;
  margin-bottom: 1vh;
  border-bottom: 1px solid black;
}
.selectable:hover {
  color: var(--blue-300);
  border-bottom-color: var(--blue-300);
}
.user-container {
  height: 9vh;
  display: flex;
  justify-content: space-evenly;
}
.user-name {
  margin-left: 5px;
  font-size: 0.3rem;
  line-height: 10vh;
  font-weight: bold;
}
.img-growth {
  height: 5.5vh;
  margin-top: 2.25vh;
  margin-left: -0.5vh;
}
.add-new {
  height: 3.75vh;
  width: auto;
  margin: auto 0;
  transition: all 0.5s ease;
}
.fill {
  fill: #000;
}
.rotate {
  transform: rotate(45deg);
}
.user-container:hover .fill {
  cursor: pointer;
  fill: white;
}
.sudo-element {
  width: 2vw;
}
</style>

<template>
  <div>
    <div class="loading" v-if="loading">
      <Logo class="loading-icon" />
    </div>
    <Header :navItems="navItems" :openNav="openNav" />
    <div @click="closeNav" class="bg">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
// Components
import Header from "@/components/Common/Header/Header.vue";
import Logo from "@/assets/svg/Logo.svg";

// Icons
import HomeIcon from "@/assets/svg/Star.svg";
import Logout from "@/assets/svg/Logout4.svg";

export default {
  props: ["nav"],
  components: {
    Header,
    Logo,
  },
  data() {
    return {
      loading: false,
      openNav: true,
      navItems: [
        {
          copy: "Dashboard",
          link: "landing",
          icon: HomeIcon,
        },
        {
          copy: "Logout",
          link: "logout",
          icon: Logout,
        },
      ],
    };
  },
  methods: {
    closeNav() {
      this.openNav = false;
      setTimeout(() => {
        this.openNav = true;
      }, 500);
    },
  },
  computed: {
    userConfig() {
      return this.$store.getters.userConfig;
    },
  },
  mounted() {
    if (!this.userConfig.id) this.$router.push("/");
    if (this.nav) this.$router.push(this.nav);
  },
};
</script>
.<style scoped>
.bg {
  min-height: 91vh;
  background: #ededed;
}
</style>

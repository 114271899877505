<template>
  <div
    class="settings-card-container"
    :class="$route.matched[0].path === '/r' ? 'settings-card-rep' : ''"
  >
    <div class="loading" v-if="loading">
      <Logo class="loading-icon" />
    </div>
    <div class="settings-page-one" v-if="!showPass">
      <div class="settings-header">Account Settings:</div>
      <div class="settings-photo-container">
        <img
          class="settings-photo"
          :src="userImg"
          alt=""
          ref="userImgSettings"
        />
        <div class="settings-photo-copy-container">
          <div class="settings-photo-copy-header">Profile Picture</div>
          <div class="settings-photo-copy-sub">
            Accepted file types: .png, .jpg
          </div>
          <label class="sub">
            <input
              type="file"
              class="photo-input"
              @change="handlePhoto"
              accept=".jpg,.png"
            />
            Upload
          </label>
        </div>
      </div>
      <div class="settings-user-information">
        <input
          title="First Name"
          type="text"
          placeholder="First Name"
          class="input"
          v-model="fName"
        />
        <input
          title="Last Name"
          type="text"
          placeholder="Last Name"
          class="input"
          v-model="lName"
        />
        <input
          title="Email"
          type="email"
          placeholder="Email"
          class="input disabled"
          v-model="email"
          disabled
        />
        <input
          title="Phone Number"
          type="tel"
          placeholder="Phone Number"
          class="input"
          v-model="phone"
          @input="phoneNumberFormatter"
        />
        <input
          title="Company Code"
          type="text"
          placeholder="Company Identification Code"
          class="input company"
          v-model="companyCode"
          v-if="$route.matched[0].path === '/r'"
        />
      </div>
      <div class="settings-button-container">
        <div class="button" @click="submit">Save Changes</div>
        <div class="button inverse" @click="$emit('close')">Close</div>
      </div>
      <div class="settings-change-password-container">
        <span class="settings-link" @click="showPass = true">Click Here</span>
        to change your password.
      </div>
      <div class="message-confirmation">
        {{ mainMsg }}
      </div>
    </div>
    <div class="settings-page-two" v-if="showPass">
      <div class="settings-change-password">
        Password Reset:
        <input
          title="Current Password"
          type="password"
          placeholder="Current Password"
          class="input pass"
          v-model="pass"
          required
        />
        <input
          title="New Password"
          type="password"
          placeholder="New Password"
          class="input pass"
          v-model="newPass"
          required
        />
        <input
          title="Retype New Password"
          type="password"
          placeholder="Retype New Password"
          class="input pass"
          v-model="confirmNewPass"
          required
        />
      </div>
      <div class="password-button-container">
        <div class="button" @click="submit">Save Changes</div>
        <div class="button inverse" @click="showPass = false">Back</div>
      </div>
      <div class="password-message-confirmation">
        {{ passMsg }}
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/assets/svg/Logo.svg";
import { mapGetters } from "vuex";

export default {
  components: {
    Logo,
  },
  data() {
    return {
      fName: "",
      lName: "",
      email: "",
      phone: "",
      pass: "",
      companyCode: "",
      newPass: "",
      confirmNewPass: "",
      userImg: "",
      showPass: false,
      passMsg: "",
      mainMsg: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["images", "userConfig"]),
    payload() {
      return {
        fName: this.fName,
        lName: this.lName,
        email: this.email,
        phone: this.phone,
        newPass: this.newPass,
        companyCode: this.companyCode,
      };
    },
  },
  methods: {
    async handlePhoto(e) {
      this.loading = true;
      const file = e.target.files[0];
      const photo = URL.createObjectURL(file);
      this.userImg = photo;
      await this.handlePhotoUpload(file);
      this.loading = false;
      this.mainMsg = "Profile Picture Updated!";
    },
    async handlePhotoUpload(file) {
      let formData = new FormData();
      const name = `${this.userConfig.username}userPhoto`;
      const index = file.name.lastIndexOf(".");
      formData.append("image", file, `${name}${file.name.substr(index)}`);
      await this.$http.put(`${this.$API}image/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const url = `${this.$BUCKET}${name}${file.name.substr(index)}`;
      this.$store.commit("alterImage", {
        key: `${this.userConfig.username}userPhoto`,
        value: url,
      });
      this.$emit("refreshImg", url);
    },
    load() {
      if (this.$route.matched[0].path === "/r")
        this.companyCode = this.userConfig.companyCode;
      this.fName = this.userConfig.fName;
      this.lName = this.userConfig.lName;
      this.email = this.userConfig.email;
      this.phone = this.formatPhoneNumber(this.userConfig.phone);
      const url = this.images[`${this.userConfig.username}userPhoto`];
      this.userImg = url
        ? `${url}`
        : require("../../../assets/svg/UserProfile.svg?external");
    },
    submit() {
      Object.keys(this.payload).forEach((key) => {
        if (key === "newPass") {
          if (this.pass === this.userConfig.pass) {
            if (this.payload[key] === this.confirmNewPass) {
              this.changeSetting("pass", this.payload[key]);
              this.passMsg = "Update Successful!";
            } else {
              this.passMsg = "Passwords do not match.";
            }
          } else {
            this.passMsg = "Incorrect Password.";
          }
        } else if (key === "phone") {
          const testPhone = this.phone.replace(/-|\(|\)|\s/g, "");
          if (testPhone !== this.userConfig[key]) {
            this.changeSetting(key, testPhone);
          }
        } else if (key === "companyCode") {
          if (this.$route.matched[0].path !== "/r") return;
          this.changeSetting(key, this.payload[key]);
        } else {
          if (this.payload[key] !== this.userConfig[key]) {
            this.changeSetting(key, this.payload[key]);
          }
        }
      });
    },
    async changeSetting(field, value) {
      let context = null;
      switch (this.$route.matched[0].path) {
        case "/r":
          context = "rep";
          break;
        case "/a":
          context = "admin";
          break;
        case "/h":
          context = "user";
          break;
        case "/c":
        case "/s":
          context = "company";
          break;
        default:
          break;
      }
      if (!context) return;
      if (context !== "rep" && field === "companyCode") return;
      this.loading = true;
      try {
        await this.$http.put(
          `${this.$API}${context}/${this.userConfig.id}/settings`,
          {
            field: field,
            value: value,
          }
        );
        this.$store.commit("updateUserConfig", {
          [field]: value,
        });
      } catch (e) {
        console.log(e);
        this.mainMsg = "Update Failed, Check Connection";
      }
      this.loading = false;
      this.mainMsg = "Update Successful!";
    },
    phoneNumberFormatter() {
      this.phone = this.formatPhoneNumber(this.phone);
    },
    formatPhoneNumber(value) {
      if (!value) return value;
      const phoneNumber = value.replace(/[^\d]/g, "");
      const phoneNumberLength = phoneNumber.length;
      if (phoneNumberLength < 4) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )} - ${phoneNumber.slice(6, 10)}`;
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.settings-card-container {
  position: absolute;
  z-index: 2;
  width: 45vw;
  height: 80vh;
  top: 10vh;
  left: 27.5vw;
  background: #ffffff;
  border: 1px solid #ededed;
  overflow: hidden;
}
.settings-card-rep {
  height: 86vh;
  top: 7vh;
}
.settings-header {
  width: 80%;
  margin: 5vh auto 0;
  padding-bottom: 2vh;
  border-bottom: 2px solid #ededed;
  font-weight: bold;
}
.settings-photo-container {
  display: flex;
  width: 80%;
  margin: 3vh auto;
  padding-bottom: 1vh;
  border-bottom: 2px solid #ededed;
}
.settings-photo {
  height: 12vh;
  width: 12vh;
  object-fit: cover;
  border-radius: 50%;
  margin: auto 2vw auto 0;
}
.settings-photo-copy-container {
  display: flex;
  flex-direction: column;
  font-size: 0.25rem;
}
.settings-photo-copy-header {
  font-weight: bold;
}
.settings-photo-copy-sub {
  font-size: 0.8em;
  opacity: 0.6;
  margin: 0.5vh 0;
}
.sub {
  margin: 2vh 0;
}
.settings-user-information {
  display: grid;
  grid-template-areas:
    "a b"
    "c d";
  width: 80%;
  margin: 4vh auto 2vh;
  row-gap: 1vh;
  column-gap: 1vw;
  padding-bottom: 4vh;
  border-bottom: 2px solid #ededed;
}
.input {
  width: 94%;
}
.disabled {
  opacity: 0.7;
}
.pass {
  margin-top: 2vh;
}
.settings-page-two {
  width: 80%;
  height: 80%;
  margin: 10%;
}
.settings-button-container,
.password-button-container {
  width: 80%;
  margin: 4vh auto 2vh;
  display: flex;
  padding-bottom: 4vh;
  border-bottom: 2px solid #ededed;
}
.password-button-container {
  width: 100%;
  padding-top: 4vh;
  border-top: 2px solid #ededed;
}
.button {
  margin-right: 1vw;
}
.button,
.sub {
  font-size: 0.24rem;
}
.button:hover {
  background: var(--blue-600);
}
.settings-change-password-container {
  width: 80%;
  font-size: 0.25rem;
  margin: 4vh auto 0;
  padding-bottom: 4vh;
  border-bottom: 2px solid #ededed;
}
.settings-link {
  text-decoration: none;
  color: var(--blue-600);
  cursor: pointer;
}
.settings-link:hover {
  color: var(--blue-300);
  border-bottom: 1px solid var(--blue-300);
}
.message-confirmation {
  width: 80%;
  text-align: center;
  margin: auto;
  line-height: 7vh;
}
.company {
  grid-column: 1 / span 2;
  width: 97%;
}
</style>

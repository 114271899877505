<template>
  <div
    class="rep-info-container"
    :class="open ? 'rep-info-container-show' : ''"
  >
    <div class="icon-container" :class="!open ? 'hide-icon-container' : ''">
      <img
        class="user-icon"
        :src="userConfig.profile ? userConfig.profile : userImg"
        alt=""
      />
      <div
        class="circle"
        v-for="(link, index) of activeNavItems"
        :key="'link-' + index"
        @click="nav(link.link)"
        :class="activeLink === link.link ? 'circle-active' : ''"
      >
        <component
          class="icon"
          :is="link.icon"
          :class="activeLink === link.link ? 'icon-active' : ''"
        />
        <div class="icon-hover-info">{{ link.copy }}</div>
      </div>
    </div>

    <div class="settings-container" v-if="showSettings">
      <div class="settings-container-sudo" @click="showSettings = false" />
      <Settings @refreshImg="refreshImg" @close="showSettings = false" />
    </div>
  </div>
</template>

<script>
import Settings from "@/components/Common/Header/Settings.vue";
import Cog from "@/assets/svg/Cog2.svg";

export default {
  components: {
    Cog,
    Settings,
  },
  props: ["open", "navItems"],
  data() {
    return {
      userImg: "",
      activeLink: "home",

      // Options popup
      showSettings: false,
    };
  },
  computed: {
    userConfig() {
      return this.$store.getters.userConfig;
    },
    images() {
      return this.$store.getters.images;
    },
    activeNavItems() {
      const navCopy = [...this.navItems];
      navCopy.splice(navCopy.length - 1, 0, {
        copy: "Settings",
        link: "settings",
        icon: Cog,
      });
      return navCopy;
    },
  },
  methods: {
    nav(link) {
      if (link === "settings") {
        this.showSettings = true;
        this.$emit("closeNav");
      } else {
        this.$emit("nav", link);
        this.activeLink = link;
      }
    },
    getProfile() {
      const path = `${this.userConfig.username}userPhoto`;
      this.userImg = this.images[path]
        ? this.images[path]
        : require("../../../assets/svg/Profile2.svg?external");
    },
    refreshImg() {
      setTimeout(() => {
        this.getProfile();
      }, 1500);
    },
  },
  mounted() {
    this.getProfile();
  },
};
</script>

<style scoped>
.rep-info-container {
  position: absolute;
  z-index: 3;
  height: 0vh;
  width: 5vw;
  top: 9vh;
  left: 0;
  font-size: 0.3rem;
  background: #ededed;
  transition: height 0.4s linear 0.3s, width 0.4s linear;
}
.rep-info-container-show {
  height: 91vh;
  transition: height 0.4s linear, width 0.4s linear 0.6s;
}
.icon-container {
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease 0.2s;
}
.hide-icon-container {
  opacity: 0;
  transform: translateX(-40px);
  pointer-events: none;
}
.user-icon {
  object-fit: cover;
  width: calc(6vh + 8px);
  height: calc(6vh + 8px);
  max-width: calc(5vw - 8px);
  max-height: calc(5vw - 8px);
  margin: 3vh auto 0;
  border-radius: 50%;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  width: 6vh;
  max-width: calc(5vw - 8px);
  max-height: calc(5vw - 8px);
  border: 4px solid #000;
  border-radius: 50%;
  margin: 3vh auto 0;
  position: relative;
  cursor: pointer;
}
.icon {
  height: 4vh;
  max-width: 4vh;
}
.circle:hover {
  border-color: rgba(0, 0, 0, 0.6);
}
.circle-active {
  background: rgba(0, 0, 0, 0.3);
}
.icon-active {
  fill: #fff;
  stroke: #fff;
}
.icon-hover-info {
  position: absolute;
  left: 4vw;
  font-size: 0.25rem;
  white-space: nowrap;
  background: var(--blue-600);
  color: white;
  border-radius: 10px;
  width: 0;
  padding: 0;
  transition: all 0.3s ease;
  overflow: hidden;
}
.circle:hover .icon-hover-info {
  width: fit-content;
  padding: 1vh 1vw;
}
.settings-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: #282828d1;
}
.settings-container-sudo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}
</style>

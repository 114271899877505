<template>
  <div class="landing-container">
    <div class="item-container">
      <!-- Page 1 -->
      <div class="item-1">
        <navbar />
        <div class="icon-container" ref="item-1-icon">
          <Icon class="icon" />
        </div>
        <img src="../assets/images/Home.jpg" alt="home" class="item-1-photo" />
        <div class="item-1-container" ref="item-1">
          <div class="header">Home Up</div>
          <div class="sub-text">level up your home</div>
        </div>
      </div>

      <!-- Page 2 -->
      <div class="item-2">
        <div class="left">
          <div class="item-2-text-container">
            <div class="item-2-header">Construction</div>
            <div class="item-2-header">Made Easy</div>
            <div class="item-2-text">
              Home Up is Florida's fastest growing home improvement<br />
              service thanks to you! We make it possible to order a<br />
              construction project with the touch of a button.<br />
              We strive to deliver above and beyond home improvement<br />
              experiences, and with your feedback we will continue to<br />
              improve each and every day!
            </div>
          </div>
        </div>
        <div class="right">
          <img src="../assets/images/Homepage/interior-1.png" alt="home-img" class="photo-2-1" />
          <img src="../assets/images/Homepage/interior-2.png" alt="home-img" class="photo-2-2" />
        </div>
      </div>
    </div>

    <!-- Page 3: -->
    <div class="item-3">
      <img src="../assets/images/Homepage/tablet-sm.png" alt="tablet" class="photo-3" />
      <div class="item-3-text">
        Full Project<br />
        Management &<br />
        Installation
      </div>
    </div>

    <!-- Page: 4 -->
    <div class="item-4">
      <div class="item-4-top-header">Our Services</div>
      <div class="item-4-section" v-for="(item, index) of sectionFourItems" :key="`item-4-${index + 1}`">
        <img :src="item.img" alt="item-img" class="photo-4" />
        <div class="item-4-header">{{ item.header }}</div>
        <div class="item-4-sub">{{ item.sub }}</div>
        <ul class="item-4-list">
          <li v-for="listItem of item.list" :key="`${listItem}-${index}`">
            {{ listItem }}
          </li>
        </ul>
      </div>
    </div>
    <div class="item-4-footer">
      Live Chat with us for Additional Requests!
    </div>

    <!-- Page: 5 -->
    <div class="item-5">
      <div class="item-5-header">Quick & Easy Quotes</div>
      <img src="../assets/images/Homepage/tablet.png" alt="Quote image" class="photo-5">
    </div>

    <!-- Page: 6 -->
    <div class="item-6">
      <div class="item-6-header">MOBILE COMING SOON</div>
      <div class="item-6-sub">Access your projects information on the go!</div>
      <img src="../assets/images/Homepage/mobile.png" alt="mobile" class="photo-6">
    </div>

    <!-- Footer -->
    <div class="footer">
      <div class="footer-header">
        <div class="footer-logo">Home Up <sup>&reg;</sup></div>
        <div class="footer-extra-text">
          We hope you love the experience as<br />
          much as we've loved creating it.
        </div>
      </div>
      <!-- TODO: Needs redesign -->
      <div class="footer-body">
        <div>
          Headquarters<br />
          Apointments Only<br />
          201 S Orlando Ave Suite 850<br />
          Orlando, FL 32801 <br />
          Home Up Construction LLC
        </div>
        <div>
          <div class="footer-body-sub">
            Connect with us
          </div>
          <div class="footer-right-flex">
            <component v-for="link in links" :key="link.url" :is="link.component" @click="open(link.url)"
              class="footer-social-media" />
          </div>
        </div>
      </div>
    </div>

    <!-- Cookies -->
    <div class="cookie-pop-up" :class="cookieActive ? 'cookie-active' : ''">
      <div class="cookie-close" @click="cookieActive = false">X</div>
      <div class="cookie-head">We use cookies</div>
      <div class="cookie-body">
        Cookies help us deliver the best experience on our website. By using our
        website, you agree to the use of cookies.
        <a :href="cookieLink" class="cookie-link" target="__blank">Find out how we use cookies.</a>
      </div>
      <div class="cookie-button" @click="cookieActive = false">Accept</div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Common/WelcomeNav.vue";
import Icon from "@/assets/svg/Logo.svg";
import linkedIn from "@/assets/svg/homepage/linkedin.svg";
import instagram from "@/assets/svg/homepage/instagram.svg";
import facebook from "@/assets/svg/homepage/facebook.svg";

export default {
  components: {
    Navbar,
    Icon,
  },
  data() {
    return {
      sectionFourItems: [
        {
          img: require("../assets/images/Homepage/cutout-0.png"),
          header: "Improvement",
          sub:
            "Using our platform you can follow along with your home projects for all your construction needs",
          list: [
            "Roofing",
            "Tree Removal",
            "HVAC",
            "Hot Water Heater",
            "Pool Pump",
            "Generator",
          ],
        },
        {
          img: require("../assets/images/Homepage/cutout-1.png"),
          header: "Remodels",
          sub:
            "Our team works quickly and efficently to get anywhere in your home leveled up",
          list: [
            "Flooring",
            "Cabinetry",
            "Granite Countertops",
            "Drywall",
            "Windows",
            "Doors",
          ],
        },
        {
          img: require("../assets/images/Homepage/cutout-2.png"),
          header: "Commercial",
          sub:
            "Not only do we take on residential, but we also include full commercial remodels!",
          list: [
            "Store Fronts",
            "Restaurants",
            "Fast Foods",
            "Office Buildings",
          ],
        },
        {
          img: require("../assets/images/Homepage/cutout-3.png"),
          header: "Services",
          sub:
            "Protect your home and save money by using our professional routine maintenance!",
          list: [
            "Solar Panel Cleaning",
            "Tree Trimming",
            "Lawn Care",
            "Pest Control",
            "Duct Cleaning",
          ],
        },
      ],
      links: {
        linkedIn: { url: "https://www.linkedin.com/company/homeup-io/", component: linkedIn },
        facebook: { url: "https://www.facebook.com/HomeUpConstruction", component: facebook },
        instagram: { url: "https://www.instagram.com/homeup.io/", component: instagram },
      },
      cookieActive: false,
      cookieLink: `${this.$EMAIL_BUCKET}Privacy_Policy.pdf`,
      windowWidth:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      windowHeight:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
    };
  },
  methods: {
    handleScroll() {
      if (
        window.scrollY < 100 &&
        !this.$refs["item-1"].classList.contains("animation-1")
      ) {
        this.$refs["item-1"].classList.add("animation-1");
        this.$refs["item-1-icon"].classList.add("animation-1-a");
        this.resetAnimation(1);
      }
    },
    resetAnimation(number) {
      const regex = new RegExp(`[${number - 1}-${number + 1}]`);
      for (const ref in this.$refs) {
        if (!regex.test(ref.toString())) {
          const modifiedRef = Array.isArray(this.$refs[ref])
            ? this.$refs[ref][0]
            : this.$refs[ref];
          const classes = modifiedRef.className
            .split(" ")
            .filter((c) => !c.startsWith("animation"));
          modifiedRef.className = classes.join(" ").trim();
        }
      }
    },
    handleResize() {
      this.windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      this.windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
    },
    open(link) {
      window.open(link, "__blank");
    },
  },
  mounted() {
    setTimeout(() => {
      this.cookieActive = true;
    }, 500);
    this.resetAnimation();
    this.handleScroll();
    window.addEventListener(
      "scroll",
      () => {
        this.handleScroll();
      },
      false
    );
    window.addEventListener("resize", () => {
      this.handleResize();
    });
  },
  beforeUnmount() {
    window.removeEventListener(
      "scroll",
      () => {
        this.handleScroll();
      },
      false
    );
    window.removeEventListener("resize", () => {
      this.handleResize();
    });
  },
};
</script>

<style scoped>
/* Cookie */
.cookie-pop-up {
  position: fixed;
  font-size: 0.2rem;
  background: #fff;
  bottom: 60px;
  left: 2vh;
  width: 11vw;
  padding: 3vh;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.cookie-close {
  position: absolute;
  right: 3vh;
  top: 2vh;
  opacity: 0.2;
  border: 5px solid black;
  width: 1vw;
  height: 1vw;
  font-size: 1vw;
  text-align: center;
  line-height: calc(1vw + 2px);
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.4s ease;
}

.cookie-close:hover {
  opacity: 0.6;
}

.cookie-active {
  opacity: 1;
}

.cookie-head {
  font-weight: bold;
  margin-bottom: 2vh;
}

.cookie-body {
  margin-bottom: 2vh;
}

.cookie-button {
  height: 4vh;
  line-height: 4vh;
  width: calc(100% - 10px);
  text-align: center;
  border: 5px solid #000;
  font-weight: bold;
  cursor: pointer;
}

.cookie-button:hover {
  color: var(--blue-600);
  border-color: var(--blue-600);
}

.cookie-link {
  text-decoration: underline;
}

a:hover {
  color: var(--blue-600);
}

/* Page 1 */
.landing-container {
  overflow-x: hidden;
}

.item-container {
  display: flex;
  flex-direction: column;
}

.item-1 {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.item-1-container {
  position: absolute;
  top: 8vh;
  left: -100vw;
}

.item-1-photo {
  position: absolute;
  z-index: -2;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  object-fit: cover;
}

.icon-container {
  opacity: 0;
}

.icon {
  position: absolute;
  height: 12vh;
  top: 9vh;
  left: 9vh;
  transition: fill 0.5s ease;
  fill: white;
}

.icon-container:hover .icon {
  fill: var(--blue-300);
}

.header {
  font-size: 1em;
  letter-spacing: 2px;
  font-weight: bold;
  margin-left: -4px;
  color: #fff;
}

.sub-text {
  color: #fff;
  font-size: 0.35em;
  font-weight: bold;
  margin-top: -13px;
  letter-spacing: 1px;
  margin-left: 2px;
}

/* Page 2 */
.item-2 {
  position: relative;
  margin-top: 5vh;
  margin-bottom: 5vh;
  height: 80vh;
  width: 100vw;
  font-size: 0.25rem;
  display: flex;
}

.left,
.right {
  position: relative;
  width: 50vw;
}

.item-2-text-container {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70%;
  transform: translate(-50%, -50%);
}

.item-2-header {
  font-size: 0.45rem;
  font-weight: bold;
  color: var(--blue-300);
  letter-spacing: 1px;
}

.item-2-text {
  margin-top: 1vh;
}

.right {
  display: flex;
  justify-content: space-evenly;
}

.photo-2-1,
.photo-2-2 {
  height: 66vh;
  width: 17vw;
  border-radius: 25px;
  object-fit: cover;
}

.photo-2-2 {
  margin-top: 10vh;
}

/* Page 3 */
.item-3 {
  width: 100vw;
  display: flex;
  background-color: #8cb5eb;
  color: #fff;
  font-size: 0.75rem;
  align-items: center;
  justify-content: space-around;
}

.photo-3 {
  margin-left: -23vw;
  width: 80vw;
}

.item-3-text {
  margin-left: -29vw;
  text-align: center;
}

/* Page 4 */
.item-4 {
  height: fit-content;
  width: 100vw;
  position: relative;
  display: flex;
  font-size: 0.2rem;
  padding-top: 4vh;
}

.item-4-top-header {
  width: 100%;
  position: absolute;
  text-align: center;
  font-size: .4rem;
  color: var(--blue-300);
  top: 4vh;
}

.item-4-section {
  margin: 10vh 0 5vh;
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-content: center;
}

img.photo-4 {
  width: 85%;
  margin: 0 auto;
}

.item-4-header {
  margin: 2vh 0 2vh 3vw;
  color: var(--blue-300);
  font-size: 0.4rem;
}

.item-4-sub {
  width: 70%;
  margin-left: 3vw;
}

.item-4-list {
  margin-left: 3vw;
  color: var(--blue-300);
}

.item-4-footer {
  width: 100vw;
  text-align: center;
  font-size: 0.45rem;
  font-weight: bold;
  opacity: 0.3;
  margin-bottom: 5vh;
}

/* Page 5 */

.item-5-header {
  margin-top: 20vh;
  text-align: center;
  font-size: .4rem;
  color: var(--blue-300);
}

.photo-5 {
  width: 80vw;
  margin-top: -5vh;
  margin-left: 10vw;
}

/* Page 6 */
.item-6-header {
  font-size: .4rem;
  color: var(--blue-300);
  text-align: center;
  margin-bottom: 2vh;
}

.item-6-sub {
  font-size: .2rem;
  text-align: center;
  color: var(--blue-300);
  opacity: .7;
  margin-bottom: 10vh;
}

.photo-6 {
  width: 100vw;
}

/* Footer */
.footer {
  position: relative;
  background-color: #8cb5eb;
  font-size: 0.25rem;
  color: #fff;
}

.footer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3vh 2vw;
}

.footer-logo {
  font-size: 0.55rem;
  font-weight: bold;
}

.footer-body {
  display: flex;
  margin: auto 8vw auto 2vw;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5vh;
}

.footer-right-flex {
  margin-top: 1vh;
  display: flex;
  justify-content: space-around;
}

.footer-social-media {
  width: calc(2vw + 1vh);
  height: calc(2vw + 1vh);
}

/* Start Mobile */
@media screen and (max-width: 450px) {

  /* Page 1 */

  .item-1-container {
    top: 10vh;
    left: 42vw;
  }

  .header {
    font-size: 0.55rem;
  }

  .sub-text {
    font-size: 0.2rem;
    margin-top: 0;
    margin-left: -2px;
  }

  .icon-container {
    opacity: 1;
  }

  .icon {
    height: 6vh;
    top: 10vh;
    left: 26vw;
  }

  /* Page 2 */
  .item-2 {
    flex-direction: column;
    height: unset;
  }

  .right,
  .left {
    width: 100vw;
  }

  .item-2-text-container {
    position: initial;
    transform: unset;
    width: 100vw;
    text-align: center;
  }

  .item-2-header {
    font-size: .6rem;
  }

  .item-2-text {
    margin-top: 2vh;
  }

  .photo-2-1,
  .photo-2-2 {
    height: 45vh;
    width: 40vw;
  }

  /* Page 3 */
  .item-3 {
    flex-direction: column-reverse;
  }

  .photo-3 {
    margin: -8vh 0 0 0;
    width: 170vw;
  }

  .item-3-text {
    margin: 8vh 0 0;
    font-size: .6rem;
    font-weight: bold;
  }

  /* Page 4 */
  .item-4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 8vh 0;
  }

  .item-4-top-header {
    font-size: .5rem;
  }

  .item-4-section {
    width: 50vw;
    margin: 3vh 0 0;
    align-content: unset;
  }

  .item-4-footer {
    font-size: .35rem;
    margin-bottom: 0;
  }

  /* Page 5 */
  .item-5-header {
    margin-top: 10vh;
  }
  .photo-5 {
    width: 110vw;
    margin: 0 0 5vh -5vw;
  } 

  /* Page 6 */
  .item-6-header {
    margin-bottom: 1vh;
  }
  .photo-6 {
    width: 200vw;
    margin-left: -50vw;
    margin-top: -5vh;
  }

  /* Footer */
  .footer-header {
    padding: 2vh 2vw;
  }
  .footer-logo {
    font-size: .4rem;
  }
  .footer-extra-text {
    text-align: right;
    opacity: .7;
  }
  .footer-body {
    font-size: .2rem;
    margin: auto 3vw auto 2vw;
  }

  /* Cookie popup */

  .cookie-pop-up {
    width: 30vw;
  }

  .cookie-close {
    border: 3px solid #000;
    width: 2vh;
    height: 2vh;
    font-size: 2vh;
    line-height: 2vh;
  }
}

/* Start Animations */
@media screen and (min-width: 451px) {

  .animation-1,
  .animation-1-a {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: fade-in-left;
  }

  .animation-1-a {
    animation-name: fade-in;
    animation-duration: 1.5s;
  }
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    left: -20vw;
  }

  100% {
    opacity: 1;
    left: 25vh;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>

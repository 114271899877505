<template>
  <div class="user-container">
    <div class="user" v-for="user of filteredUsers" :key="'user-' + user.id" @click="nav(user.id)">
      <div class="primary">{{ formatName(user.fName, user.lName) }}</div>
      <div class="secondary" v-if="!isRep">{{ calcBill(user.projectSnapShot) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["users", "search"],
  computed: {
    filteredUsers() {
      return this.users.filter((a) =>
        `${a.fName.toLowerCase()} ${a.lName.toLowerCase()}`.includes(
          this.search.toLowerCase()
        )
      );
    },
    isRep() {
      return this.$route.matched[0].path === "/r"
    }
  },
  methods: {
    calcBill(projects) {
      if (projects) {
        let sum = 0;
        for (const project of projects) {
          sum += project.bill;
        }
        return this.formatBill(sum);
      }
      return "$0.00"
    },
    formatBill(number) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(number)
    },
    formatName(first, last) {
      return `${first.charAt(0).toUpperCase() + first.slice(1)} ${last.charAt(0).toUpperCase() + last.slice(1)}` 
    },
    nav(userId) {
      const route = `${this.$route.matched[0].path}/view/h?u=${userId}`;
			this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.user-container {
  width: calc(100% - 2vw);
  height: calc(100% - 4vh);
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 1vw;
  column-gap: 1vw;
  row-gap: 2vh;
  flex-direction: row;
  align-content: flex-start;
  overflow: auto;
  border-radius: 25px;
  padding: 2vh 1vw;
}

.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1vh;
  width: 30%;
  margin: auto;
  height: 13vh;
  padding: 1vh 0;
  border-radius: 15px;
  box-shadow: var(--box-shadow-light);
  background: #fff;
  cursor: pointer;
  transition: width 0.4s ease, height 0.4s ease;
}
.user:hover {
  width: 31%;
  height: 14vh;
}
.primary {
  font-weight: bold;
  text-align: center;
  max-width: 85%;
}
.secondary {
  margin: 0 1vw 0 auto;
}
</style>

<template>
  <div class="customer-container">
    <div class="house-container">
      <img class="customer-house" :src="getHome()" alt="house-img" />
      <div class="blocked-status" v-if="project.blockedStatus"> 
        Blocked
      </div>
			<div class="cursor-info">
				{{project.blockedStatus}}
			</div>
    </div>
    <div class="customer-info-name">
      <div>{{ formatName(user.fName, user.lName) }}</div>
      <Cog class="cog" @click="$emit('editing')" v-if="level === 'a'" />
    </div>
    <div class="customer-extra-info">
      <Location class="ball" />{{ user.address }}, {{ user.city }}
      {{ user.state }}
    </div>
    <div class="customer-extra-info">
      <Phone class="ball" />{{ formatPhoneNumber(user.phone) }}
    </div>
    <div class="customer-extra-info"><Mail class="ball" />{{ user.email }}</div>
    <div class="customer-extra-info">
      <div class="user-ball">
        <User class="user-ball-inner" />
      </div>
      Sold By {{ formatName(rep.fName, rep.lName) }} through
      {{ rep.companyName ? rep.companyName : 'an independent company' }}. <br />
      Contact Info: {{ formatPhoneNumber(checkPhone(rep.phone) ? rep.phone : user.companyPhone) }}, {{ rep.email }} <br />
      Solar Installer:
      {{ user.solarCompany ? user.solarCompany : "Not Assigned" }}
    </div>
  </div>
</template>

<script>
import Phone from "@/assets/svg/Phone2.svg";
import Mail from "@/assets/svg/Mail2.svg";
import Location from "@/assets/svg/DropPin.svg";
import User from "@/assets/svg/UserProfile.svg";
import Cog from "@/assets/svg/Cog2.svg";

export default {
  props: ["user", "userPhotos", "rep", "selectedProject"],
  components: {
    Phone,
    Location,
    Mail,
    User,
    Cog,
  },
  data() {
    return {
      house: require("../../../assets/images/Home.jpg"),
    };
  },
  computed: {
    level() {
      return this.$store.getters.level;
    },
		project() {
			return this.selectedProject ? this.selectedProject : {blockedStatus: null}
		}
  },
  methods: {
		checkPhone(num) {
			const regEx = /^[2-9]{1}(1[2-9]|[2-9]1|[2-9]{2})\d{7}$/;
			return regEx.test(num);
		},
    formatName(first, last) {
      return `${first.charAt(0).toUpperCase()}${first.slice(1)} ${last
        .charAt(0)
        .toUpperCase()}${last.slice(1)} `;
    },
    formatPhoneNumber(number) {
			if(!number) return 'No phone on record'
      if (number.length === 11) number = number.substring(1);
      return `(${number.substring(0, 3)}) ${number.substring(
        3,
        6
      )} - ${number.substring(6)}`;
    },
    getHome() {
      const prefix = this.user.username;
      const suffix = "HomePhotoUrl";
      const sides = ["front", "left", "right", "back"];
      for (const side of sides) {
        if (this.userPhotos[`${prefix}${side}${suffix}`]) {
          return this.userPhotos[`${prefix}${side}${suffix}`];
        }
      }
      return this.house;
    },
  },
};
</script>

<style scoped>
.customer-container {
  display: flex;
  flex-direction: column;
}
.house-container {
  position: relative;
}
.customer-house {
  height: 33vh;
  width: 100%;
  margin-bottom: 2vh;
  object-fit: cover;
}
.blocked-status {
	position: absolute;
	bottom: 4vh;
	left: 1vw;
	padding: 1vh 2vw;
	background: #ff8c00;
	border-radius: 15px;
	font-size: .2rem;
	cursor: help;
}
.cursor-info {
	opacity: 0;
	font-size: .2rem;
	padding: .5vh 1vw;
	text-align: center;
	max-width: 10vw;
	position: absolute;
	bottom: 10vh;
	left: 7vw;
	background: #d4d4d4;
	transition: opacity .4s ease;
}
.blocked-status:hover ~ .cursor-info {
	opacity: 1;
}
.customer-info-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}
.cog {
  height: 4vh;
  width: 4vh;
  opacity: 0.5;
  cursor: pointer;
}
.cog:hover {
  -webkit-animation: rotate 4s linear infinite;
  -moz-animation: rotate 4s linear infinite;
  animation: rotate 4s linear infinite;
}

.customer-extra-info {
  display: flex;
  align-items: center;
  font-size: 0.75em;
  margin: 0.75vh 0;
  cursor: default;
}
.customer-extra-info:hover .ball {
  fill: var(--blue-300);
}
.ball,
.user-ball-inner {
  width: 3vh;
  height: 3vh;
  fill: var(--blue-600);
  border-radius: 50%;
  margin-right: 0.5vw;
}
.user-ball-inner {
  stroke: #fff;
  margin-right: 0;
  width: 3vh;
  height: 2.2vh;
}
.user-ball {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-600);
  border-radius: 50%;
  margin-right: 0.5vw;
  width: 3vh;
  height: 3vh;
}
.user-ball:hover {
  background: var(--blue-300);
}

/* Animations */
@-moz-keyframes rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div class="rep-new-lead-container">
    <div class="loading" v-if="loading">
      <Logo class="loading-icon" />
    </div>
    <NewLeadItem
      :componentName="selectedOption"
      :componentNumber="componentNumber"
      :maxPages="componentMaxPages"
      v-if="openPopUp"
      @quit="clearSelected"
      @submit="updateOptionAnswers"
    />

    <div class="show-confirmation" v-if="showConfirmation">
      <div class="confirmation-title">
        Are you sure you want to clear the selected?
      </div>
      <div class="confirmation-button-container">
        <div class="button submit" @click="showConfirmation = false">No</div>
        <div class="button submit" @click="clearSelected">Yes</div>
      </div>
    </div>
    <div class="show-confirmation" v-if="showConfirmationSubmit">
      <div class="confirmation-title">Are you ready to submit?</div>
      <div class="confirmation-button-container">
        <div class="button submit" @click="showConfirmationSubmit = false">
          No
        </div>
        <div class="button submit" @click="submit()">Yes</div>
      </div>
    </div>
    <!-- end popups -->

    <!-- Pages -->
    <div class="card-container">
      <!-- page 1 -->
      <div class="customer-info-container">
        <div class="" v-if="page === 0">
          Homeowner Info

          <div class="name-container">
            <input
              title="First Name"
              type="text"
              placeholder="First Name"
              class="input"
              v-model="firstName"
              @change="removeError(0)"
              required
            />
            <input
              title="Last Name"
              type="text"
              placeholder="Last Name"
              class="input"
              v-model="lastName"
              @change="removeError(1)"
              required
            />
          </div>

          <div class="contact-container">
            <input
              title="Email ex: something@somewhere.com"
              type="email"
              placeholder="Email"
              class="input"
              v-model="email"
              @change="removeError(2)"
              required
            />
            <input
              title="Input numbers only"
              type="tel"
              placeholder="Phone Number"
              class="input"
              v-model="phoneNumber"
              @change="removeError(3)"
              @input="phoneNumberFormatter('phoneNumber')"
              required
            />
          </div>

          Project Address

          <div class="street-container">
            <input
              title="Home number and Street name"
              type="text"
              placeholder="Street Address"
              class="input"
              v-model="streetAddress"
              @change="removeError(4)"
              required
            />
          </div>

          <div class="address-container">
            <input
              title="City"
              type="text"
              placeholder="City"
              class="input"
              v-model="city"
              @change="removeError(5)"
              required
            />
            <select
              class="input select"
              v-model="state"
              @change="removeError(6)"
              required
            >
              <option v-for="state of states" :key="state">{{ state }}</option>
            </select>
            <input
              title="Zip code"
              type="text"
              placeholder="Zip"
              class="input"
              v-model="zipCode"
              @change="removeError(7)"
              required
              pattern="[0-9]{5}"
            />
          </div>

          <div class="member-of-container">
            Are you a current or former member of...
            <div class="button-container">
              <div
                class="button"
                @click="memberOfGov = 'Armed Forces'"
                :class="memberOfGov === 'Armed Forces' ? 'active' : ''"
              >
                Armed Forces
              </div>
              <div
                class="button"
                @click="memberOfGov = 'Government Employee'"
                :class="memberOfGov === 'Government Employee' ? 'active' : ''"
              >
                Government Employee
              </div>
              <div
                class="button"
                @click="memberOfGov = 'Neither'"
                :class="memberOfGov === 'Neither' ? 'active' : ''"
              >
                Neither
              </div>
            </div>
          </div>
        </div>
        <!-- end -->

        <!-- page 2 -->
        <div class="options-container" v-if="page === 1">
          <div class="items">
            <div
              class="button"
              :class="options.includes('roofing') ? 'active' : ''"
              @click="toggle('roofing', 0, 3)"
            >
              Roof Quote
            </div>
          </div>
          <div class="items">
            <div
              class="button"
              :class="options.includes('tree_trimming') ? 'active' : ''"
              @click="toggle('tree_trimming', 1, 3)"
            >
              Tree Quote
            </div>
          </div>
          <div class="items">
            <div
              class="button"
              :class="options.includes('generac') ? 'active' : ''"
              @click="toggle('generac', 2, 1)"
            >
              Generator
            </div>
          </div>
          <div class="items">
            <div
              class="button"
              :class="options.includes('insulation') ? 'active' : ''"
              @click="toggle('insulation', 3, 1)"
            >
              Attic Insulation
            </div>
          </div>
          <div class="items">
            <div
              class="button"
              :class="options.includes('pool_pumps') ? 'active' : ''"
              @click="toggle('pool_pumps', 4, 1)"
            >
              Pool Pump
            </div>
          </div>
          <div class="items">
            <div
              class="button"
              :class="options.includes('hot_water_heater') ? 'active' : ''"
              @click="toggle('hot_water_heater', 5, 1)"
            >
              Hybrid Hot Water Heater
            </div>
          </div>
          <div class="items">
            <div
              class="button"
              :class="options.includes('hvac') ? 'active' : ''"
              @click="toggle('hvac', 6, 2)"
            >
              HVAC
            </div>
          </div>
        </div>
        <!-- end -->

        <!-- page 3 -->
        <div class="hoa-photo-container" v-if="page === 2">
          <div class="home-photo-header">Photos of all sides of the home:</div>
          <div class="photo-container f">
            <img class="photo" alt="" :src="frontHomePhoto" />
            <label class="sub">
              <input
                type="file"
                class="photo-input"
                @change="handlePhoto($event, 'frontHomePhoto')"
                accept=".jpg,.png"
              />
              Front
            </label>
          </div>
          <div class="photo-container l">
            <img class="photo" alt="" :src="leftHomePhoto" />
            <label class="sub">
              <input
                type="file"
                class="photo-input"
                @change="handlePhoto($event, 'leftHomePhoto')"
                accept=".jpg,.png"
              />
              Left
            </label>
          </div>
          <div class="photo-container b">
            <img class="photo" alt="" :src="backHomePhoto" />
            <label class="sub">
              <input
                type="file"
                class="photo-input"
                @change="handlePhoto($event, 'backHomePhoto')"
                accept=".jpg,.png"
              />
              Back
            </label>
          </div>
          <div class="photo-container r">
            <img class="photo" alt="" :src="rightHomePhoto" />
            <label class="sub">
              <input
                type="file"
                class="photo-input"
                @change="handlePhoto($event, 'rightHomePhoto')"
                accept=".jpg,.png"
              />
              Right
            </label>
          </div>
          <div class="hoa-footer">
            <div class="hoa-header">HOA Information</div>
            <div class="hoa-container">
              <input
                type="text"
                placeholder="Hoa Contact Name:"
                class="hoa-input"
                v-model="hoa"
                required
              />
              <input
                type="tel"
                placeholder="Hoa phone number:"
                class="hoa-input"
                v-model="hoaNumber"
                @input="phoneNumberFormatter('hoaNumber')"
                required
              />
              <div
                class="button hoa-button"
                :class="hoaOnline ? 'active' : ''"
                @click="hoaOnline = true"
              >
                Online
              </div>
              <div
                class="button hoa-button"
                :class="!hoaOnline ? 'active' : ''"
                @click="hoaOnline = false"
              >
                In Person
              </div>

              <!-- HOA Check -->
              <div class="items check-item">
                <div
                  ref="accept-terms"
                  :class="acceptTerms === true ? 'active' : ''"
                  @click="acceptTerms = !acceptTerms"
                  class="check"
                />
                <div class="check-text">
                  <a :href="$EMAIL_BUCKET + 'Terms_Of_Use.pdf'" target="_blank"
                    >Accept terms & privacy policy</a
                  >
                </div>
              </div>
              <div class="items check-item">
                <div
                  ref="allow-hoa"
                  :class="allowHOA === true ? 'active' : ''"
                  @click="allowHOA = !allowHOA"
                  class="check"
                />
                <div class="check-text">Allow Home Up to reach out to HOA</div>
              </div>
            </div>
          </div>
        </div>
        <!-- end -->

        <!-- footer -->
        <div class="footer-container">
          <div class="error-msg">{{ error }}</div>
          <div class="submission-container">
            <div class="button submit" v-if="page > 0" @click="page--">
              Previous
            </div>
            <div class="button submit inactive" v-else>Previous</div>
            <div class="button submit" @click="next()">
              {{ page === 2 ? "Submit" : "Next" }}
            </div>
          </div>
        </div>
        <!-- end -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { States } from "@/assets/const.js";

import NewLeadItem from "@/components/Common/NewLead/NewLeadSection.vue";
import Logo from "@/assets/svg/Logo.svg";

export default {
  props: ["repId"],
  components: {
    NewLeadItem,
    Logo,
  },
  data() {
    return {
      // User Info
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      memberOfGov: "Neither",

      // Home info
      streetAddress: "",
      city: "",
      state: "AL",
      zipCode: "",
      frontHomePhoto: "",
      leftHomePhoto: "",
      backHomePhoto: "",
      rightHomePhoto: "",
      hoa: "",
      hoaNumber: "",
      hoaOnline: false,

      states: States,
      // Options
      options: [],
      timeFrame: 0,
      showConfirmationSubmit: false,
      error: "",
      loading: false,
      hasAccount: true,
      page: 0,

      // Additional Questions
      showConfirmation: false,
      openPopUp: false,
      optionAnswers: {},
      selectedOption: "",
      componentNumber: 0,
      componentMaxPages: 0,

      // Photo Handling
      photos: [],

      // Loading Icon
      maxStages: 5,
      currentStage: 5,

      // HOA acceptance
      allowHOA: false,
      acceptTerms: false,
    };
  },
  computed: {
    ...mapGetters(["userConfig", "customers", "level"]),
    hoaInfo() {
      const isOnline = this.hoaOnline
        ? "Contact: Online"
        : "Contact: in Person";
      return `Number: ${this.hoaNumber}, Info: ${this.hoa}, ${isOnline}`;
    },
    submission() {
      return {
        context: "user",
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber.replace(/-|\(|\)|\s/g, ""),
        address: this.streetAddress,
        city: this.city,
        state: this.state,
        zip: this.zipCode,
        hoa: this.hoaInfo,
        repId: /[as]/.test(this.level) ? this.repId : this.userConfig.id,
        memberOfGov: this.memberOfGov,
        tableOptions: this.optionAnswers,
        solarId: this.level === "s" ? this.userConfig.id : null,
      };
    },
  },
  methods: {
    handlePhoto(e, photoChoice) {
      const url = `${photoChoice}Url`;
      const file = e.target.files[0];
      const photo = URL.createObjectURL(file);
      this[photoChoice] = photo;
      this.photos[url] = file;
    },
    toggle(option, component, page) {
      this.componentNumber = component;
      this.componentMaxPages = page;
      this.selectedOption = option;
      if (this.options.includes(option)) {
        this.showConfirmation = true;
      } else {
        this.options.push(option);
        this.openPopUp = true;
      }
    },
    clearSelected() {
      this.options = this.options.filter((a) => a !== this.selectedOption);
      delete this.optionAnswers[this.selectedOption];
      this.showConfirmation = false;
      this.openPopUp = false;
    },
    clearAll() {
      this.optionAnswers = {};
      this.options = [];
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phoneNumber = "";
      this.memberOfGov = "Niether";
      this.streetAddress = "";
      this.city = "";
      this.state = "";
      this.zipCode = "";
      this.frontHomePhoto = "";
      this.leftHomePhoto = "";
      this.backHomePhoto = "";
      this.rightHomePhoto = "";
      this.hoa = "";
      this.allowHOA = false;
      this.acceptTerms = false;
    },
    // From popup component
    updateOptionAnswers(payload) {
      for (const key in payload) {
        if (key === "images") {
          this.photos = {
            ...this.photos,
            ...payload[key],
          };
        } else {
          this.optionAnswers[key] = payload[key];
        }
      }
      this.openPopUp = false;
    },

    // WIP toggle between two
    quote(time) {
      this.timeFrame = time ? 1 : 0;
    },
    checkEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    phoneNumberFormatter(number) {
      this[number] = this.formatPhoneNumber(this[number]);
    },

    formatPhoneNumber(value) {
      if (!value) return value;
      const phoneNumber = value.replace(/[^\d]/g, "");
      const phoneNumberLength = phoneNumber.length;
      if (phoneNumberLength < 4) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )} - ${phoneNumber.slice(6, 10)}`;
    },
    checkFilled() {
      const inputFields = document.getElementsByClassName("input");
      let isFilled = true;
      for (const input of inputFields) {
        if (!input.checkValidity()) {
          input.classList.add("invalid-input");
          isFilled = false;
        }
      }
      return isFilled;
    },
    removeError(number) {
      const field = document.getElementsByClassName("input")[number];
      if (field.classList.contains("invalid-input"))
        field.classList.remove("invalid-input");
    },
    async handlePhotoUpload(username) {
      const promises = [];
      for (const key in this.photos) {
        if (this.photos[key]) {
          if (Array.isArray(this.photos[key])) {
            for (let i = 0; i < this.photos[key].length; i++) {
              let formData = new FormData();
              const file = this.photos[key][i];
              const name = `${username}${key}${i}`;
              const index = file.name.lastIndexOf(".");
              formData.append(
                "image",
                file,
                `${name}${file.name.substr(index)}`
              );
              promises.push(
                this.$http.put(`${this.$API}image`, formData, {
                  headers: { "Content-Type": "multipart/form-data" },
                })
              );
            }
          } else {
            let formData = new FormData();
            const file = this.photos[key];
            const name = `${username}${key}`;
            const index = file.name.lastIndexOf(".");
            formData.append(
              "image",
              file,
              `${name}${file.name.substr(index)}`
            );
            promises.push(
              this.$http.put(`${this.$API}image/upload`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
              })
            );
          }
        }
      }
      await Promise.all(promises);
    },
    async submit() {
      this.loading = true;
      this.showConfirmationSubmit = false;
      try {
        const res = await this.$http.post(`${this.$API}user`, {
          submission: this.submission,
        });
        if (this.level !== "a") {
          const payload = (
            await this.$http.get(`${this.$API}user/${res.data.id}`)
          ).data[0];
          this.$store.commit("addCustomer", {
            ...payload,
          });
        }
        await this.handlePhotoUpload(res.data.username);
        if (res.status === 200) {
          this.$router.push(
            `${this.$route.matched[0].path}/view/h?u=${res.data.id}`
          );
        } else {
          this.page = 0;
          const clearInput = this.level === "a" ? 3 : 2;
          setTimeout(() => {
            document.getElementsByClassName("input")[clearInput].classList.add("invalid-input");
            this.error = "Email Already in Use";
          }, 100);
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    next() {
      if (!this.checkFilled()) {
        this.error = "Please fill out all fields.";
        return;
      } else if (!this.checkEmail(this.email)) {
        this.error = "Please enter a valid email.";
        return;
      }
      this.error = "";
      if (this.page < 2) this.page++;
      else {
        if (!this.allowHOA)
          this.error = "Please allow Home Up to contact your HOA";
        if (!this.acceptTerms)
          this.error = "Please accept terms and conditions before submitting";
        if (this.allowHOA && this.acceptTerms)
          this.showConfirmationSubmit = true;
      }
    },
  },
};
</script>

<style scoped>
.rep-new-lead-container {
  height: 91vh;
  width: 100%;
  background: #d4d4d4;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
}
.card-container {
  width: 70vw;
  height: 70vh;
  margin: auto;
  font-size: 0.3rem;
  background: #fff;
  box-shadow: var(--box-shadow);
  border-radius: 25px;
}
/* page 1 */
.customer-info-container {
  margin: 4vh 3vw;
  display: flex;
  flex-direction: column;
  height: calc(100% - 8vh);
}
.name-container,
.contact-container {
  margin-top: 2vh;
  display: flex;
  justify-content: space-between;
}
.contact-container {
  margin-bottom: 2vh;
}
.input {
  width: 47%;
}
.input:focus-visible {
  outline-style: ridge;
  outline-width: 1px;
  outline-color: var(--blue-300);
}
.street-container {
  margin: 2vh 0;
}
.street-container .input {
  width: 98.5%;
}
.address-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4vh;
}
.address-container .input {
  width: 20%;
}
.address-container .input:first-child {
  width: 43%;
}
.address-container .input:last-child {
  width: 27%;
}
.member-of-container {
  margin-top: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 3vh;
  font-size: 0.2rem;
}
.button {
  width: 3rem;
}
/* end */
/* page 2 */
.options-container {
  font-size: 0.25rem;
  height: 100%;
  display: grid;
  grid-template-areas:
    "a b c"
    "d e f"
    ". g .";
  align-items: center;
  justify-content: space-evenly;
}
.items {
  height: 6vh;
  width: 100%;
  display: flex;
  align-items: center;
}
.check-item {
  width: fit-content;
  margin-left: 1vw;
}
.check-item:first-child {
  margin-left: 1vw;
}
.check {
  height: 4vh;
  width: 4vh;
  background: #ededed;
  cursor: pointer;
  margin-right: 1vw;
}
.check-text {
  font-size: 0.2rem;
  width: 2rem;
}
/* page 3 */
.hoa-photo-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas:
    "hd hd hd hd"
    "f l b r"
    "hoa hoa hoa hoa";
}
.home-photo-header {
  grid-area: hd;
  margin: auto;
  font-size: 0.3rem;
}
.photo-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.f {
  grid-area: f;
}
.l {
  grid-area: l;
}
.b {
  grid-area: b;
}
.r {
  grid-area: r;
}
.hoa-footer {
  grid-area: hoa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64vw;
}
.hoa-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hoa-header {
  text-align: center;
  margin-bottom: 3vh;
  width: 100%;
}
.hoa-button {
  width: 2rem;
  font-size: 0.2rem;
  margin-left: 1vw;
}
.hoa-input {
  width: 20%;
  margin-right: 1vw;
  background: #ededed;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 3.5vh;
  padding-left: 10px;
}
.photo {
  max-width: 14vw;
  min-height: 10vh;
  max-height: 20vh;
  margin: 0;
  cursor: pointer;
}
.photo:hover ~ .sub {
  background: var(--blue-600);
  color: #fff;
}
.photo-input {
  display: none;
}
/* end page 3 */
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  margin-bottom: 2vh;
}
.footer-container .items:first-child {
  margin-left: 3vw;
  width: calc(25% - 3vw);
}
.submission-container {
  width: 36%;
  display: flex;
  justify-content: space-between;
}
.submit {
  width: 45%;
}
.show-confirmation {
  position: absolute;
  width: 30vw;
  height: 30vh;
  left: calc(50% - 15vw);
  top: calc(50% - 15vh);
  font-size: 0.3rem;
  background: #fff;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  border-radius: 25px;
}
.confirmation-title {
  font-size: 0.25rem;
  margin: auto;
}
.confirmation-button-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5vh;
}
</style>

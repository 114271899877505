<template>
  <div class="tree-trimming-container">
    <div class="page-one-content" v-if="currentPage === 0">
      <div class="item-container a">
        Is gate access 6ft or more?
        <div class="button-container">
          <div
            class="button"
            @click="gateAccess = true"
            :class="gateAccess ? 'active' : ''"
          >
            Yes
          </div>
          <div
            class="button"
            @click="gateAccess = false"
            :class="!gateAccess ? 'active' : ''"
          >
            No
          </div>
        </div>
      </div>
      <div class="item-container b">
        Please Note any hazards above or below the ground
        <input
          class="input"
          type="text"
          v-model="hazards"
          placeholder="Include locations and descriptions"
        />
      </div>
      <div class="item-container c">
        Does the home have a septic tank?
        <div class="button-container">
          <div
            class="button"
            @click="septicTank = true"
            :class="septicTank ? 'active' : ''"
          >
            Yes
          </div>
          <div
            class="button"
            @click="septicTank = false"
            :class="!septicTank ? 'active' : ''"
          >
            No
          </div>
        </div>
      </div>
      <div class="item-container d hide" :class="septicTank ? 'show' : ''">
        Where is the septic tank located?
        <input
          class="input"
          type="text"
          v-model="septicTankLocation"
          placeholder="Input location:"
        />
      </div>
    </div>

    <div class="page-two-content" v-if="currentPage === 1">
      <div class="item-container a">
        How many trees will be worked on?
        <input
          class="input"
          type="number"
          v-model="treeCount"
          placeholder="Number of trees:"
        />
      </div>
      <div class="item-container b">
        Any specific restrictions?
        <input
          class="input"
          type="text"
          v-model="permissions"
          placeholder="Neighbors trees, Historic district, etc:"
        />
      </div>
      <div class="item-container c">
        Trimming and/or Removal?
        <div class="button-container">
          <div
            class="button"
            @click="trimming = !trimming"
            :class="trimming ? 'active' : ''"
          >
            Trimming
          </div>
          <div
            class="button"
            @click="removal = !removal"
            :class="removal ? 'active' : ''"
          >
            Removal
          </div>
        </div>
      </div>
      <div class="item-container d">
        Are the trees within 10ft of powerlines?
        <div class="button-container">
          <div
            class="button"
            @click="powerLineProx = true"
            :class="powerLineProx ? 'active' : ''"
          >
            Yes
          </div>
          <div
            class="button"
            @click="powerLineProx = false"
            :class="!powerLineProx ? 'active' : ''"
          >
            No
          </div>
        </div>
      </div>
    </div>

    <div class="page-three-content" v-if="currentPage === 2">
      <div class="img-carousel">
        <div
          v-for="(photo, index) of treePhotos"
          :key="`tree-${index}`"
          class="tree-container"
          :class="photoIndex === index ? '' : 'hide-tree'"
        >
          <div class="button-container">
            <div
              class="button"
              @click="toggleTrimRemove(!photo.trimming, index, 'trimming')"
              :class="photo.trimming ? 'active' : ''"
            >
              Trim
            </div>
            <div
              class="button"
              @click="toggleTrimRemove(!photo.removal, index, 'removal')"
              :class="photo.removal ? 'active' : ''"
            >
              Remove
            </div>
          </div>
          <img :src="photo.src" alt="" class="tree-photo" />
          <div class="counter">
            {{ treeUploadCount }} Tree{{ treeUploadCount > 1 ? "s" : "" }}
            Uploaded
          </div>
        </div>

        <div class="button-container add-tree">
          <label class="button">
            <input
              type="file"
              multiple
              class="photo-input"
              @change="addPhoto"
              accept=".jpg,.png"
              ref="treePhotos"
            />
            Add Tree
          </label>
          <div
            v-if="treePhotos.length !== 0"
            class="button"
            @click="removePhoto"
          >
            Remove Tree
          </div>
          <div v-else class="button inactive">Remove Tree</div>
        </div>

        <Arrow class="next" @click="next" v-if="treePhotos.length > 1" />
        <Arrow
          class="previous"
          @click="previous"
          v-if="treePhotos.length > 1"
        />
      </div>
    </div>

    <div class="page-four-content" v-if="currentPage === 3"></div>
  </div>
</template>

<script>
import Arrow from "@/assets/svg/RightArrow.svg";

export default {
  components: {
    Arrow,
  },
  props: ["currentPage"],
  data() {
    return {
      gateAccess: false,
      septicTank: false,
      septicTankLocation: "",
      hazards: "",
      treeCount: null,
      permissions: "",
      trimming: false,
      removal: false,
      powerLineProx: false,
      treePhotos: [],
      treePhotosUrl: [],
      photoIndex: 0,
      treeUploadCount: 0,
    };
  },
  computed: {
    payload() {
      return {
        hasGateAccess: this.gateAccess,
        hasSepticTank: this.septicTank,
        septicTankLocation: this.septicTankLocation,
        hazards: this.hazards,
        treeCount: this.treeCount,
        permissions: this.permissions,
        trimming: this.trimming,
        removal: this.removal,
        powerLineProx: this.powerLineProx,
      };
    },
    imagePayload() {
      return {
        TreePhoto: this.treePhotosUrl,
      };
    },
  },
  methods: {
    addPhoto(e) {
      const file = e.target.files[0];
      const photo = URL.createObjectURL(file);
      if (!this.treePhotos.includes(photo)) {
        this.treePhotos.push({
          src: photo,
          trimming: false,
          removal: false,
        });
        this.treePhotosUrl.push(file);
        this.treeUploadCount++;
      }
    },
    removePhoto() {
      this.treePhotos.splice(this.photoIndex, 1);
      this.treePhotosUrl.splice(this.photoIndex, 1);
      this.photoIndex = 0;
      this.$refs.treePhotos.value = "";
      this.treeUploadCount--;
    },
    next() {
      if (this.photoIndex === this.treePhotos.length - 1) {
        this.photoIndex = 0;
      } else {
        this.photoIndex++;
      }
    },
    previous() {
      if (this.photoIndex === 0) {
        this.photoIndex = this.treePhotos.length - 1;
      } else {
        this.photoIndex--;
      }
    },
    toggleTrimRemove(state, selection, option) {
      const tree = this.treePhotos[selection];
      if (option !== "trimming" && state && tree.trimming) {
        this.treePhotos[selection].trimming = false;
        this.treePhotos[selection].removal = true;
      } else if (option !== "removal" && state && tree.removal) {
        this.treePhotos[selection].trimming = true;
        this.treePhotos[selection].removal = false;
      } else {
        this.treePhotos[selection][option] = state;
      }
    },
  },
  watch: {
    septicTank: {
      immediate: true,
      handler() {
        if (!this.septicTank) this.septicTankLocation = "";
      },
    },
  },
};
</script>

<style scoped>
.tree-trimming-container {
  height: 100%;
  width: 100%;
}
.page-one-content,
.page-two-content {
  margin-top: 5%;
  height: 80%;
  width: 100%;
  display: grid;
  grid-template-areas:
    "a b"
    "c d";
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.a {
  grid-area: a;
}
.b {
  grid-area: b;
}
.c {
  grid-area: c;
}
.d {
  grid-area: d;
  transition: all 0.3s ease;
}
.item-container {
  margin-top: 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hide {
  opacity: 0;
  transform: translateY(-20px);
}
.show {
  opacity: 1;
  transform: translateY(0);
}
.button-container {
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  width: 100%;
}
.input {
  width: 70%;
  margin: auto;
}
.page-three-content {
  width: 100%;
  height: 100%;
}
.img-carousel {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tree-container {
  height: 73%;
  width: 70%;
  margin: auto auto 0 auto;
  display: flex;
  flex-direction: column;
}
.tree-photo {
  max-width: 33vw;
  max-height: 33vh;
  margin: auto;
}
.counter {
  text-align: center;
  color: var(--blue-600);
}
.border-active {
  border: inset 2px solid var(--blue-600);
}
.hide-tree {
  display: none;
}
.add-tree {
  margin: auto auto 5vh auto;
}
.next,
.previous {
  position: absolute;
  width: 2vw;
  height: auto;
  top: calc(50% - 2.5vh);
  stroke: var(--blue-600);
  cursor: pointer;
}
.next:hover,
.previous:hover {
  stroke: var(--blue-300);
}
.previous {
  transform: rotate(180deg);
  left: 14vw;
}
.next {
  right: 14vw;
}
</style>

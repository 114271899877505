<template>
  <div class="company-container">
    <div class="loading" v-if="loading"><Icon class="loading-icon" />"</div>
    <Settings
      v-if="editing"
      @exit="editing = false"
      :user="activeUser"
      userType="company"
    />
    <div class="left-info">
      <div class="company-header">
        <div>{{ activeUser.companyName }}</div>
        <div class="company-id" @click="copy">
          Id: {{ activeUser.companyCode }}
        </div>
        <div class="hover-id">Click to copy to clipboard</div>
      </div>
      <div class="rep-container">
        <div class="header">
          <div class="caption">Rep list</div>
          <div class="see-more">see more <span class="chevron">></span></div>
        </div>
        <div
          v-for="rep of activeReps"
          :key="'rep-' + rep.id"
          class="rep-item"
          @click="nav('r', rep.id)"
        >
          <div class="selector" />
          <div class="name">{{ formatName(rep.fName, rep.lName) }}</div>
          <div class="date">{{ formatDate(rep.createdAt) }}</div>
          <div class="rep-customer-count">
            Signed {{ countCustomers(rep.id) }} customer{{
              countCustomers(rep.id) === 1 ? "" : "s"
            }}
          </div>
        </div>
      </div>
      <div class="customer-container">
        <div class="header">
          <div class="caption">Homeowner list</div>
          <div class="see-more">see more <span class="chevron">></span></div>
        </div>
        <div
          v-for="homeowner of homeowners"
          :key="'homeowner-' + homeowner.id"
          class="homeowner-item"
          @click="nav('h', homeowner.id)"
        >
          <div class="selector" />
          <div class="name">
            {{ formatName(homeowner.fName, homeowner.lName) }}
          </div>
          <div class="date">{{ formatDate(homeowner.createdAt) }}</div>
          <div>
            <span class="faded">Signed By:</span>
            {{ formatName(homeowner.repFName, homeowner.repLName) }}
            <span v-if="/[sa]/.test(level)"
              ><span class="faded">from</span> {{ homeowner.companyName }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="right-info">
      <img
        :src="userPhoto"
        alt=""
        class="profile-pic"
        :class="level === 'a' ? 'profile-pic-select' : ''"
        @click="openPhotoUpload"
      />
      <div class="company-user-name">
        {{ formatName(activeUser.fName, activeUser.lName) }}
        <Cog class="cog" @click="editing = true" v-if="level === 'a'" />
      </div>
      <div class="icon-container">
        <Phone class="ball" />{{ formatPhoneNumber(activeUser.phone) }}
      </div>
      <div class="icon-container">
        <Mail class="ball" />{{ activeUser.email }}
      </div>

      <div class="icon-container">
        <Location class="ball" /> {{ activeUser.address }},
        {{ activeUser.city }} {{ activeUser.state }}, {{ activeUser.zip }}
      </div>
      <div class="documents">
        <div class="document-header">Documents</div>
        <div
          v-for="(document, index) of activeUser.documents"
          :key="'company-doc-' + index"
          @click="open(document.url)"
          class="icon-container document"
        >
          <Document class="ball" /> {{ document.title }}
        </div>
      </div>
    </div>
    <div
      class="photo-pop-up-bg"
      v-if="photoPopUp === true"
      @click="photoPopUp = false"
    />
    <div class="photo-pop-up-card" v-if="photoPopUp === true">
      <div class="photo-container">
        <img
          class="photo"
          alt=""
          @click="handleClick(false)"
          :src="userPhoto"
        />
        <label class="sub">
          <input
            type="file"
            class="photo-input"
            @change="handlePhoto($event, 'userPhoto')"
            accept=".jpg,.png"
            ref="userPhoto"
          />
          Company logo
        </label>
      </div>
      <div class="photo-container">
        <img
          class="photo"
          alt=""
          @click="handleClick(true)"
          :src="quotePhoto"
        />
        <label class="sub">
          <input
            type="file"
            class="photo-input"
            @change="handlePhoto($event, 'quotePhoto')"
            accept=".jpg,.png"
            ref="quotePhoto"
          />
          Quote Photo
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Settings from "@/components/Common/UserSettings.vue";

import Phone from "@/assets/svg/Phone2.svg";
import Mail from "@/assets/svg/Mail2.svg";
import Document from "@/assets/svg/Document2.svg";
import Location from "@/assets/svg/DropPin.svg";
import Cog from "@/assets/svg/Cog2.svg";
import Icon from "@/assets/svg/Logo.svg";

export default {
  components: {
    Settings,

    Phone,
    Mail,
    Document,
    Location,
    Cog,
    Icon,
  },
  props: ["userId"],
  data() {
    return {
      userPhoto: require("../../assets/svg/Profile2.svg?external"),
      quotePhoto: require("../../assets/svg/Profile2.svg?external"),
      editing: false,
      userPhotos: {},
      photoPopUp: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      "userConfig",
      "level",
      "images",
      "reps",
      "customers",
      "companies",
    ]),
    activeUser() {
      return this.userId
        ? this.companies.filter((a) => a.id === parseInt(this.userId))[0]
        : this.userConfig;
    },
    isSolar() {
      return this.activeUser.isSolar;
    },
    activeReps() {
      const reps = this.reps.filter((a) => a.isActive);
      return reps.filter((a) => a.companyName === this.activeUser.companyName);
    },
    homeowners() {
      if (this.isSolar) {
        return this.customers.filter(
          (a) => a.solarCompany === this.activeUser.companyName
        );
      }
      return this.customers.filter(
        (a) => a.companyName === this.activeUser.companyName
      );
    },
  },
  methods: {
    async handlePhoto(e, photoChoice) {
      this.loading = true;

      // Set Photo choice
      let url = `${photoChoice}Url`;
      const file = e.target.files[0];
      const photo = URL.createObjectURL(file);
      this[photoChoice] = photo;
      this[url] = file;

      // Append data to send
      let formData = new FormData();
      const name = `${this.activeUser.username}${photoChoice}`;
      const index = file.name.lastIndexOf(".");
      formData.append("image", file, `${name}${file.name.substr(index)}`);
      await this.$http.put(`${this.$API}image/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Commit data to storage
      url = `${this.$BUCKET}${name}${file.name.substr(index)}`;
      this.$store.commit("alterImage", {
        key: `${this.activeUser.username}userPhoto`,
        value: url,
      });
      this.loading = false;
    },
    handleClick(isTag) {
      if (isTag) this.$refs.quotePhoto.click();
      else this.$refs.userPhoto.click();
    },
    openPhotoUpload() {
      if (this.level !== "a") return;
      this.photoPopUp = true;
    },
    nav(type, id) {
      const path = `${this.$route.path.substring(
        0,
        this.$route.path.length - 1
      )}${type}?u=${id}`;
      this.$router.push(path);
    },
    copy() {
      navigator.clipboard.writeText(this.activeUser.companyCode);
    },
    formatDate(date) {
      return `${date.substring(5, 7)}.${date.substring(8, 10)}.${date.substring(
        0,
        4
      )}`;
    },
    formatName(first, last) {
      if (!first || !last) return "";
      return `${first.charAt(0).toUpperCase()}${first.substring(
        1
      )} ${last.charAt(0).toUpperCase()}${last.substring(1)}`;
    },
    countCustomers(repId) {
      let sum = 0;
      this.homeowners.forEach((c) => {
        if (c.repId === repId) sum++;
      });
      return sum;
    },
    setImg() {
      const photo = this.userPhotos[`${this.activeUser.username}userPhoto`];
      this.userPhoto = photo
        ? photo
        : require("../../assets/svg/Profile2.svg?external");

      const quote = this.userPhotos[`${this.activeUser.username}quotePhoto`];
      this.quotePhoto = quote
        ? quote
        : require("../../assets/svg/Profile2.svg?external");
    },
    formatPhoneNumber(number) {
      if (number.length === 11) number = number.substring(1);
      return `(${number.substring(0, 3)}) ${number.substring(
        3,
        6
      )} - ${number.substring(6)}`;
    },
    open(docUrl) {
      window.open(docUrl, "_blank");
    },
    async getCompanyPhoto() {
      if (!this.activeUser.photos) {
        await this.$http
          .put(`${this.$API}image/get`, {
            prefix: this.activeUser.username,
          })
          .then((res) => {
            if (res.data[0].length === 0) {
              this.$store.commit("setUserImages", {
                userId: this.activeUser.id,
                photoName: "",
                photo: "",
                context: "companies",
              });
            }
            for (const image of res.data[0]) {
              const index = image.name.lastIndexOf(".");
              const photoName = image.name.substring(0, index);
              const photo = `${this.$BUCKET}${image.name}`;
              const payload = {
                userId: this.activeUser.id,
                photoName: photoName,
                photo: photo,
                context: "companies",
              };
              this.userPhotos[photoName] = photo;
              this.$store.commit("setUserImages", payload);
            }
          });
      } else {
        this.userPhotos = this.activeUser.photos;
      }
    },
    async getDocuments() {
      const res = await this.$http.put(`${this.$API}document/get`, {
        prefix: this.activeUser.username,
      });
      const docs = [];
      const pattern = /-.*/;
      if (!res.data) return;
      for (const titles of res.data[0]) {
        let title = titles.name;
        title = title.match(pattern)[0].substring(1);
        title = title.charAt(0).toUpperCase() + title.slice(1);
        title = title.replace(".html", "");
        docs.push({ url: `${this.$DOCUMENT_BUCKET}${titles.name}`, title });
      }
      this.$store.commit("updateCustomerDocument", {
        userId: this.activeUser.id,
        documents: docs,
        context: "companies",
        isUser: !!this.userId,
      });
    },
  },
  async mounted() {
    if (this.userId) await this.getCompanyPhoto();
    else this.userPhotos = this.images;
    if (!this.activeUser.documents) await this.getDocuments();
    this.setImg();
  },
};
</script>

<style scoped>
.company-container {
  width: 100vw;
  height: 91vh;
  display: flex;
  font-size: 0.4rem;
  background: #ededed;
}
.left-info {
  width: 82vw;
  display: flex;
  flex-direction: column;
}
.company-header {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 6vw);
  padding: 5vh 3vw;
  font-size: 0.3rem;
  font-weight: bold;
}
.company-id {
  opacity: 0.7;
  cursor: pointer;
}
.hover-id {
  position: absolute;
  opacity: 0;
  right: 20vw;
  top: 8vh;
  transition: opacity 0.3s ease;
}
.company-id:hover ~ .hover-id {
  opacity: 1;
}
.rep-container,
.customer-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  padding: 2vh 2vw;
  background: #fff;
  border-radius: 25px;
  box-shadow: var(--box-shadow);
  height: 26vh;
  overflow: auto;
}
.rep-container {
  margin-bottom: 5vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  padding: 2vh 0 1vh 0;
  margin-top: -2vh;
  top: -2vh;
  z-index: 2;
  width: 100%;
  background: #fff;
  font-size: 0.3rem;
}
.caption {
  font-weight: bold;
}
.see-more {
  display: flex;
  align-items: center;
  opacity: 0.5;
  font-size: 0.2rem;
  cursor: pointer;
  transition: opacity 0.2s ease;
}
.see-more:hover {
  opacity: 0.7;
}
.chevron {
  font-family: cursive;
  margin-left: 0.1rem;
}
.rep-item,
.homeowner-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 0.3rem;
  margin: 0.5vh 0;
  font-size: 0.25rem;
}
.rep-item:hover .selector,
.homeowner-item:hover .selector {
  background: var(--blue-300);
}
.selector {
  width: 0.4vw;
  height: 80%;
  margin: auto 0;
  background: var(--blue-600);
  transition: background-color 0.2s ease;
}
.name {
  font-weight: bold;
  margin: 0 1vw;
}
.date {
  font-size: 0.2rem;
  opacity: 0.5;
  margin-right: auto;
}
.faded {
  opacity: 0.5;
}
.right-info {
  width: 18vw;
  margin-top: 3vh;
  font-size: 0.25rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.profile-pic {
  object-fit: cover;
  height: 15vh;
  width: 15vh;
  margin: auto;
  margin-left: 4vw;
  border-radius: 50%;
}
.profile-pic-select {
  cursor: pointer;
}
.company-user-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.25rem;
  margin: 2vh 2vw 2vh 0;
}
.icon-container {
  display: flex;
  align-items: center;
  font-size: 0.75em;
  margin: 0.75vh 0;
  cursor: default;
}
.icon-container:hover .ball {
  fill: var(--blue-300);
}
.ball {
  min-width: 3vh;
  min-height: 3vh;
  max-width: 3vh;
  max-height: 3vh;
  fill: var(--blue-600);
  border-radius: 50%;
  margin-right: 0.5vw;
}
.documents {
  margin-top: 2vh;
  height: 50vh;
  padding: 2vh 1vw 0 1vw;
  margin-right: 2vw;
  background: #dbdbdb;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  overflow: auto;
}
.document-header {
  margin: 1vh;
}
.document {
  cursor: pointer;
}
.document:hover {
  text-decoration: underline;
}

.cog {
  height: 4vh;
  width: 4vh;
  opacity: 0.5;
  cursor: pointer;
}
.cog:hover {
  -webkit-animation: rotate 4s linear infinite;
  -moz-animation: rotate 4s linear infinite;
  animation: rotate 4s linear infinite;
}

/* Photo Pop Up */
.photo-pop-up-bg {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  background: #000000a8;
}
.photo-pop-up-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  width: 40vw;
  height: 45vh;
  padding: 5vh 5vw;
  background: #fff;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
}

.photo-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.photo {
  max-width: 24vw;
  max-height: 30vh;
  margin: 0;
  cursor: pointer;
}
.photo:hover ~ .sub {
  background: var(--blue-600);
  color: #fff;
}

/* Animations */
@-moz-keyframes rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

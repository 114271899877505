<template>
  <div class="project-notes-footer-container">
    <div class="button note-footer-button" @click="nav">
      Project Settings
    </div>
    <div class="date-picker-container" v-if="/[as]/.test(level)">
      <Datepicker
        @selected="setDate"
        :value="scheduledAt"
        wrapper-class="wrapper-date-picker"
        input-class="calendar-input"
        calendar-class="calendar-date-picker"
        :disabled-dates="disabledDates"
      />
      <select
        v-model="time"
        class="input select"
        @change="setTime"
        :disabled="dateSelected"
        v-if="level === 'a'"
      >
        <option v-for="time of timeOptions" :key="time" :value="time">
          {{
            time > 11
              ? `${time - 12}pm - ${time - 10}pm`
              : `${time}am - ${time + 2 > 11 ? time - 10 : time + 2}${
                  time + 2 > 12 ? "pm" : "am"
                }`
          }}
        </option>
      </select>
    </div>

    <div
      class="button note-footer-button"
      @click="setSchedule"
      v-if="/[as]/.test(level)"
    >
      Set Service Date
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  props: ["selectedProject", "user"],
  components: {
    Datepicker,
  },
  data() {
    return {
      date: "",
      time: "",
      timeOptions: [7, 9, 11, 13, 15, 17, 19],

      // Datepicker options
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
      },
    };
  },
  methods: {
		nav() {
			const path = `${this.$route.path}/p?${this.level !== 'u' ? `u=${this.user.id}&` : ''}p=${this.selectedProject.projectId}`;
			this.$router.push(path);
		},
    setDate(date) {
      this.date = new Date(date.setHours(0, 0, 0, 0));
    },
    setTime() {
      this.date = new Date(new Date(this.date)).setHours(this.time);
    },
    async setSchedule() {
      let date = new Date(this.date);
      date = `${date
        .toISOString()
        .substring(0, 10)} ${date.toISOString().substring(11, 19)}`;
      this.selectedProject.scheduledAt = date;
      if (this.level === "a") {
        await this.$http.put(`${this.$API}project/${this.selectedProject.projectId}/schedule`, {
          homeId: this.user.homeId,
          fName: this.user.fName,
          lName: this.user.lName,
          scheduledAt: date,
          context: this.selectedProject.tableName,
        });
      } else {
        await this.$http.put(`${this.$API}user/${this.user.id}/solar`, {
          scheduledAt: date,
        });
      }
    },
  },
  computed: {
    level() {
      return this.$store.getters.level;
    },
    scheduledAt() {
      return this.selectedProject.scheduledAt
        ? this.selectedProject.scheduledAt
        : new Date();
    },
    dateSelected() {
      return this.date === "";
    },
  },
};
</script>

<style scoped>
.project-notes-footer-container {
  display: flex;
  column-gap: 1vw;
  align-items: center;
}
.note-footer-button {
  width: 12vw;
  margin-top: 0;
}
.calendar-date-picker {
  top: -30vh;
  left: -4vw;
}
.date-picker-container {
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
}
.select {
  width: 100%;
}
</style>

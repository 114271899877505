<template>
  <div>
    <div class="loading" v-if="loading">
      <Logo class="loading-icon" />
    </div>
    <div class="assign-solar-container" >
      <div class="main-header">Drag & Drop</div>
      <div class="container solar">
        <div class="header">
          <div>Solar Installers</div>
          <input
            type="text"
            class="input search"
            placeholder="Search"
            v-model="companyFilter"
          />
        </div>
        <div class="card-container">
          <div
            class="card"
            :class="
              selectedCompany && selectedCompany.id === company.id
                ? 'active-card'
                : ''
            "
            v-for="(company, index) of filteredCompanies"
            :key="'company-' + index"
            :ref="'company-' + index"
            draggable="true"
            @dragstart="handleDrag('company', company)"
            @drop="handleDrop('company', company)"
            @dragenter="handleEnter('company', index)"
            @dragleave="handleExit('company', index)"
            @dragover.prevent
            @dragenter.prevent
          >
            <div class="company-name">{{ company.companyName }}</div>
          </div>
        </div>
      </div>
      <div class="container homeowner">
        <div class="header">
          <div class="homeowner-header">Homeowners</div>
          <input
            type="text"
            class="input search"
            placeholder="Search"
            v-model="customerFilter"
          />
        </div>
        <div class="card-container">
          <div
            class="card"
            v-for="(customer, index) of filteredCustomers"
            :key="'customer-' + index"
            :ref="'customer-' + index"
            draggable="true"
            @dragstart="handleDrag('customer', customer)"
            @drop="handleDrop('customer', customer)"
            @dragenter="handleEnter('customer', index)"
            @dragleave="handleExit('customer', index)"
            @dragover.prevent
            @dragenter.prevent
          >
            <div class="customer-name">
              {{ formatName(customer.fName, customer.lName) }}
            </div>
            <div class="company-assigned">
              {{ customer.solarCompany ? customer.solarCompany : "Unassigned" }}
            </div>
          </div>
        </div>
      </div>
      <div class="company-info-container" v-if="selectedCompany">
        <div class="company-info-header">
          <div>{{ selectedCompany.companyName }}</div>
          <div class="company-code">Id: {{ selectedCompany.companyCode }}</div>
        </div>
        <div class="company">
          <img class="company-photo" alt="" :src="profilePicture" />
          <div class="company-info">
            <div class="company-lead">
              {{ formatName(selectedCompany.fName, selectedCompany.lName) }}
            </div>
            <div class="info">
              <Phone class="icon" />
              <div>{{ formatPhone(selectedCompany.phone) }}</div>
            </div>
            <div class="info">
              <Mail class="icon" />
              <div>{{ selectedCompany.email }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-up-container" v-if="showConfirmation" @click="showConfirmation = false" />
    <div class="pop-up" v-if="showConfirmation">
        <div class="pop-up-header blue">Wait!</div>
        <div class="pop-up-warning">
          Are you sure you want to assign
          <span class="blue bold">{{
            formatName(selectedUser.fName, selectedUser.lName)
          }}</span>
          to <span class="bold">{{ selectedCompany.companyName }}?</span>
        </div>
        <div class="pop-up-info-container">
          <img :src="homeImage" alt="" class="pop-up-img" />
          <div class="pop-up-info">
            <div>
              {{ selectedCompany.isSolar ? "Solar Company" : "Sales Company" }}
            </div>
            <div class="info">
              <Location class="pop-up-icon" />
              <div>{{ selectedUser.address }}</div>
            </div>
            <div class="info">
              <Phone class="pop-up-icon" />
              <div>{{ formatPhone(selectedUser.phone) }}</div>
            </div>
            <div class="info">
              <Mail class="pop-up-icon" />
              <div>{{ selectedUser.email }}</div>
            </div>
          </div>
        </div>
        <div class="button" @click="accept">Accept</div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// icons
import Phone from "@/assets/svg/Phone2.svg";
import Mail from "@/assets/svg/Mail2.svg";
import Location from "@/assets/svg/DropPin.svg";
import Logo from "@/assets/svg/Logo.svg";

export default {
  components: {
    Phone,
    Mail,
    Location,
		Logo,
  },
  data() {
    return {
      companyFilter: "",
      customerFilter: "",
      type: "",
      payload: "",
      selectedCompany: null,
      selectedUser: null,
      showConfirmation: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["customers", "companies"]),
    filteredCompanies() {
      const companies = this.companies.filter(a => a.isSolar);
      return companies.filter((a) =>
        a.companyName.toLowerCase().includes(this.companyFilter.toLowerCase())
      );
    },
    filteredCustomers() {
      return this.customers.filter((a) =>
        `${a.fName.toLowerCase()} ${a.lName.toLowerCase()}`.includes(
          this.customerFilter.toLowerCase()
        )
      );
    },
    profilePicture() {
      return require("../../assets/svg/UserProfile.svg?external");
    },
    homeImage() {
      return require("../../assets/images/Home.jpg");
    },
  },
  methods: {
    formatName(first, last) {
      return `${first.charAt(0)}${first.substring(1)} ${last.charAt(
        0
      )}${last.substring(1)}`;
    },
    formatPhone(number) {
      if (number.length === 11) number = number.substring(1);
      return `(${number.substring(0, 3)}) ${number.substring(
        3,
        6
      )} - ${number.substring(6)}`;
    },
    async accept() {
      this.loading = true;
      try {
        await this.$http.put(`${this.$API}user/${this.payload.userId}/solar/${this.payload.companyId}`);
        this.$store.commit("setSolarCompany", this.payload);
      } catch {
        this.loading = false;  
      }
      this.type = "";
      this.payload = {}
      this.$forceUpdate();
      this.showConfirmation = false;
      this.loading = false;
    },
    assign(type, user) {
      if (type === "company") {
        this.selectedCompany = user;
      } else {
        this.selectedUser = user;
      }
    },
    handleDrop(type, user) {
      if (this.type !== type) {
        this.assign(type, user);
        this.payload = {
          userId: this.selectedUser.id,
          companyId: this.selectedCompany.id,
        };
        this.showConfirmation = true;
      }
    },
    handleDrag(type, user) {
      this.type = type;
      this.assign(type, user);
    },
    handleEnter(type, index) {
      if (this.type !== type) {
        const ref = this.$refs[`${type}-${index}`][0];
        if (!ref.classList.contains("drag-hover"))
          ref.classList.add("drag-hover");
      }
    },
    handleExit(type, index) {
      if (this.type !== type) {
        const ref = this.$refs[`${type}-${index}`][0];
        if (ref.classList.contains("drag-hover"))
          ref.classList.remove("drag-hover");
      }
    },
  },
};
</script>

<style scoped>
.assign-solar-container {
  height: 91vh;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-areas:
    "header header"
    "solar homeowner"
    "company-info homeowner";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 8vh 1fr 1fr;
  font-size: 0.3rem;
  background: #ededed;
}
.main-header {
  font-size: 0.35rem;
  grid-area: header;
  text-align: center;
  line-height: 8vh;
  font-weight: bold;
}
.container,
.company-info-container {
  margin: 2vh 2vw;
}
.container {
  overflow: auto;
  position: relative;
}
.solar {
  grid-area: solar;
}
.homeowner {
  grid-area: homeowner;
}
.header {
  position: sticky;
  background: #ededed;
  top: 0;
}
.homeowner-header {
  text-align: end;
  margin-right: 2vw;
}
.search {
  position: sticky;
  top: 0;
  background: #fff;
  opacity: 0.5;
  width: 94%;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2vw;
  padding-right: 2vw;
}
.card {
  flex: 1 0 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  min-height: 10vh;
  padding: 1vh 1vw;
  background: #fff;
  border-radius: 25px;
  text-align: center;
  margin: 1vh 0;
  transition: padding 0.3s ease;
  cursor: grab;
  user-select: none;
}
.card:hover {
  padding: 1.5vh 1.5vw;
}
.drag-hover {
  padding: 1.5vh 1.5vw;
}

.active-card {
  border: 3px solid var(--blue-600);
}

.customer-name,
.company-assigned,
.company-name {
  pointer-events: none;
}
.company-info-container {
  grid-area: company-info;
}
.company-info-header {
  display: flex;
  justify-content: space-between;
}
.company {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5vh;
}
.company-photo {
  width: 20vh;
  height: 20vh;
  border-radius: 50%;
  background: #fff;
}
.company-code {
  opacity: 0.5;
}
.company-info {
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
}
.company-lead {
  font-size: 0.35rem;
  font-weight: bold;
}
.info {
  display: flex;
  column-gap: 1vw;
  cursor: default;
}
.icon {
  height: 4vh;
  fill: var(--blue-600);
}
.info:hover .icon {
  fill: var(--blue-300);
}
.pop-up-container {
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.pop-up {
  position: absolute;
  z-index: 3;
  font-size: 0.3rem;
  width: 50vw;
  height: 50vh;
  left: 23vw;
  top: 14vh;
  background: #fff;
  border: 6px inset rgba(0, 0, 0, 0.4);
  border-radius: 25px;
  padding: 2vh 2vw;

}
.pop-up-header {
  font-size: 0.6rem;
  margin: 2vh;
  font-weight: bold;
  text-align: center;
}
.pop-up-warning {
  text-align: center;
}
.pop-up-info-container {
  display: flex;
  align-items: center;
  margin: 2vh 0 2vh 2vw;
}
.pop-up-img {
  height: 20vh;
  width: auto;
  max-width: 22vw;
  margin: 2vh auto;
}
.pop-up-info {
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  width: 50%;
}
.pop-up-icon {
  min-height: 4vh;
  min-width: 4vh;
  height: 4vh;
  width: 4vh;
  fill: var(--blue-600);
}
.blue {
  color: var(--blue-600);
}
.bold {
  font-weight: bold;
}
.button {
  margin: auto;
}
</style>

<template>
  <div>
    <div class="project-header">
      Project Timelines{{ level !== "u" ? `: ${totalBill()}` : "" }}
      <NewProject :userId="userId" @refreshProjects="$emit('refreshProjects')"/>
    </div>

    <div class="project-container">
      <div
        v-for="(project, index) of projects"
        :key="'progress-' + index"
        class="progress-container"
        @click="$emit('projectSelection', index)"
      >
        <div>
          {{ project.title
          }}{{ level !== "u" ? `: ${formatBill(project.bill)}` : "" }}
        </div>
        <div class="progress-bar" />
        <div
          class="progress-bar-fill"
          :style="'width: ' + Math.floor((project.completion / 5) * 100) + '%;'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewProject from "@/components/Common/ViewCustomer/AddProject.vue";
export default {
  props: ["projects", "userId"],
  components: {
    NewProject,
  },
  computed: {
    level() {
      return this.$store.getters.level;
    },
  },
  methods: {
    formatBill(bill) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(bill);
    },
    totalBill() {
      let sum = 0;
      for (const project of this.projects) {
        sum += parseFloat(project.bill);
      }
      return this.formatBill(sum);
    },
  },
};
</script>

<style scoped>
.project-header {
  margin: 3vh 0;
  font-weight: bold;
	display: flex;
	justify-content: space-between;
}
.project-container {
  height: 20vh;
  overflow-y: auto;
  margin-bottom: 4vh;
  padding-right: 2vw;
}
.project-container {
  cursor: pointer;
}
.progress-container:hover .progress-bar-fill {
  background: var(--blue-300);
}
.progress-container:hover .progress-bar {
  background: #b2b2b2;
}
.progress-container:hover div {
  text-decoration: underline;
  text-decoration-color: var(--blue-300);
}
.progress-bar {
  width: 100%;
  height: 4vh;
  margin: 2vh 0;
  background: #989898;
}
.progress-bar-fill {
  margin-top: -6vh;
  background: var(--blue-600);
  height: 4vh;
  margin-bottom: 2vh;
  transition: all 0.5s ease;
}
</style>

<template>
  <div class="hvac-main-container">
    <div class="hvac-container" v-if="currentPage === 0">
      <div class="hvac-header">
        Please Provide Images of the Exterior HVAC Unit:
      </div>
      <div class="hvac-photo-container">
        <img
          class="photo"
          alt=""
          @click="handleClick('ExACUnitPhoto')"
          :src="ExACUnitPhoto"
        />
        <label class="sub">
          <input
            type="file"
            class="photo-input"
            @change="handlePhoto($event, 'ExACUnitPhoto')"
            accept=".jpg,.png"
            ref="ExACUnitPhoto"
          />
          AC Unit
        </label>
      </div>
      <div class="hvac-tag-container">
        <img
          class="photo"
          alt=""
          @click="handleClick('ExACDataTagPhoto')"
          :src="ExACDataTagPhoto"
        />
        <label class="sub">
          <input
            type="file"
            class="photo-input"
            @change="handlePhoto($event, 'ExACDataTagPhoto')"
            accept=".jpg,.png"
            ref="ExACDataTagPhoto"
          />
          Data Tag
        </label>
      </div>
    </div>

    <div class="hvac-container" v-if="currentPage === 1">
      <div class="hvac-header">
        Please Provide Images of the Interior HVAC Unit:
      </div>
      <div class="hvac-photo-container">
        <img
          class="photo"
          alt=""
          @click="handleClick('InACUnitPhoto')"
          :src="InACUnitPhoto"
        />
        <label class="sub">
          <input
            type="file"
            class="photo-input"
            @change="handlePhoto($event, 'InACUnitPhoto')"
            accept=".jpg,.png"
            ref="InACUnitPhoto"
          />
          AC Unit
        </label>
      </div>
      <div class="hvac-tag-container">
        <img
          class="photo"
          alt=""
          @click="handleClick('InACDataTagPhoto')"
          :src="InACDataTagPhoto"
        />
        <label class="sub">
          <input
            type="file"
            class="photo-input"
            @change="handlePhoto($event, 'InACDataTagPhoto')"
            accept=".jpg,.png"
            ref="InACDataTagPhoto"
          />
          Data Tag
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentPage"],
  data() {
    return {
      ExACUnitPhoto: require("../../../../assets/images/HVAC.jpg"),
      ExACDataTagPhoto: require("../../../../assets/images/HVACTag.jpg"),
      InACUnitPhoto: require("../../../../assets/images/HVAC.jpg"),
      InACDataTagPhoto: require("../../../../assets/images/HVACTag.jpg"),
      ExACUnitPhotoUrl: "",
      ExACDataTagPhotoUrl: "",
      InACUnitPhotoUrl: "",
      InACDataTagPhotoUrl: "",
    };
  },
  computed: {
    payload() {
      return {};
    },
    imagePayload() {
      return {
        ExACUnit: this.ExACUnitPhotoUrl,
        ExACDataTag: this.ExACDataTagPhotoUrl,
        InACUnit: this.InACUnitPhotoUrl,
        InACDataTag: this.InACDataTagPhotoUrl,
      };
    },
  },
  methods: {
    handlePhoto(e, tag) {
      const url = `${tag}Url`;
      const file = e.target.files[0];
      const photo = URL.createObjectURL(file);
      this[tag] = photo;
      this[url] = file;
    },
    handleClick(tag) {
      this.$refs[tag].click();
    },
  },
};
</script>

<style scoped>
.hvac-main-container {
  height: 100%;
  width: 100%;
}
.hvac-container {
  height: calc(100% - 7vh);
  width: 86%;
  display: grid;
  grid-template-areas:
    ". a a ."
    "b b c c";
  margin: auto;
}
.hvac-header {
  grid-area: a;
  margin: auto;
  font-size: 0.3rem;
}
.hvac-photo-container,
.hvac-tag-container {
  grid-area: b;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.hvac-tag-container {
  grid-area: c;
}
.photo {
  max-width: 24vw;
  max-height: 30vh;
  margin: 0;
  cursor: pointer;
}
.photo:hover ~ .sub {
  background: var(--blue-600);
  color: #fff;
}
</style>

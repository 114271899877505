<template>
  <div class="not-found">
    <div class="header">
      <Logo class="logo" @click="$router.push('/')" />
    </div>
    <div class="error-head">404</div>
    <div class="error-message">
      This page does not exist please return home, and try again.
    </div>
		<div class="button" @click="$router.back()">Back</div>
  </div>
</template>

<script>
import Logo from "@/assets/svg/Logo.svg";
export default {
  components: {
    Logo,
  },
};
</script>

<style scoped>
.not-found {
  height: 100vh;
  width: 100vw;
  background: #ededed;
  text-align: center;
}
.header {
  width: 96vw;
  padding: 0 2vw;
  height: 10vh;
  background: #77c4e2;
  display: flex;
  position: relative;
}
.logo {
  position: absolute;
  left: calc(50% - 3vh);
  top: 2.5vh;
  height: 5vh;
  width: 6vh;
  transition: 0.5s ease all;
}
.logo:hover {
  cursor: pointer;
  fill: #fff;
}
.error-head {
  font-size: 0.8rem;
  margin: 10vh 0 2vh 0;
}
.error-message {
  font-size: 0.3rem;
}
.button {
	margin: 3vh auto;
	font-size: 0.45rem;
	background: var(--blue-600);
	color: #fff;
	width: fit-content;
	padding: 1vh 4vw;
}
.button:hover {
	background: var(--blue-300);
}
</style>
<template>
  <div class="hwh-container">
    <div class="item-container">
      <div class="header">Is the Heater Gas or Electric?</div>
      <div class="button-container">
        <div
          class="button"
          :class="isGas === 'Gas' ? 'active' : ''"
          @click="isGas = 'Gas'"
        >
          Gas
        </div>
        <div
          class="button"
          :class="isGas === 'Electric' ? 'active' : ''"
          @click="isGas = 'Electric'"
        >
          Electric
        </div>
      </div>
    </div>
    <div class="item-container">
      <div class="header">Where is the Heater Located?</div>
      <input type="text" class="input" v-model="location" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["project"],
  data() {
    return {
      isGas: "",
      location: "",
    };
  },
  computed: {
    payload() {
      return {
        isGas: this.isGas,
        location: this.location
      }
    }
  },
  mounted() {
    Object.keys(this.$data).forEach((key) => {
      this.$data[key] = this.project[key];
    });
  },
};
</script>

<style scoped>
.hwh-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.item-container {
  width: 30vw;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header {
  margin-bottom: 2vh;
}
.button-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
</style>

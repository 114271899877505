<template>
  <div class="tree-trimming-container">
    <div class="item-container">
      <div class="header">Is gate access 6ft or more?</div>
      <div class="button-container">
        <div
          class="button"
          :class="hasGateAccess ? 'active' : ''"
          @click="hasGateAccess = true"
        >
          Yes
        </div>
        <div
          class="button"
          :class="!hasGateAccess ? 'active' : ''"
          @click="hasGateAccess = false"
        >
          No
        </div>
      </div>
    </div>
    <div class="item-container">
      <div class="header">Does the home have a septic tank?</div>
      <div class="button-container">
        <div
          class="button"
          :class="hasSepticTank ? 'active' : ''"
          @click="hasSepticTank = true"
        >
          Yes
        </div>
        <div
          class="button"
          :class="!hasSepticTank ? 'active' : ''"
          @click="hasSepticTank = false"
        >
          No
        </div>
      </div>
    </div>
    <div class="item-container">
      <div class="header">If yes, please provide the location</div>
      <input type="text" class="input" v-model="septicTankLocation" />
    </div>
    <div class="item-container">
      <div class="header">
        Please Note any hazards above or below the ground
      </div>
      <input type="text" class="input" v-model="hazards" />
    </div>
    <div class="item-container">
      <div class="header">How many trees will be worked on?</div>
      <input type="number" class="input" v-model="treeCount" />
    </div>
    <div class="item-container">
      <div class="header">Any specific restrictions?</div>
      <input type="text" class="input" v-model="permissions" />
    </div>
    <div class="item-container">
      <div class="header">Trimming and/or Removal?</div>
      <div class="button-container">
        <div
          class="button"
          :class="trimming ? 'active' : ''"
          @click="trimming = !trimming"
        >
          Trimming
        </div>
        <div
          class="button"
          :class="removal ? 'active' : ''"
          @click="removal = !removal"
        >
          Removal
        </div>
      </div>
    </div>
    <div class="item-container">
      <div class="header">Are the trees within 10ft of powerlines?</div>
      <div class="button-container">
        <div
          class="button"
          :class="powerLineProx ? 'active' : ''"
          @click="powerLineProx = true"
        >
          Yes
        </div>
        <div
          class="button"
          :class="!powerLineProx ? 'active' : ''"
          @click="powerLineProx = false"
        >
          No
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["project"],
  data() {
    return {
      hasGateAccess: false,
      hasSepticTank: false,
      hazards: "",
      permissions: "",
      powerLineProx: false,
      removal: false,
      septicTankLocation: "",
      treeCount: 0,
      trimming: false,
    };
  },
  computed: {
    payload() {
      return {
        hasGateAccess: this.hasGateAccess,
        hasSepticTank: this.hasSepticTank,
        hazards: this.hazards,
        permissions: this.permissions,
        powerLineProx: this.powerLineProx,
        removal: this.removal,
        septicTankLocation: this.septicTankLocation,
        treeCount: this.treeCount,
        trimming: this.trimming
      }
    }
  },
  mounted() {
    Object.keys(this.$data).forEach((key) => {
      this[key] = this.project[key] ? this.project[key] : null;
    });
  },
};
</script>

<style scoped>
.tree-trimming-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 6vh;
  width: 100%;
}
.item-container {
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.header {
  margin-bottom: 2vh;
}
.button-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
</style>

<template>
  <div class="new-lead-item-container">
    <div class="card-container">
      <component
        :is="componentList[componentNumber]"
        :currentPage="page"
        ref="component"
      />
      <ItemFooter
        :sections="maxPages"
        @next="next"
        @previous="previous"
        @submit="submit"
        @quit="quit"
      />
    </div>
  </div>
</template>

<script>
import Generac from "@/components/Common/NewLead/ForumItems/Generac.vue";
import HotWaterHeater from "@/components/Common/NewLead/ForumItems/HotWaterHeater.vue";
import Insulation from "@/components/Common/NewLead/ForumItems/Insulation.vue";
import PoolPumps from "@/components/Common/NewLead/ForumItems/PoolPumps.vue";
import Roofing from "@/components/Common/NewLead/ForumItems/Roofing.vue";
import TreeTrimming from "@/components/Common/NewLead/ForumItems/TreeTrimming.vue";
import Hvac from "@/components/Common/NewLead/ForumItems/Hvac.vue";
import ItemFooter from "@/components/Common/NewLead/ForumItems/ItemFooter.vue";

export default {
  props: ["componentNumber", "maxPages", "componentName"],
  components: {
    ItemFooter,
  },
  data() {
    return {
      payload: {},
      page: 0,
      componentList: [
        Roofing,
        TreeTrimming,
        Generac,
        Insulation,
        PoolPumps,
        HotWaterHeater,
        Hvac,
      ],
    };
  },
  methods: {
    next() {
      this.page++;
    },
    previous() {
      this.page--;
    },
    submit() {
      this.$emit("submit", {
        [this.componentName]: this.$refs.component.payload,
        images: this.$refs.component.imagePayload,
      });
      this.page = 0;
    },
    quit() {
      this.$emit("quit");
      this.page = 0;
    },
  },
};
</script>

<style scoped>
.new-lead-item-container {
  height: 100%;
  width: 100%;
  background: #d4d4d4;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: absolute;
}
.card-container {
  width: 70vw;
  height: 70vh;
  margin: auto;
  font-size: 0.25rem;
  background: #fff;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  border-radius: 25px;
}
</style>

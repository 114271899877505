<template>
  <div class="landing-container">
    <input type="text" class="input search-bar" placeholder="Search" v-model="search"/>
    <UserContainer class="user-container" :search="search" :users="customers" />
    <Map class="map" :homeowners="customers" />
    <Leaderboard class="leaderboard" :users="repLeaderboard" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Map from "@/components/Common/Map.vue";
import Leaderboard from "@/components/Common/Leaderboard.vue";
import UserContainer from "@/components/Common/UserContainer.vue";

export default {
  components: {
    Map,
    Leaderboard,
    UserContainer,
  },
  data() {
    return {
      repLeaderboard: [],
      search: "",
    };
  },
  methods: {
    async getRepLeaderBoard() {
      if (!this.userConfig.leaderboard) {
        const leaders = (
          await this.$http.put(`${this.$API}rep/leaderboard`, {
            companyCode: this.userConfig.companyCode,
          })
        ).data;
        this.$store.commit("setLeaderboard", leaders);
        this.repLeaderboard = leaders;
      } else {
        this.repLeaderboard = this.userConfig.leaderboard;
      }
    },
  },
  computed: {
    ...mapGetters(["userConfig", "customers", "images"]),
  },
  async mounted() {
    await this.getRepLeaderBoard();
  },
};
</script>

<style scoped>
.landing-container {
    display: grid;
    grid-template-areas:
        "search search"
        "users map"
        "users leaderboard";
    grid-template-rows: 8vh 38.5vh 38.5vh;
    grid-template-columns: 1fr 1fr;
    row-gap: 2vh;
    -moz-column-gap: 1vw;
    column-gap: 1vw;
    width: 95vw;
    height: 91vh;
    padding: 0 2.5vw;
    background: #ededed;
    font-size: 0.3rem;
}
.search-bar {
  grid-area: search;
  width: 90%;
  margin: auto;
  background: #fff;
  border: none;
  color: #000;
  opacity: 0.7;
  font-size: 0.25rem;
}
input::-webkit-input-placeholder {
  font-weight: bold;
  opacity: 0.5;
}
input:-moz-placeholder {
  font-weight: bold;
  opacity: 0.5;
}
input::-moz-placeholder {
  font-weight: bold;
  opacity: 0.5;
}
input:-ms-input-placeholder {
  font-weight: bold;
  opacity: 0.5;
}
input::placeholder {
  font-weight: bold;
  opacity: 0.5;
}
input:focus-visible {
  outline: none;
}
.user-container {
  grid-area: users;
  font-size: .25rem;
}
.map {
  grid-area: map;
  width: 100%;
  height: 100%;
  margin: auto;
}
.leaderboard {
  grid-area: leaderboard;
}
</style>

<template>
  <div>
    <div class="loading" v-if="loading"><Icon class="loading-icon" />"</div>
    <div class="outer-container" @click="$emit('exit')" />
    <div class="card">
      <div class="header">User Settings:</div>
      <div class="item-container">
        <input
          type="text"
          placeholder="First Name"
          v-model="fName"
          class="input"
        />
        <input
          type="text"
          placeholder="Last Name"
          v-model="lName"
          class="input"
        />
      </div>
      <div class="item-container">
        <input
          type="text"
          placeholder="Address"
          v-model="address"
          class="input"
        />
        <input type="text" placeholder="City" v-model="city" class="input" />
        <select class="input" v-model="state">
          <option v-for="state of states" :key="state">{{ state }}</option>
        </select>
        <input type="number" placeholder="Zip" v-model="zip" class="input" />
      </div>
      <div class="item-container space-out">
        <input
          type="tel"
          placeholder="Phone Number"
          v-model="phone"
          class="input phone"
        />
        <input
          v-if="userType !== 'user'"
          type="text"
          placeholder="Company Code"
          v-model="companyCode"
          class="input phone"
          :disabled="userType !== 'rep'"
        />
      </div>
      <div class="item-container submission">
        <div class="button" @click="$emit('exit')">Cancel</div>
        <div class="button" @click="submit">Submit</div>
      </div>
    </div>
  </div>
</template>

<script>
import { States } from "@/assets/const.js";
import Icon from "@/assets/svg/Logo.svg";

export default {
  props: ["user", "userType"],
  components: {
    Icon,
  },
  data() {
    return {
      fName: "",
      lName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      companyCode: "",
      loading: false,
      states: States,
    };
  },
  computed: {
    payload() {
      return {
        fName: this.fName,
        lName: this.lName,
        address: this.address,
        city: this.city,
        state: this.state,
        zip: this.zip,
        phone: this.phone,
        companyCode: this.companyCode,
      };
    },
  },
  methods: {
    prefill() {
      this.fName = this.user.fName;
      this.lName = this.user.lName;
      this.address = this.user.address;
      this.city = this.user.city;
      this.state = this.user.state;
      this.zip = this.user.zip;
      this.phone = this.user.phone;
      this.companyCode =
        this.userType === "user" ? null : this.user.companyCode;
    },
    async submit() {
      const userFields = ["fName", "lName", "phone", "companyCode"];
      this.loading = true;
      for (const key in this.payload) {
        if (key === "companyCode" && this.userType === "user") continue;
        const value = this.payload[key];
        if (this.user[key] !== value) {
          const context = userFields.includes(key) ? this.userType : "home";
          const id = userFields.includes(key) ? this.user.id : this.user.homeId;
          await this.$http.put(`${this.$API}${context}/${id}/settings`, {
            field: key,
            value: value,
          });
          this.$store.commit("updateUser", {
            id: this.user.id,
            [key]: value,
            userType: this.userType,
          });
        }
      }
      this.loading = false;
      this.$emit("exit");
    },
  },
  mounted() {
    this.prefill();
  },
};
</script>

<style scoped>
.outer-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
}
.card {
  position: fixed;
  height: 35vh;
  width: 50vw;
  left: 25vw;
  top: 30.5vh;
  padding: 4vh 2vw;
  z-index: 11;
  background: #fff;
  border-radius: 25px;
  font-size: 0.3rem;
}
.item-container {
  display: flex;
  column-gap: 2vw;
  margin: 2vh 0;
}
.space-out {
  justify-content: space-between;
}
.phone {
  width: 10vw;
}
.submission {
  margin-top: 7.5vh;
  justify-content: space-evenly;
}
</style>

<template>
  <div class="roofing-container">
    <div class="input-container" v-if="currentPage === 0">
      <div class="roof-age">
        How old is the current roof?
        <input
          type="number"
          class="input"
          placeholder="Ex: 12"
          v-model="ageOfRoof"
        />
      </div>
      <div class="roof-type">
        Please select current type of roof
        <select v-model="typeOfRoof" class="input select">
          <option
            v-for="roofType of roofTypeOptions"
            :key="roofType"
            :value="roofType"
            >{{ roofType }}</option
          >
          <option value="Unknown" selected>Unknown</option>
        </select>
      </div>
      <div class="extras small">
        Check the requested replacements
        <div class="button-container">
          <div
            v-for="(item, index) of replacementButtons"
            :key="item.text"
            class="button"
            :class="item.checked ? 'active' : ''"
            @click="toggleReplacement(index)"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <!-- <div class="extras">
          Check all that apply:
          <div class="button-container">
            <div v-for="item of extras" :key="item.text" class="check-container">
              {{item.text}}
              <div class="check" :class="item.checked ? 'active' : ''" @click="item.checked = !item.checked" />  
            </div>
          </div>
        </div> -->
    </div>

    <div v-if="currentPage === 1">
      <div class="shingle-container">
        <div v-for="color of shingleColors" :key="color.name" class="shingle">
          <img
            :src="color.img"
            alt=""
            class="shingle-img"
            :class="
              color.name === shingleChoiceOne
                ? 'choice-one-color'
                : color.name === shingleChoiceTwo
                ? 'choice-two-color'
                : ''
            "
          />
          <!-- <div class="tool-tip">
              {{color.name}}
            </div> -->
          <div class="shingle-text">
            <div
              class="choice-one"
              :class="
                shingleChoiceOne === color.name ? 'choice-one-color-text' : ''
              "
              @click="toggleShingleColor(1, color.name)"
            >
              1
            </div>
            <div
              class="choice-two"
              :class="
                shingleChoiceTwo === color.name ? 'choice-two-color-text' : ''
              "
              @click="toggleShingleColor(2, color.name)"
            >
              2
            </div>
          </div>
        </div>
        <div class="caption">
          Shingle Color Selection<br />
          {{
            shingleChoiceOne || shingleChoiceTwo
              ? ""
              : "Please Select Two Options"
          }}
          <div v-if="shingleChoiceOne">
            Choice One: <span class="bold">{{ shingleChoiceOne }}</span>
          </div>
          <div v-if="shingleChoiceTwo">
            Choice Two: <span class="bold">{{ shingleChoiceTwo }}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="currentPage === 2">
      <div class="edge-container">
        <div
          v-for="color of edgeColors"
          :key="'edge-' + color.name"
          class="shingle"
        >
          <img
            :src="color.img"
            alt=""
            class="shingle-img"
            :class="color.name === edgeChoice ? 'choice-one-color' : ''"
            @click="edgeChoice = color.name"
          />
        </div>
      </div>
      <div class="edge-caption">
        Drip Edge Color Selection:<br />
        <div v-if="edgeChoice">
          <strong>{{ edgeChoice }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentPage"],
  data() {
    return {
      ageOfRoof: null,
      typeOfRoof: "Unknown",
      shingleChoiceOne: "",
      shingleChoiceTwo: "",
      edgeChoice: "",
      replacementButtons: [
        { checked: false, text: "Gutter" },
        { checked: false, text: "Soffit" },
        { checked: false, text: "Fascia" },
        { checked: true, text: "None" },
      ],
      roofTypeOptions: [
        "Architectural Shingle",
        "Standing Seam Metal",
        "Tile",
        "Three Tab Shingle",
        "Exposed Fastener Metal",
        "Rolled Roofing",
        "Mixed Roofing",
        "Gravel",
      ],
      // extras: [
      //   {checked: false, text: 'Gravel'},
      //   {checked: false, text: 'Double Layers'},
      //   {checked:false, text: 'Multiple Stories'},
      // ],
      shingleColors: [
        {
          name: "Black Walnut",
          img: require("../../../../assets/images/ShingleColors/BlackWalnut.png"),
        },
        {
          name: "Desert Sand",
          img: require("../../../../assets/images/ShingleColors/DesertSand.png"),
        },
        {
          name: "Glacier White",
          img: require("../../../../assets/images/ShingleColors/GlacierWhite.png"),
        },
        {
          name: "Natural Timber",
          img: require("../../../../assets/images/ShingleColors/NaturalTimber.png"),
        },
        {
          name: "Olde English Pewter",
          img: require("../../../../assets/images/ShingleColors/OldeEnglishPewter.png"),
        },
        {
          name: "Oxford Grey",
          img: require("../../../../assets/images/ShingleColors/OxfordGrey.png"),
        },
        {
          name: "Painted Desert",
          img: require("../../../../assets/images/ShingleColors/PaintedDesert.png"),
        },
        {
          name: "Rustic Black",
          img: require("../../../../assets/images/ShingleColors/RusticBlack.png"),
        },
        {
          name: "Rustic Cedar",
          img: require("../../../../assets/images/ShingleColors/RusticCedar.png"),
        },
        {
          name: "Rustic Slate",
          img: require("../../../../assets/images/ShingleColors/RusticSlate.png"),
        },
        {
          name: "Shadow Grey",
          img: require("../../../../assets/images/ShingleColors/ShadowGrey.png"),
        },
        {
          name: "Thunderstorm Grey",
          img: require("../../../../assets/images/ShingleColors/ThunderstormGrey.png"),
        },
        {
          name: "Weathered Wood",
          img: require("../../../../assets/images/ShingleColors/WeatheredWood.png"),
        },
      ],
      edgeColors: [
        {
          name: "Brown",
          img: require("../../../../assets/images/TrimColors/BrownTrim.png"),
        },
        {
          name: "Black",
          img: require("../../../../assets/images/TrimColors/BlackTrim.png"),
        },
        {
          name: "White",
          img: require("../../../../assets/images/TrimColors/WhiteTrim.png"),
        },
      ],
    };
  },
  computed: {
    payload() {
      return {
        ageOfRoof: this.ageOfRoof,
        typeOfRoof: this.typeOfRoof,
        // gravel: this.extras[0].checked,
        // doubleLayers: this.extras[1].checked,
        // stories: this.extras[2].checked ? 2 : 1,
        replaceGutter: this.replacementButtons[0].checked,
        replaceSoffit: this.replacementButtons[1].checked,
        replaceFascia: this.replacementButtons[2].checked,
        shingleColor: `{one: ${this.shingleChoiceOne}, two: ${this.shingleChoiceTwo}}`,
        edgeColor: this.edgeChoice,
      };
    },
    imagePayload() {
      return {};
    },
  },
  methods: {
    toggleShingleColor(number, name) {
      if (number === 1) {
        if (name === this.shingleChoiceOne) {
          this.shingleChoiceOne = "";
        } else {
          if (name === this.shingleChoiceTwo) this.shingleChoiceTwo = "";
          this.shingleChoiceOne = name;
        }
      } else {
        if (name === this.shingleChoiceTwo) {
          this.shingleChoiceTwo = "";
        } else {
          if (name === this.shingleChoiceOne) this.shingleChoiceOne = "";
          this.shingleChoiceTwo = name;
        }
      }
    },
    toggleReplacement(number) {
      const length = this.replacementButtons.length - 1;
      if (number == length) {
        for (let i = 0; i < length; i++) {
          this.replacementButtons[i].checked = false;
        }
        this.replacementButtons[length].checked = true;
      } else {
        this.replacementButtons[length].checked = false;
        this.replacementButtons[number].checked = !this.replacementButtons[
          number
        ].checked;
        let isNone = true;
        this.replacementButtons.forEach((element) => {
          element.checked ? (isNone = false) : "";
        });
        this.replacementButtons[length].checked = isNone;
      }
    },
  },
};
</script>

<style scoped>
.roofing-container {
  height: 100%;
  width: 100%;
}
.input-container {
  margin-top: 6%;
  height: 74%;
  width: 100%;
  display: grid;
  grid-template-areas:
    "a b"
    "c c";
}
.roof-age,
.roof-type,
.extras {
  margin: auto;
  display: flex;
  flex-direction: column;
}
.extras {
  width: 85%;
  text-align: center;
}

.small {
  width: 75%;
  grid-area: c;
}

.input {
  width: 100%;
  margin: 5vh auto auto auto;
}
.button-container {
  display: flex;
  column-gap: 1vw;
  justify-content: space-between;
  margin: 5vh auto auto auto;
  width: 100%;
}

.check-container {
  display: flex;
  flex-direction: column;
  width: 33%;
  align-items: center;
}
.check {
  width: 5vh;
  height: 5vh;
  margin-top: 2vh;
  background: #ededed;
  cursor: pointer;
}
.check:hover {
  background: var(--blue-300);
}

.shingle-container,
.edge-container {
  display: grid;
  width: 100%;
  height: 98%;
  margin-top: 2%;
}
.shingle-container {
  grid-template-areas:
    "a b c d e"
    "f g h i j"
    "k l m n n";
}
.edge-container {
  margin-top: 20vh;
  grid-template-areas: "a b c";
}
.shingle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shingle-img {
  height: 13vh;
  width: 16vh;
}
.tool-tip {
  position: absolute;
  display: none;
  top: 10px;
  left: 20px;
}
.shingle-img:hover ~ .tool-tip {
  display: block;
}
.choice-one-color {
  height: calc(13vh - 8px);
  width: calc(16vh - 8px);
  border: 4px solid var(--blue-600);
}
.choice-two-color {
  height: calc(13vh - 8px);
  width: calc(16vh - 8px);
  border: 4px solid var(--secondary-orange);
}
.shingle-text {
  display: flex;
  margin: 1vh 0;
  width: 62%;
  font-size: 0.18rem;
  justify-content: space-evenly;
}
.choice-one,
.choice-two {
  padding: 1vh 1vw;
  background: #ededed;
  color: #000;
  cursor: pointer;
}
.choice-one:hover {
  background: var(--blue-300);
}
.choice-two:hover {
  background: var(--primary-yellow);
  color: #000;
}
.choice-one-color-text {
  background: var(--blue-600);
}
.choice-two-color-text {
  background: var(--secondary-orange);
}
.caption {
  grid-area: n;
  margin: auto;
  font-size: 0.28rem;
  text-align: center;
}
.edge-caption {
  margin-top: 5vh;
  font-size: 0.28rem;
  text-align: center;
}
.caption div {
  margin-top: 1vh;
  font-size: 0.18rem;
  text-align: left;
  text-align: center;
}
.bold {
  font-weight: bold;
}
</style>

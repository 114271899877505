<template>
  <div class="insulation-container">
    <div class="item-container">
      <div class="header">Is the Attic Accessible?</div>
      <div class="button-container">
          <div class="button" @click="access = true" :class="access ? 'active' : ''">Yes</div>
          <div class="button" @click="access = true" :class="!access ? 'active' : ''">No</div>
      </div>
    </div>
    <div class="item-container">
      <div class="header">Type of Current Insulation</div>
      <div class="button-container">
        <div
          class="button"
          :class="typeOf === 'Spray Foam' ? 'active' : ''"
          @click="typeOf = 'Spray Foam'"
        >
          Spray Foam
        </div>
        <div
          class="button"
          :class="typeOf === 'Blown-In' ? 'active' : ''"
          @click="typeOf = 'Blown-In'"
        >
          Blown-In
        </div>
        <div
          class="button"
          :class="typeOf === 'Unknown' ? 'active' : ''"
          @click="typeOf = 'Unknown'"
        >
          Unknown
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["project"],
  data() {
    return {
      typeOf: "",
      access: false,
    };
  },
  computed: {
    payload() {
      return {
        typeOf: this.typeOf,
        access: this.access,
      }
    }
  },
  mounted() {
    Object.keys(this.project).forEach((key) => {
      this.$data[key] = this.project[key];
    });
  },
};
</script>

<style scoped>
.insulation-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.item-container {
  width: 30vw;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header {
  margin-bottom: 2vh;
}
.button-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
</style>

<template>
  <div class="document-container">
    Documents
    <div class="quote-button-container">
      <div
        class="document-item button quote"
        @click="nav()"
        v-if="level === 'a'"
      >
        Create Quote
      </div>
      <div class="document-item button quote">Upload Document</div>
    </div>
    <div class="document-item" @click="$emit('open', 'photos')">
      <Document class="ball" />
      Uploaded Images
    </div>
    <div
      v-for="(document, index) of user.documents"
      :key="'cust-doc-' + index"
      class="document-item"
      @click="$emit('open', document.url)"
    >
      <Document class="ball" />
      {{ document.title }}
    </div>
    <div
      v-for="(quote, index) of user.quotes"
      :key="'quote-doc-' + index"
      class="document-item"
      @click="nav(quote.id)"
    >
      <Document class="ball" :class="quote.accepted ? 'accepted' : ''"/>
      {{ formatQuote(quote.createdAt) }}
    </div>
  </div>
</template>

<script>
import Document from "@/assets/svg/Document2.svg";
export default {
  props: ["user"],
  components: {
    Document,
  },
  computed: {
    level() {
      return this.$store.getters.level;
    },
  },
  methods: {
		nav(quoteId) {
			let route = this.$route.matched[0].path;
			if (quoteId) {
				route += `/view/q?u=${this.user.id}&q=${quoteId}`;
			} else {
				route += `/createQuote?u=${this.user.id}`;
			}
			this.$router.push(route);
		},
    formatQuote(createdAt) {
      const date = new Date(createdAt);
      const month =
        date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth();
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return `Quote-${month}-${day}-${date.getFullYear()}`;
    },
  },
};
</script>

<style scoped>
.document-container {
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
  padding: 2vh 2vw;
  background: #989898;
  height: -webkit-fill-available;
  height: 25vh;
  overflow-y: auto;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}
.quote-button-container {
  display: flex;
  column-gap: 1vw;
}
.document-item {
  margin-top: 0.5vh;
  font-size: 0.75em;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ball {
  width: 3vh;
  height: 3vh;
  fill: var(--blue-600);
  border-radius: 50%;
  margin-right: 0.5vw;
}
.document-item:hover .ball {
  background: var(--blue-300);
}
.quote {
  width: 2.5rem;
  justify-content: center;
  margin-bottom: 1vh;
  height: 4vh;
}
.accepted {
	fill: green;
}
.document-item:hover .accepted {
	fill: greenyellow;
	background: inherit;
}
</style>
